import { createContext, useCallback, useState, useRef } from "react"
import useLdjsonApi from "../hooks/useLdjsonApi"

const BridgeContext = createContext()
export default BridgeContext

// keeps a copy of data that should only need to be fetched once per session
export function BridgeProvider({ children }) {
    const [bridges, setBridges] = useState({})
    const pendingRequests = useRef({})
    const { get } = useLdjsonApi()

    const getBridge = useCallback(async (resource) => {

        if (bridges[resource]) return bridges[resource]

        if (!allowedEndpoints.includes(resource)) {
            console.error('Unrecognized resource, did not fetch')
            return
        }

        if (!pendingRequests.current[resource]) {

            const query = endpointQueryMap[resource]

            pendingRequests.current[resource] = (async () => {
                try {
                    const { data } = await get(resource, { query })
                    setBridges((prev) => ({
                        ...prev,
                        [resource]: data,
                    }))
                    return data
                } catch (error) {
                    console.error(error)
                    throw error
                } finally {
                    delete pendingRequests.current[resource]
                }
            })()
        }

        return pendingRequests.current[resource]
    }, [bridges, get])

    const clearCache = useCallback(() => {
        setBridges({})
        pendingRequests.current = {}
    }, [])

    return (
        <BridgeContext.Provider value={{ getBridge, clearCache }}>
            {children}
        </BridgeContext.Provider>
    )
}

const allowedEndpoints = [
    '/api/current/frontend-bridge/activity-event-types',
    '/api/current/frontend-bridge/aeration-heive',
    '/api/current/frontend-bridge/aeration-spacing',
    '/api/current/frontend-bridge/aeration-types',
    '/api/current/frontend-bridge/biotreatment-event-treatment-methods',
    '/api/current/frontend-bridge/biotreatment-event-treatment-types',
    '/api/current/frontend-bridge/body-impact-zones',
    '/api/current/frontend-bridge/cleansing-types',
    '/api/current/frontend-bridge/directions',
    '/api/current/frontend-bridge/disease-types',
    '/api/current/frontend-bridge/event-types',
    '/api/current/frontend-bridge/fertiliser-additional-nutritions',
    '/api/current/frontend-bridge/fertiliser-types',
    '/api/current/frontend-bridge/fungicide-product-treatment-types',
    '/api/current/frontend-bridge/grooming-machines',
    '/api/current/frontend-bridge/grooming-types',
    '/api/current/frontend-bridge/heating-types',
    '/api/current/frontend-bridge/heatingTemperatures',
    '/api/current/frontend-bridge/injury-event-reason',
    '/api/current/frontend-bridge/injury-event-severities',
    '/api/current/frontend-bridge/injury-types',
    '/api/current/frontend-bridge/mowing-machine-types',
    '/api/current/frontend-bridge/mowing-techniques',
    '/api/current/frontend-bridge/seeding-species',
    '/api/current/frontend-bridge/seeding-types',
    '/api/current/frontend-bridge/sports',
    '/api/current/frontend-bridge/training-teams',
    '/api/current/frontend-bridge/training-types',
    '/api/current/frontend-bridge/units',
    `/api/current/frontend-bridge/line-marking-methods`,
    '/api/current/frontend-bridge/top-dressing-materials',
    '/api/current/frontend-bridge/timezones',
    // not strictly frontendbridges but are entities in backend that should not be configurable by user
    '/api/current/fungicide-products',
    '/api/current/injuries',
    '/api/current/nematode-products',
    `/api/current/frontend-bridge/cover-event-products`,
    `/api/current/frontend-bridge/cover-event-types`,
]

//always fetch theses with these queries:
const endpointQueryMap = {
    '/api/current/injuries': { pagination: false }
}
