import { useCallback, useContext, useMemo } from "react"

import {
    formatUnit as formatUnitUtil,
    convertToUnits as convertToUnitsUtil,
    convertToMetric as convertToMetricUtil,
    isUnitConvertable,
    formatUnitName as formatUnitNameUtil,
    formatHeatingEventTemperature as formatHeatingEventTemperatureUtil,
} from "../utils/unitConversion"

import UnitSystemContext from "../context/UnitSystem"

export default function useUnit() {
    const { unitSystem } = useContext(UnitSystemContext)

    const formatUnit = useCallback((value, fromUnit, options) => {
        return formatUnitUtil(value, fromUnit, unitSystem, options)
    }, [unitSystem])

    const formatUnitName = useCallback((fromUnit, options) => {
        return formatUnitNameUtil(fromUnit, unitSystem, options)
    }, [unitSystem])

    const convertToUnits = useCallback((value, fromUnit, options) => {
        return convertToUnitsUtil(value, fromUnit, unitSystem, options)
    }, [unitSystem])

    const convertToMetric = useCallback((primaryValue, secondaryValue, metricUnit) => {
        return convertToMetricUtil(primaryValue, secondaryValue, metricUnit, unitSystem)
    }, [unitSystem])

    const isUnitInCurrentSystem = useCallback((metricUnit) => {
        return isUnitConvertable(metricUnit, unitSystem)
    }, [unitSystem])

    const formatHeatingEventTemperature = useCallback((temparatureRangeString) => {
        return formatHeatingEventTemperatureUtil(temparatureRangeString, unitSystem)
    }, [unitSystem])

    const systemIsMetric = useMemo(() => {
        return unitSystem === 'metric'
    }, [unitSystem])

    return {
        formatUnit,
        formatUnitName,
        convertToUnits,
        convertToMetric,
        isUnitInCurrentSystem,
        formatHeatingEventTemperature,
        systemIsMetric,
    }
}
