import React, { useContext } from 'react'
import { css } from '@emotion/react'
import { colors, fonts } from '../../../style/vars'
import AnalyticsContext from '../../../context/Analytics'
import { useState } from 'react'

export default function FilterSelect({ dashId, filter, isSecondary, ...props }) {

    const { flexOrder, name, options, selectedOptionValue, addDefaultOption } = filter

    const { dashboards: { setFilter } } = useContext(AnalyticsContext)
    const [isDefault, setIsDefault] = useState(!!addDefaultOption)

    const style = css`
        height: 32px;
        max-width: 10em;
        border-radius: 5px;
        background: ${colors.eventDark};
        font-family: ${fonts.special};
        font-size: 20px;
        color: ${colors.soft};
        order: ${flexOrder};
        cursor: pointer;
        margin: 1px;

        text-align: center;
        display: flex;
        justify-content: center;
        -webkit-appearance: menulist;

        &:hover {
            opacity: 0.8;
        }

        box-shadow: ${isDefault && ('0 0 2px 1px ' + colors.main1)};
        color: ${isDefault && colors.main1};

        option {
            color: ${colors.soft};
        }
    `

    return (
        <select
            defaultValue={selectedOptionValue}
            css={style}
            onChange={(e) => {
                setIsDefault(!e.target.value)
                setFilter(dashId, name, e.target.value, isSecondary)
            }}
            className='filterSelect'
            tableau-variable-name={name}
        >
            {options.map(o => {
                return <option
                    key={o.value}
                    value={o.value ?? ''}
                >
                    {o.label}
                </option>
            })}
        </select>
    )
}