
import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import { css } from '@emotion/react'
import Selector from '../_control/OptionsSelector'
import { getUnixTime } from 'date-fns'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import FieldSectionSelector from '../_control/FieldSectionSelector'
import Orientation from '../test/Orientation'
import PitchContext from '../../context/Pitch'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import NumberScroller from '../_control/NumberScroller'
import { zonesToSectionState } from '../../utils/eventUtils'
    
const columns = 7
const rows = 5

export default function TrainingForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [trainingTeam, setTrainingTeam] = useState()
    const [trainingType, setTrainingType] = useState()
    const [sections, setSections] = useState([])
    //other
    const { data: trainingTeamOptions, loading: waitingForTrainingTeams } = useBridge(`/api/current/frontend-bridge/training-teams`)
    const { data: trainingTypeOptions, loading: waitingForTrainingTypes } = useBridge(`/api/current/frontend-bridge/training-types`)
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/training-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setTrainingTeam(data.team)
                setTrainingType(data.trainingType)
                setSections(zonesToSectionState(data.zoneResults))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // when the required field are set the form is valid and will submit.
    const validateAndSubmit = useCallback(async () => {
        if (!trainingTeam) return setFormError(formatMessage({ id: 'pleaseSelectTeam' }))
        if (!trainingType) return setFormError(formatMessage({ id: 'pleaseSelectTrainingType' }))
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setFormError()
        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            team: trainingTeam,
            trainingType,
        }

        if (event) {
            const { data: putResponse, error } = await put(`${event['@id']}`, { body: payload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (putResponse) {
                const { data: trainingData } = await get(`/api/current/training-events/${putResponse.id}`);

                trainingData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections?.[zone.posY - 1]?.[zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, trainingData)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Match event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data: postResponse, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (postResponse) {
                const { data: trainingData } = await get(`/api/current/training-events/${postResponse.id}`);
                trainingData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections?.[zone.posY - 1]?.[zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, trainingData)
            }
        }
    }, [pitch, trainingTeam, trainingType, sections, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => (
        waitingForTrainingTeams || waitingForTrainingTypes || isLoading
    ), [waitingForTrainingTeams, waitingForTrainingTypes, isLoading])

    return (<>
        {isBusy ? <FormLoader /> :
            <div css={css`display: flex; flex-direction: column; align-items: flex-start;`}>
                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />
                <NumberScroller
                    label={formatMessage({ id: 'timeTaken' })}
                    minVal={0}
                    maxVal={24}
                    state={timeTaken}
                    unit={formatMessage({ id: 'hours' })}
                    step={0.5}
                    setState={setTimeTaken}
                />
                <Selector
                    label={formatMessage({ id: 'team' })}
                    optionsObject={trainingTeamOptions}
                    setState={setTrainingTeam}
                    state={trainingTeam} />
                <Selector
                    label={formatMessage({ id: 'type' })}
                    optionsObject={trainingTypeOptions}
                    setState={setTrainingType}
                    state={trainingType} />
                <span css={{ marginBottom: 20 }}>
                    <FormattedMessage id='wornAreas' />
                </span>
                <div css={css`max-width: 100%; overflow-x: auto;`}>
                    <FieldSectionSelector
                        backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                        rows={rows}
                        columns={columns}
                        sections={sections}
                        onChange={setSections} />
                    <Orientation
                        css={style.compass}
                        heading={pitch.orientation}
                    />
                </div>
            </div>
        }
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            disabled={isBusy}
            hidden={isBusy}
            type="button"
            onClick={(e) => validateAndSubmit(e)}
        >
            <FormattedMessage id='save' />
        </button>
    </>)
}

const style = {
    compass: css`
        position: relative;
        height: 2em;
        width: 2em;
        bottom: -1em;
        left: calc(50% - 1em);
        background-color: rgba(44,52,58);
        box-shadow: none;
        margin-bottom:40px;
    `,
}