import React from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import {ReactSVG} from 'react-svg'
import { colors, timings } from '../../style/vars'
import Loader from '../_general/Loader'

const typeColorMap = {
    'positive': {
        color: colors.white,
        bg: 'transparent',
        border: colors.main1,
        colorHover: colors.black,
        bgHover: colors.main1,
        borderHover: colors.main1,
        opacity: 1,
        hoverOpacity: 1,
    },
    'subtle': {
        color: colors.white,
        bg: 'rgba(255,255,255,0)',
        border: colors.white,
        colorHover: colors.white,
        bgHover: 'rgba(255,255,255,0.1)',
        borderHover: colors.white,
        opacity: 1,
        hoverOpacity: 1,
    },
    'neutral': {
        color: colors.white,
        bg: 'rgba(255,255,255,0)',
        border: colors.main1,
        colorHover: colors.white,
        bgHover: rgba(colors.main1, 0.25),
        borderHover: colors.main1,
        opacity: 1,
        hoverOpacity: 1,
    },
    'admin': {
        color: colors.white,
        bg: rgba(colors.super, 0.5),
        border: rgba(colors.super, 0.5),
        colorHover: colors.white,
        bgHover: colors.super,
        borderHover: colors.super,
        opacity: 1,
        hoverOpacity: 1,
    },
}

const style = signal => css`
    position: relative;
    display: inline-flex;
    justify-content: center;
    padding: 0.5em 2em;
    font-size: 0.9375em;
    line-height: 1.5em;
    font-weight: 600;
    color: ${typeColorMap[signal].color};
    background-color: ${typeColorMap[signal].bg};
    border: 2px solid ${typeColorMap[signal].border};
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    outline: none;
    opacity: ${typeColorMap[signal].opacity};
    transition: all ${timings.fast} ease;
    text-transform: uppercase;

    &:disabled {
        opacity: 0.35;
        cursor: default;
    }

    &:focus,&:hover {
        &:not(:disabled) {
            color: ${typeColorMap[signal].colorHover};
            background-color: ${typeColorMap[signal].bgHover};
            border-color: ${typeColorMap[signal].borderHover};
            opacity: ${typeColorMap[signal].hoverOpacity};
            transition: all ${timings.snappy} ease;
        }
    }
`

export default function Cta({ children, signal = 'neutral', icon, loading = false, ...props }) {
    return (
        <button css={style(signal, icon, loading)} type='button' {...props}>
            {children}
            {(!loading && icon) &&
                <ReactSVG
                    src={icon}
                    css={css`
                        position: absolute;
                        right: 0.5em;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 1.25em;
                        width: 1.25em;
                    `}
                />
            }
            {loading &&
                <Loader
                    size='1.25em'
                    css={css`
                        position: absolute;
                        right: 0.5em;
                        top: 50%;
                        transform: translateY(-50%);
                        ${signal === 'positive' ? `
                            filter: invert(100%);
                        ` : ''}
                    `}
                />
            }
        </button>
    )
}