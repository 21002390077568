import React, { createContext, useState, useCallback, useEffect, useContext, useMemo } from 'react'
import ProfileContext from './Profile'
import useLdjsonApi from '../hooks/useLdjsonApi'

const integrationFlagNames = [
    'cover-master',
]

const IntegrationsContext = createContext()

function IntegrationsProvider({ ...props }) {

    const { put } = useLdjsonApi()
    const [profile, { fetch, busy }] = useContext(ProfileContext)
    const [requestBusy, setRequestBusy] = useState(false)

    const isBusy = useMemo(() => {
        return busy || !profile || requestBusy
    }, [busy, profile, requestBusy])

    const activate = useCallback(async (integration) => {
        if (!profile) return
        if (profile.integrations?.includes(integration)) return
        setRequestBusy(true)
        const newIntegrations = Array.isArray(profile.integrations) ? [...profile.integrations] : []
        newIntegrations.push(integration)
        await put(`/api/current/users/${profile.id}`, {
            body: {
                integrations: newIntegrations
            }
        })
        await fetch()
        setRequestBusy(false)
    }, [fetch, put, profile])

    const deActivate = useCallback(async (integration) => {
        if (!profile) return
        if (!Array.isArray(profile.integrations) || !profile.integrations.includes(integration)) return
        setRequestBusy(true)
        const newIntegrations = profile.integrations.filter(x => x !== integration)
        await put(`/api/current/users/${profile.id}`, {
            body: {
                integrations: newIntegrations
            }
        })
        await fetch()
        setRequestBusy(false)
    }, [fetch, put, profile])

    const toggle = useCallback((integration) => {
        if (!integrationFlagNames.includes(integration) || !profile) return
        if (!Array.isArray(profile.integrations) || !profile.integrations.includes(integration)) {
            activate(integration)
        } else {
            deActivate(integration)
        }
    }, [profile, fetch, deActivate, activate])

    return (
        <IntegrationsContext.Provider
            {...props}
            value={{
                integrations: profile?.integrations ?? [],
                activate,
                deActivate,
                toggle,
                isBusy,
            }}
        />
    )
}

export default IntegrationsContext
export { IntegrationsProvider }