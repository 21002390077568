import React from 'react'
import { uniqueId } from 'lodash';

export default function SvgShape({ shape, ...props }) {
    const id = uniqueId()

    switch (shape) {
        case 'ellipse': return (<ellipse {...props} />);
        case 'rect': return (<rect {...props} />);
        case 'polygon': return (<polygon {...props} />);
        default:
            return (<circle {...props} />);
    }
}

