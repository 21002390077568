import { css } from "@emotion/react"
import { useCallback, useState } from "react"
import { colors } from "../../style/vars"
import Cta from "../_control/Cta"
import Loader from "../_general/Loader"
import { FormattedMessage } from "react-intl"
import { useIntl } from "react-intl"

export default function ChangePasswordCard({ submitPassword, ...props }) {
    const [passString1, setPassString1] = useState('')
    const [passString2, setPassString2] = useState('')

    const [showError, setShowError] = useState('')
    const [isChanging, setIsChanging] = useState(false)
    const [isDone, setIsDone] = useState(false)

    const { formatMessage } = useIntl()

    const validateAndSubmit = useCallback(async () => {
        if (passString1 !== passString2) return setShowError(formatMessage({ id: 'passwordsNotTheSame' }))
        if (passString1.length < 8) return setShowError(formatMessage({ id: 'minimum8Cahrs' }))

        setIsChanging(true)
        setShowError('')
        try {
            await submitPassword(passString1, () => {
                setIsChanging(false)
                setIsDone(true)
            })
        } catch (e) {
            setIsChanging(false)
            setShowError(formatMessage({ id: 'errorBoundaryMessage' }))
            console.error(e)
        }
    }, [passString1, passString2])

    return (
        <div css={style.passChangeCard} {...props}>
            <label>
                <div> <FormattedMessage id='newPass' /> </div>
                <input type="password" maxLength={70} onChange={(e) => setPassString1(e.target.value)} autoComplete='off' />
            </label>
            <label >
                <div> <FormattedMessage id='repeatPass' /> </div>
                <input type="password" maxLength={70} onChange={(e) => setPassString2(e.target.value)} autoComplete='off' />
            </label>
            <Cta disabled={passString1 === '' || passString2 === '' || isChanging || isDone} onClick={() => validateAndSubmit()} >
                {isDone ? <FormattedMessage id='changedPass' /> : <FormattedMessage id='changePass' />}
            </Cta>
            <div> {isChanging ? <Loader /> : showError} </div>
        </div>
    )
}

const style = {
    passChangeCard: css`
        display: grid;
        width: min-content;
        grid-template-columns: 1fr;
        place-items: center;
        margin-top: 1em;
        background: ${colors.substance};
        padding: 2em 1em 1em 1em;
        gap: 1em;
        color: ${colors.soft};

        input {
            margin-top: 0.2em;
            background: none;
            padding: 0.5em;
            background: ${colors.eventLight};
            color: ${colors.white};
            border-radius: 5px;
            width: min(max-content, 20em);
            font-size: 1.2em;
        }

        > div {
            height: 1em; color: ${colors.warn};
        }
    `
}