//@ts-ignore
import icon_rain from '@assets/icons/weather/rain.svg'
//@ts-ignore
import icon_cloudy from '@assets/icons/weather/cloudy.svg'
//@ts-ignore
import icon_rain_possible from '@assets/icons/weather/rain_possible.svg'
//@ts-ignore
import icon_rain_likely from '@assets/icons/weather/rain_likely.svg'
//@ts-ignore
import icon_snow_sleet from '@assets/icons/weather/snow_sleet.svg'
//@ts-ignore
import icon_storm from '@assets/icons/weather/storm.svg'
//@ts-ignore
import icon_sun from '@assets/icons/weather/sunshine_daytime_sunrise.svg'
//@ts-ignore
import icon_moon from '@assets/icons/weather/night_sunset.svg'
//@ts-ignore
import icon_wind from '@assets/icons/weather/wind.svg'
//@ts-ignore
import icon_partly_cloudy from '@assets/icons/weather/partly_cloudy.svg'
import { formatISO } from 'date-fns'
import { groupBy } from 'lodash'

/**
 * 
 * @param {String} condition 
 * @returns the .svg file corresponding to the provided weather condition
 */
export function getConditionIcon(condition) {
    const normalized_condition = String(condition).toLowerCase().replace(' ', '-')
    switch (normalized_condition) {
        case 'clear':
        case 'clear-day':
            return icon_sun

        case 'clear-night':
            return icon_moon

        case 'cloudy':
        case 'foggy':
            return icon_cloudy

        case 'partly-cloudy':
        case 'partly-cloudy-day':
        case 'partly-cloudy-night':
            return icon_partly_cloudy

        case 'rain-possible':
        case 'possibly-rainy-day':
        case 'possibly-rainy-night':
        case 'very-light-rain':
            return icon_rain_possible

        case 'rain-likely':
        case 'moderate-rain':
            return icon_rain_likely

        case 'rainy':
            return icon_rain

        case 'snow':
        case 'sleet':
        case 'snow-possible':
        case 'possibly-snow-day':
        case 'possibly-snow-night':
        case 'possibly-sleet-day':
        case 'possibly-sleet-night':
        case 'wintry-mix-likey':
        case 'wintry-mix-possible':
            return icon_snow_sleet

        case 'thunderstorm':
        case 'possibly-thunderstorm-day':
        case 'possibly-thunderstorm-night':
        case 'thunderstorms-possible':
        case 'thunderstorms-likely':
        case 'storm':
            return icon_storm

        case 'windy':
            return icon_wind

        default:
            return icon_rain_possible
    }
}

//tempest's condition strings mapped to translation keys
export const conditionStringsMap = {
    "Clear": 'clear',
    "Rain Likely": 'rainLikely',
    "Rain Possible": 'rainPossible',
    "Snow": 'snow',
    "Snow Possible": 'snowPossible',
    "Wintry Mix Likely": 'wintryMixLikely',
    "Wintry Mix Possible": 'wintryMixPossible',
    "Thunderstorms Likely": 'thunderstormsLikely',
    "Thunderstorms Possible": 'thunderstormsPossible',
    "Windy": 'windy',
    "Foggy": 'foggy',
    "Cloudy": 'cloudy',
    "Partly Cloudy": 'partlyCloudy',
    "Very Light Rain": 'veryLightRain',
    //@ts-ignore
    [undefined]: 'error'
}

export function calcDerivedForecast(hourlyForecastData, fieldProperties) {
    if (!hourlyForecastData) return

    ///////////////////
    // calculate GD  //
    const tempAvgOnly = hourlyForecastData.map(h => ({
        dateTime: h.date,
        date: formatISO(h.date, { representation: 'date' }),
        value: h[fieldProperties.temperatureAvg.name],
    }))
    // group per 'iso date', take avg of avg temperatures
    const dayGroups = groupBy(tempAvgOnly, h => h.date)
    // this is now an object

    // calc gdd per day
    const dateGddList = Object.entries(dayGroups).map(([key, val]) => {
        return {
            date: key,
            gdd: calcGdd((val.reduce((a, b) => a + b.value, 0) / val.length))
        }
    })

    ///////////////////
    // calculate GDD //
    const cumulData = []
    for (const dg of dateGddList) {
        if (cumulData.length === 0) {
            cumulData.push({ ...dg })
        } else {
            const prevGdd = cumulData[cumulData.length - 1].gdd
            cumulData.push({
                ...dg,
                gdd: dg.gdd + prevGdd
            })
        }
    }

    ////////////////////////////
    // calculate leaf wetness //  (this is a daily metric, so we need to calculate hourly intermediary values and sum those)
    const leafWetnessHourly = hourlyForecastData.map(h => {
        return {
            dateTime: h.date,
            date: formatISO(h.date, { representation: 'date' }),
            value: calcHourlyLeafWetnessValue(
                h[fieldProperties.temperatureAvg.name],
                h[fieldProperties.dewPointAvg.name],
                h[fieldProperties.humidityAvg.name],
            )
        }
    })
    const groups = groupBy(leafWetnessHourly, h => h.date)
    const dateLeafWetnessList = Object.entries(groups).map(([key, val]) => {
        return {
            date: key,
            leafWetness: val.reduce((a, b) => a + b.value, 0),
        }
    })

    // add derived metrics to our original dataset
    const output = hourlyForecastData.map(h => {
        h['growingDegrees'] = dateGddList
            .find(dateGddObj => dateGddObj.date === formatISO(h.date, { representation: 'date' }))
            .gdd

        h['growingDegreeDays'] = cumulData
            .find(dateGddObj => dateGddObj.date === formatISO(h.date, { representation: 'date' }))
            .gdd

        h['diseaseRiskIndex'] = calcDiseaseRisk(
            h[fieldProperties.humidityAvg.name],
            h[fieldProperties.temperatureAvg.name],
            h[fieldProperties.windSpeedAvg.name]
        )

        h['leafWetness'] = dateLeafWetnessList
            .find(dateLeafWetnessObj => dateLeafWetnessObj.date === formatISO(h.date, { representation: 'date' }))
            .leafWetness

        return h
    })

    // finally take moving average (last x hours) of disease risk index
    const movingDiseaseRisk = mapMovingAverage(output.map(h => h.diseaseRiskIndex), 24)

    return output.map((h, i) => ({
        ...h,
        diseaseRiskIndex: movingDiseaseRisk[i]
    }))

}

function calcGdd(avgDailyTemp, gddThreshold = 6) {
    return Math.max(avgDailyTemp - gddThreshold, 0);
}

function mapMovingAverage(arr, lookback = 0) {
    if (!arr || arr.length === 0) return []

    const output = []
    const elementsToAverage = lookback + 1

    for (let i = 0; i < arr.length; i++) {
        const removedEnd = arr.slice(0, i + 1)
        const lookbackElements = removedEnd.slice(-elementsToAverage)
        output.push(lookbackElements.reduce((a, b) => a + b) / lookbackElements.length)
    }

    return output
}

function calcDiseaseRisk(humidityPerc, temperatureC, windMs) {

    const diseaseFactors = {
        humidity: {
            critical: 6,
            high: 4,
            average: 2,
            low: 1,
        },
        temperature: {
            critical: 1.28,
            high: 1.2,
            average: 1.1,
            low: 1,
        },
        wind: {
            critical: 1.28,
            high: 1.2,
            average: 1.1,
            low: 1,
        }
    }

    let humidityFactor
    let temperatureFactor
    let windFactor

    if (humidityPerc <= 45) { humidityFactor = diseaseFactors.humidity.low }
    else if (humidityPerc <= 65) { humidityFactor = diseaseFactors.humidity.average }
    else if (humidityPerc <= 85) { humidityFactor = diseaseFactors.humidity.high }
    else if (humidityPerc <= 100) { humidityFactor = diseaseFactors.humidity.critical }
    else return

    if (temperatureC <= 12) { temperatureFactor = diseaseFactors.temperature.low }
    else if (temperatureC <= 15) { temperatureFactor = diseaseFactors.temperature.average }
    else if (temperatureC <= 18) { temperatureFactor = diseaseFactors.temperature.high }
    else if (temperatureC <= 100) { temperatureFactor = diseaseFactors.temperature.critical }
    else return

    if (windMs <= 1) { windFactor = diseaseFactors.wind.critical }
    else if (windMs <= 3) { windFactor = diseaseFactors.wind.high }
    else if (windMs <= 6) { windFactor = diseaseFactors.wind.average }
    else if (windMs <= 100) { windFactor = diseaseFactors.wind.low }
    else return

    return Math.round(10 * humidityFactor * temperatureFactor * windFactor) / 10
}

function calcHourlyLeafWetnessValue(tempHourlyAvg, dewPointHourlyAvg, humidityAvg) {
    if (humidityAvg >= 95) return 1 // Reece's addition, broadens the definition of 'leaf wetness'
    if (tempHourlyAvg <= dewPointHourlyAvg) return 1  //original formula
    // account for fluctuations within hourly averages, makes original formula more lenient
    if ((tempHourlyAvg - dewPointHourlyAvg) <= 0.2) return 1
    if ((tempHourlyAvg - dewPointHourlyAvg) <= 1) return 0.5
    return 0
}