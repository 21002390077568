import { useMemo } from "react"
import Loader from "../../_general/Loader"
import LinearGauge from "../../test/LinearGauge"
import { backendColors, colors } from "../../../style/vars"

const telemetryMetricIconMap = {
    'electricalConductivity': 'electrical_conductivity',
    'soilMoisture': 'moisture',
    'airHumidity': 'moisture',
    'temperatureCelsius': 'temperature',
    'airTemperatureCelsius': 'temperature',
    'groundTemperatureCelsius': 'temperature',
    'lightLevel': 'sun',
}

export default function TelemetryAvgLinearGauge({
    ratings,
    avg,
    name,
    telemetryMetric,
    unitSI,
    metricDecimals,
    ...props
}) {

    const { color, bins, binId, positionPercentage } = useMemo(() => {
        if (!ratings?.length) return {}

        const resultBin = ratings.find(r => ((r.min <= avg) && (r.max >= avg)))

        let lastFrontendCode

        const sortedRatings = ratings
            .toSorted((a, b) => a.min - b.min) // sort ratings ascending
            .filter(r => r.score >= 3) // remove ratings with extreme scores
            .map((r, i) => {  // mark where boundaries between colors are
                if (i === 0) {
                    lastFrontendCode = r.assessment.frontendCode
                    return r
                } else if (lastFrontendCode === r.assessment.frontendCode) {
                    return r
                } else {
                    lastFrontendCode = r.assessment.frontendCode
                    return { ...r, boundary: true }
                }
            })

        const gaugeMin = sortedRatings[0].min
        const gaugeMax = sortedRatings[sortedRatings.length - 1].max

        let positionPercentage  // find positioning of result within bin
        let binId  // which bin our result belongs in
        let color

        if (avg < gaugeMin) {  // out of bounds minimum
            positionPercentage = 0
            binId = sortedRatings[0].id
            color = backendColors[sortedRatings[0].assessment.frontendCode]
        } else if (avg > gaugeMax) {  // out of bounds maximum
            positionPercentage = 100
            binId = sortedRatings[sortedRatings.length - 1].id
            color = backendColors[sortedRatings[sortedRatings.length - 1].assessment.frontendCode]
        } else if (Number.isFinite(avg)) {  // calculate position of result
            if (!resultBin) return {}
            binId = resultBin.id
            const { min, max } = resultBin
            const range = max - min
            positionPercentage = ((avg - min) / range) * 100
            color = backendColors[resultBin.assessment.frontendCode]
        }

        return {
            color,
            bins: sortedRatings,
            binId: avg === null ? undefined : binId,
            positionPercentage,
        }
    }, [ratings, avg,])

    return !!ratings ?
        <LinearGauge
            avg={avg}
            color={color}
            bins={bins}
            binId={binId}
            positionPercentage={positionPercentage}
            name={name}
            iconColor={colors.main1}
            icon={telemetryMetricIconMap[telemetryMetric.systemLabel]}
            unitSI={unitSI}
            metricDecimals={metricDecimals}
            {...props}
        />
        :
        <Loader size={'2em'} />
}


