import React, { useContext } from 'react'
import { NavLink } from 'react-router'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import FallbackMessage from '../_general/FallbackMessage'
import PageSubtitle from '../_general/PageSubtitle'
import PageTitle from '../_general/PageTitle'
import ClubContext from '../../context/Club'
import LoaderText from '../_general/LoaderText'
import EZGrid from '../_layout/EZGrid'
import Loader from '../_general/Loader'
import PitchCardWithData from '../pitch/PitchCardWithData'
import { FormattedMessage } from 'react-intl'
import useClubVenuePitches from '../../hooks/useClubVenuePitches'

export default function ClubPitchList({
    ...props
}) {

    const [club] = useContext(ClubContext)
    const { venuePitches, clubLogo, isBusy } = useClubVenuePitches()

    return (
        <div {...props}>
            <PageTitle css={style.title}>
                <NavLink css={style.navLink} to='/'>
                    <FormattedMessage id='venues' /> &nbsp;
                </NavLink>
                <span css={css`color: ${colors.main1}; white-space: nowrap;`}>
                    {' / '}
                    {club?.name || <LoaderText subtle />}
                </span>
                {club?.logo && (
                    clubLogo
                        ? <img
                            css={style.logoImg}
                            alt={club.name}
                            src={clubLogo.file}
                        />
                        : <Loader size='2em' css={css`margin-left:.5em;`} />
                )}
            </PageTitle>
            {isBusy
                ?
                <>
                    <PageSubtitle>
                        <LoaderText />
                    </PageSubtitle>

                    <EZGrid w='23em' gap='2.5em'>
                        <PitchCardWithData />
                    </EZGrid>
                </>
                :
                Object.keys(venuePitches).length === 0
                    ?
                    <FallbackMessage>
                        <FormattedMessage id='noPitchesWarn' />
                    </FallbackMessage>
                    :
                    venuePitches.map(({ venueName, venue, pitches }, index) => (
                        <React.Fragment key={venueName ?? index}>
                            <PageSubtitle>
                                {venueName || <FormattedMessage id='unknownVenue' />}
                            </PageSubtitle>

                            <EZGrid w='23em' gap='2.5em' css={css`margin-bottom: 2.5em;`}>
                                {pitches?.map(pitch => (
                                    <PitchCardWithData key={pitch.id} venue={venue} pitch={pitch} />
                                ))}
                            </EZGrid>
                        </React.Fragment>
                    ))
            }
        </div>
    )
}

const style = {
    title: css`
        display: flex; 
        align-items: center; 
        max-width: 100%; 
        flex-wrap: wrap;
    `,
    navLink: css`
        text-decoration: none; 
        color: inherit; 
        white-space: nowrap;
    `,
    logoImg: css`
        margin-left: .5em; 
        height: 2em; 
        width: auto;
    `,
}