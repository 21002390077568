import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import DatePickerCta from '../_control/DatePickerCta'
import FancyDropdown from '../_control/FancyDropdown'
import BasicMeasurementPicker from '../_control/BasicMeasurementPicker'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import FieldSelector from '../_control/FieldSelector'
import PlayerNumberField from '../_control/PlayerNumberField'
import { getUnixTime } from 'date-fns'
import { uniqBy } from 'lodash'
import Orientation from '../test/Orientation'
import { css } from '@emotion/react'
import Pitch from '../../context/Pitch'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'

function parseOptions(inputObject) {
    return inputObject
        ? Object.entries(inputObject)
            .map(([value, label]) => ({ label, value }))
        : []
}

export default function InjuryForm({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [returnDate, setReturnDate] = useState()
    const [severeness, setSevereness] = useState()
    const [reason, setReason] = useState()
    const [xPos, setXPos] = useState()
    const [yPos, setYPos] = useState()
    const [playerNumber, setPlayerNumber] = useState(0)
    const [selectedBodyPart, setSelectedBodyPart] = useState()
    const [selectedInjuryType, setSelectedInjuryType] = useState()
    const [injury, setInjury] = useState()
    //other
    const { data: bodyParts, loading: waitingForBodyParts } = useBridge(`/api/current/frontend-bridge/body-impact-zones`)
    const { data: injuryTypes, loading: WaitingForInjuryTypes } = useBridge(`/api/current/frontend-bridge/injury-types`)
    const { data: injuryReasons, loading: waitingForInjuryReasons } = useBridge(`/api/current/frontend-bridge/injury-event-reason`)
    const { data: injuriesRawData, loading: waitingForInjuriesData } = useBridge(`/api/current/injuries`)
    const [pitch, { busy: contextLoading }] = useContext(Pitch)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/injury-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setReturnDate(new Date(data.returnDate * 1000))
                setSevereness(data.severeness)
                setReason(data.reason)
                setXPos(data.posFracX)
                setYPos(data.posFracY)
                setPlayerNumber(data.playerNumber)
                setSelectedBodyPart(data.injury?.bodyPart)
                setSelectedInjuryType(data.injury?.injuryType)
                setInjury(data.injury)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // parse reasons from key-val object to list of options for dropdown
    const injuryReasonOptions = useMemo(() => (
        parseOptions(injuryReasons)
    ), [injuryReasons])

    const bodyPartOptions = useMemo(() => {
        return parseOptions(bodyParts)
    }, [bodyParts])

    const injuryTypeOptions = useMemo(() => {
        return parseOptions(injuryTypes)
    }, [injuryTypes])

    const isBusy = useMemo(() => (
        isLoading || waitingForBodyParts || WaitingForInjuryTypes || waitingForInjuriesData || waitingForInjuryReasons || contextLoading
    ), [isLoading, waitingForBodyParts, WaitingForInjuryTypes, waitingForInjuriesData, waitingForInjuryReasons, contextLoading])

    // only show injuries that match the given filters
    // also include current selection, if it exists (ohterwise funky rendering stuff happens)
    const injuryOptions = useMemo(() => {
        let options = injuriesRawData
            ? uniqBy(
                // apply filters to options
                injuriesRawData?.filter(({ injuryType, bodyPart }) => (
                    ((!selectedInjuryType) || (injuryType == selectedInjuryType)) &&
                    ((!selectedBodyPart) || (bodyPart == selectedBodyPart))
                ))
                // deduplicate names after filtering
                , 'name'
            ).map(({ name, id }) => ({ label: name, value: id }))
            : []
        return options
    }, [selectedBodyPart, selectedInjuryType, injuriesRawData, injury])

    // Select an injury and set the injury type filter to match (if not already equal)
    const selectInjury = useCallback((val) => {
        const selectedId = val?.value
        // clear selection
        if (!selectedId) return setInjury()

        const { injuryType, ...targetInjury } = injuriesRawData.find(({ id }) => (id == selectedId))

        // set tissue filter to match selected injury
        if (injuryType && selectedInjuryType?.value !== injuryType) setSelectedInjuryType(injuryType)

        setInjury({ injuryType, ...targetInjury })
    }, [injuriesRawData, selectedBodyPart, selectedInjuryType])

    const selectInjuryType = useCallback((value) => {
        // clear selection
        if (!value) {
            selectInjury()
            setSelectedInjuryType()
            return
        }

        setSelectedInjuryType(value)
    }, [selectInjury])

    const selectBodyPart = useCallback((value) => {
        if (!value) {
            selectInjuryType()
            setSelectedBodyPart()
            return
        }
        setSelectedBodyPart(value)
    }, [selectInjuryType])

    // when the required fields are set the form is valid and will submit.
    const validateAndSubmit = useCallback(async () => {
        if (!injury) return setFormError(formatMessage({ id: 'pleaseSelectInjury' }))

        setFormError()
        setIsLoading(true)

        const basePayload = {
            timestamp: getUnixTime(selectedDateTime),
            injury: injury['@id'],
            name: injury?.name,
            severeness: severeness ?? null,
            reason: reason ?? null,
            returnDate: returnDate ? getUnixTime(returnDate) : null,
            posFracX: xPos ?? null,
            posFracY: yPos ?? null,
            playerNumber: playerNumber ? parseInt(playerNumber, 10) : 0,
        }

        if (event) {

            const { data, error } = await put(`${event['@id']}`, { body: basePayload })

            if (error) {
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...basePayload,
                pitch: pitch['@id'],
            }

            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })

            if (error) {
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [pitch, injury, returnDate, severeness, reason, playerNumber, xPos, yPos, selectedDateTime])

    const selectedBodyPartOption = useMemo(() => {
        return bodyPartOptions?.find(bdp => bdp.value === selectedBodyPart)
    }, [bodyPartOptions, selectedBodyPart])

    const selectedInjuryTypeOption = useMemo(() => {
        return injuryTypeOptions?.find(inj => inj.value === selectedInjuryType)
    }, [injuryTypeOptions, selectedInjuryType])

    const selectedInjuryOption = useMemo(() => {
        return injuryOptions?.find(inj => inj.value === injury?.id)
    }, [injuriesRawData, injuryOptions, injury])

    const selectedReasonOption = useMemo(() => {
        return injuryReasonOptions?.find(rsn => rsn.value === reason)
    }, [injuryReasonOptions, reason])

    return isBusy ? <FormLoader /> : <>
        <div css={style.allFieldsWrapper}>
            <div>
                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />
            </div>
            <div />
            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='bodyPart' />
                </h3>
                <FancyDropdown
                    compact={true}
                    css={css` max-width: 16em; `}
                    options={bodyPartOptions}
                    value={selectedBodyPartOption}
                    subtle={true}
                    isClearable={true}
                    placeholder={formatMessage({ id: 'selectBodyPart' })}
                    onChange={(val) => { selectBodyPart(val?.value) }} />
            </div>

            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='severeness' />
                </h3>
                <BasicMeasurementPicker
                    css={{ padding: "4px" }}
                    selected={severeness ? severeness : null}
                    values={[1, 2, 3, 4, 5]}
                    onChange={(v) => setSevereness(v)}
                />
            </div>

            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='tissue' />
                </h3>
                <FancyDropdown
                    compact={true}
                    css={css` max-width: 16em; `}
                    options={injuryTypeOptions}
                    value={selectedInjuryTypeOption ?? null}
                    subtle={true}
                    isClearable={true}
                    placeholder={formatMessage({ id: 'selectInjuryType' })}
                    onChange={(val) => { selectInjuryType(val?.value) }} />
            </div>

            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='returnDate' />
                </h3>
                <DatePickerCta
                    date={returnDate}
                    onDateChange={(date) => setReturnDate(date)}
                    datePickerProps={{
                        modifiers: {
                            disabled: {
                                before: selectedDateTime
                            }
                        }
                    }}
                />
            </div>

            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='diagnosis' />
                </h3>
                <FancyDropdown
                    compact={true}
                    css={css` max-width: 16em; `}
                    options={injuryOptions}
                    value={selectedInjuryOption ?? null}
                    subtle={true}
                    isClearable={true}
                    placeholder={selectedBodyPart ? formatMessage({ id: 'selectTheInjury' }) : formatMessage({ id: 'selectBodyPartFirst' })}
                    onChange={(val) => { selectInjury(val) }}
                    disabled={!selectedBodyPart} />
            </div>

            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='injuryMechanism' />
                </h3>
                <FancyDropdown
                    compact={true}
                    css={css` max-width: 16em; `}
                    options={injuryReasonOptions}
                    value={selectedReasonOption}
                    subtle={true}
                    placeholder={formatMessage({ id: 'selectAReason' })}
                    onChange={(val) => setReason(val?.value)} />
            </div>

            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='markInjuryPosition' />
                </h3>
                <div css={style.fieldwrapper}>
                    <FieldSelector
                        backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                        x={xPos}
                        y={yPos}
                        onChange={(x, y) => {
                            setXPos(x)
                            setYPos(y)
                        }}
                    />
                    <Orientation
                        css={style.compass}
                        heading={pitch.orientation}
                    />
                </div>
            </div>

            <div>
                <h3 css={{ marginBottom: "8px" }}>
                    <FormattedMessage id='playerNumber' />
                </h3>
                <PlayerNumberField
                    value={playerNumber}
                    onChange={(e) => setPlayerNumber(e.target.value)}
                    onClick={() => playerNumber == 0 ? setPlayerNumber('') : null}
                />
            </div>

        </div>

        <div css={{ marginTop: '3em', lineHeight: '1.5em', opacity: 0.4 }}>
            <a href="https://www.johnorchard.com/about-osiics.html"
                target="_blank"
                rel="noreferrer noopener"
                css={{ color: "inherit", outline: "inherit" }} >
                <strong>OSIICS, Orchard Sports Injury and Illness Classification System</strong>
            </a>
        </div>

        <div css={css`display: flex; align-items: center; flex-direction: row-reverse; justify-content: space-between;`} >
            <button
                onClick={() => validateAndSubmit()}
                type='button'
                className="btn"
                disabled={isLoading}
            >
                <FormattedMessage id='save' />
            </button>
            <div>{formError && formError}</div>
        </div>
    </>
}

const style = {
    allFieldsWrapper: css`
        display: grid;
        grid-template-columns: 3fr 2fr;
        row-gap: 20px;
        column-gap: 16px;
        justify-items: stretch;

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }
    `,
    fieldwrapper: css`
        position: relative;
        display: inline-flex;
    `,
    compass: css`
        position: absolute;
        height: 2em;
        width: 2em;
        bottom: -2.5em;
        left: calc(50% - 1em);
        background-color: rgba(44,52,58);
        box-shadow: none;
    `,
}
