import React, { useCallback, useState } from 'react'
import { css } from '@emotion/react'
import { fonts } from '../../style/vars'
import Modal from '../_layout/Modal'
import WysiwygEditor from '../wysiwyg/WysiwygEditor'
import Cta from '../_control/Cta'
import LoaderText from '../_general/LoaderText'
import KeyValue from '../_general/KeyValue'
import { FormattedMessage, useIntl } from 'react-intl'
import useLdjsonApi from '../../hooks/useLdjsonApi'

export default function TestAdminModal({
    test,
    onSave,
    ...props
}) {
    const { put } = useLdjsonApi()
    const { formatMessage } = useIntl()

    const [summary, setSummary] = useState(test.comment)

    const [loading, setLoading] = useState(false)

    const handleSubmit = useCallback(async () => {
        setLoading(true)
        try {
            await put(`/api/current/tests/${test.id}`, { body: { comment: summary || null, } })
                .then(() => {
                    if (onSave) onSave()
                })
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }, [summary, onSave, put, test])

    return (
        <Modal {...props}>
            <h6 css={css`font-size: 2em; font-weight: 300; font-family: ${fonts.special}; margin-bottom: 1em;`}>
                <span css={css`text-transform: capitalize;`}>
                    <FormattedMessage id='editTest' />
                </span>
                <span>&nbsp;</span>
                <span css={css`opacity: 0.5;`}>
                    {' - '}
                    {test
                        ?
                        test.testCategory ? test.testCategory.name : <FormattedMessage id='errorNotFound' />
                        :
                        <LoaderText />
                    }
                </span>
            </h6>
            <div css={css`display: flex; flex-direction: column; width: 100%; gap: 1em;`}>
                <KeyValue
                    k={formatMessage({ id: 'summary' }).toLowerCase()}
                    v={<WysiwygEditor
                        value={summary}
                        onBlur={setSummary}
                        css={css`text-transform: none;`}
                    />}
                    css={css`text-transform: capitalize;`}
                    busy={loading}
                />
                <Cta
                    signal='positive'
                    css={css`align-self: flex-end;`}
                    onClick={handleSubmit}
                    disabled={loading}
                    loading={loading}
                >
                    <FormattedMessage id='save' />
                </Cta>
            </div>
        </Modal>
    )
}