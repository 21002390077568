import { useCallback } from "react"
import { useIntl } from "react-intl"

export default function useFormattedError() {

    const { formatMessage } = useIntl()

    const getErrorMessageFromCode = useCallback((code) => {

        if (!(typeof code === 'number' || typeof code === 'string')) return formatMessage({ id: 'error' })

        const codeString = '' + code
        const firstNum = codeString[0]

        let translationKey

        if (firstNum === '4') {
            switch (codeString) {
                case '400': translationKey = 'badRequest'; break; /* bad request */
                case '401': translationKey = 'invalidCredentials'; break; /* invalid credentials */
                case '403': translationKey = 'noAccessRights'; break; /* no access rights */
                case '404': translationKey = 'notFound'; break; /* not found */
                case '429': translationKey = 'tooManyRequests'; break; /* too many requests */
            }
        } else if (firstNum === '5') {
            switch (codeString) {
                case '500': translationKey = 'serverError'; break; /* server error */
                case '503': translationKey = 'serviceUnavailable'; break; /* service temporarily unavailable, please try again later */
            }
        }

        const message = `${formatMessage({ id: 'error' })}: ${translationKey ? formatMessage({ id: translationKey }) : codeString}`

        return message

    }, [formatMessage])

    return {
        getErrorMessageFromCode,
    }
}