import { useContext, useMemo, useState } from "react"
import PitchTelemetry from "../../../context/covermaster/PitchTelemetry"
import CovermasterContext from "../../../context/covermaster/Covermaster"
import { css } from "@emotion/react"
import PageSubtitle from "../../_general/PageSubtitle"
import useRawApiState from "../../../hooks/useRawApiState"
import Loader from "../../_general/Loader"
import FancyDropdown from "../../_control/FancyDropdown"
import { toZonedTime } from "date-fns-tz"
import { addHours, subHours } from "date-fns"
import TimeSeriesWeather from "../../graphs/TimeSeriesWeather"
import useUnit from "../../../hooks/useUnit"

export default function TelemetryHistorySection({ className, ...props }) {

    const { formatUnitName, convertToUnits } = useUnit()

    const { metricTypes } = useContext(CovermasterContext)
    const {
        titleMessage,
        pitch,
        selectedMetricType,
        setSelectedMetricType,
        selectedMetricTypeUnit,
    } = useContext(PitchTelemetry)

    const { data: venueDetails, isBusy: venueBusy } = useRawApiState(pitch?.venue)

    const selectedMetricDetails = useMemo(() => {
        return metricTypes.find(m => m['@id'] === selectedMetricType)
    }, [selectedMetricType, metricTypes])

    const requestUrl = useMemo(() => {
        return buildHistoryUrl(pitch, selectedMetricDetails)
    }, [pitch, selectedMetricDetails])

    const { data: historyData, isBusy: historyDataBusy } = useRawApiState(requestUrl, {}, [requestUrl])

    const metricDropdownOptions = useMemo(() => {
        return metricTypes?.map(type => ({
            value: type['@id'],
            label: type.title,
        })) ?? []
    }, [metricTypes])

    const loading = historyDataBusy

    const [timeRange, setTimeRange] = useState('14d')  /* 14d, 7d, 24h */

    const aggregatedData = useMemo(() => {
        if (!historyData || !venueDetails) return []

        // take now (local)
        const nowLocal = new Date()
        //convert to pitch time
        const timezoneWithFallback = venueDetails?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        const dateOnPitch = toZonedTime(nowLocal, timezoneWithFallback)  // displays correct in browser, but timestamp is not real
        //truncate to hour
        dateOnPitch.setMinutes(0, 0, 0)
        //Add one hour
        const finalBinDate = addHours(dateOnPitch, 1)  // in converted time for pitch
        // aggregate every h backwards from this point, for 14 days ^ 
        // calculate average value per group  (don't forget to convert timestamps to pitch time, before comparing to bins)

        // 14 days of data is the max of what is needed
        const dateBins = []
        for (let i = 0; i < 14 * 24; i++) {
            dateBins.unshift({
                date: subHours(finalBinDate, i),
                resultsOver: [],
                resultsUnder: [],
            }) // we want it chronologically ascending for the graph
        }

        //aggregate our data in these bins
        for (const telemetry of historyData) {
            const deviceMode = telemetry.deviceMode

            if (deviceMode !== 'under-cover' && deviceMode !== 'over-cover') continue

            telemetry.convertedDate = toZonedTime(new Date(telemetry.timestamp * 1000), timezoneWithFallback)

            // add this to the correct bin
            for (const bin of dateBins) {
                if (telemetry.convertedDate.valueOf() <= bin.date.valueOf()) {
                    deviceMode === 'under-cover' ? bin.resultsUnder.push(telemetry) : bin.resultsOver.push(telemetry)
                    break
                }
            }
        }

        // calculate one average per bin per devicemode
        for (const bin of dateBins) {
            bin.avgOver = bin.resultsOver.length ? bin.resultsOver.reduce((a, b) => a + parseFloat(b.value), 0) / bin.resultsOver.length : undefined
            bin.avgUnder = bin.resultsUnder.length ? bin.resultsUnder.reduce((a, b) => a + parseFloat(b.value), 0) / (bin.resultsUnder.length) : undefined
        }

        return dateBins

    }, [historyData, pitch, venueDetails])

    const { overData, underData } = useMemo(() => {
        let entries

        if (timeRange === '14d') {
            entries = 14 * 24
        } else if (timeRange === '7d') {
            entries = 7 * 24
        } else {
            entries = 24
        }

        const filtered = aggregatedData.slice(-entries)

        return {
            overData: filtered
                .filter(bin => bin.avgOver !== undefined)
                .map(bin => ({
                    x: bin.date,
                    y: convertToUnits(
                        bin.avgOver,
                        selectedMetricTypeUnit,
                        {
                            useSecondaryUnit: false,
                            returnUnconvertedWhenUnknownUnit: true,
                        }
                    ).primaryValue
                })),
            underData: filtered
                .filter(bin => bin.avgUnder !== undefined)
                .map(bin => ({
                    x: bin.date,
                    y: convertToUnits(
                        bin.avgUnder,
                        selectedMetricTypeUnit,
                        {
                            useSecondaryUnit: false,
                            returnUnconvertedWhenUnknownUnit: true,
                        }
                    ).primaryValue 
                })),
        }
    }, [aggregatedData, timeRange, selectedMetricTypeUnit])

    const modeOptions = useMemo(() => [
        { value: '24h', label: '24h' }, /* TO DO: TRANSLATE */
        { value: '7d', label: '7d' },
        { value: '14d', label: '14d' },
    ], [])

    return <div css={className}>
        <div css={style.titleWrapper}>
            <PageSubtitle css={css`grid-column: span 2; margin: 0; font-size: 1.5em;`}>
                {titleMessage}{/* TO DO: translate */}
            </PageSubtitle>

            <div css={css`display: flex; flex-wrap: wrap; gap: 1em;`}>
                <FancyDropdown
                    options={metricDropdownOptions}
                    value={metricDropdownOptions.find(x => x.value === selectedMetricType)}
                    onChange={(val) => setSelectedMetricType(val.value)}
                    css={style.dropdown}
                    subtle={true}
                    hideArrow={true}
                />
                <FancyDropdown
                    options={modeOptions}
                    value={modeOptions.find(x => x.value === timeRange)}
                    onChange={(val) => setTimeRange(val.value)}
                    css={[style.dropdown, css`width: min-content;`]}
                    subtle={true}
                    hideArrow={true}
                />
            </div>
        </div>

        {loading ?
            <div css={css`display: grid; place-items: center; grid-column: span 2;`}>
                <Loader size='2.5em' />
            </div>
            :
            <div css={css`
                padding: 40px;
                grid-column: span 2;
                background: rgba(0,0,0,0.1);
                border-radius: 3px;
                position: relative;
            `}>
                {!overData.length && !underData.length ?
                    <PageSubtitle css={css`display: grid; place-items: center; font-size: 1.5em;`}>
                        {'No data'}{/* TO DO: translate */}
                    </PageSubtitle>
                    :
                    <TimeSeriesWeather
                        field1Data={overData}
                        field2Data={underData}
                        label1={'Over'}  /* TO DO: translate */
                        label2={'Under'}/* TO DO: translate */
                        unit1={formatUnitName(selectedMetricTypeUnit)}
                        unit2={formatUnitName(selectedMetricTypeUnit)}
                        getDomain1={(min, max) => [
                            (min < 0 ? min * 1.2 : min * 0.8),
                            (max < 0 ? max * 0.5 : max * 1.2)
                        ]}
                        getDomain2={(min, max) => [
                            (min < 0 ? min * 1.2 : min * 0.8),
                            (max < 0 ? max * 0.5 : max * 1.2)
                        ]}
                        modeToggleState={timeRange}
                    />
                }
            </div>
        }
    </div>
}

function buildHistoryUrl(pitch, metricType) {
    const fetchTelemetries = !!pitch && !!metricType
    if (!fetchTelemetries) return undefined

    let url = '/api/current/telemetries?'

    const query = {
        pitch: pitch.id,
        pagination: false,
        'timestamp[gte]': Math.ceil(new Date().valueOf() / 1000) - 3600 * 24 * 14,  // last 14 days and up
        'telemetryMetric': metricType.id,
        'order[timestamp]': 'asc',
        //'device.deviceType': 'cover-master', /* TO DO: add this when we can test with real cover-master sensors */
    }

    for (const [key, val] of Object.entries(query)) {
        url += `${key}=${val}&`
    }

    return url
}

const style = {
    titleWrapper: css`
        grid-column: span 2; 
        display: flex; 
        justify-content: 
        space-between; 
        flex-wrap: wrap; 
        gap: 1em;
    `,
}