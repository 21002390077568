import React from 'react'
import { css } from '@emotion/react'
import {ReactSVG} from 'react-svg'
import { colors, fonts } from '../../style/vars'
import compassIcon from '../../assets/icons/compass.svg'

const style = {
    cont: css`
        height: 4em;
        width: 4em;
        border-radius: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${colors.stuff};
        box-shadow: 0 0.25em 0.75em 0 rgba(0,0,0,0.3);
    `,
    compass: css`
        height: 2em;
        width: 2em;
        border-radius: 1.75em;
        border: 0.0625em solid ${colors.subtle};
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-family: ${fonts.main};
        font-weight: 700;
        position: relative;
    `,
}

const degreeOffset = -90
const headingDegreeMap = {
    N: 0,
    NE: -45,
    E: -90,
    SE: -135,
    S: -180,
    SW: -225,
    W: -270,
    NW: -315,
}

export default function Orientation({ heading, ...props }) {
    const headingDegrees = (heading ? headingDegreeMap[heading] : 0) + degreeOffset
    return (
        <div css={style.cont} {...props}>
            <div css={style.compass}>
                <ReactSVG
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        transform: rotate(${headingDegrees}deg);
                        transition: all 500ms ease;
                        color: ${colors.main1};
                        opacity: ${heading ? 1 : 0.25};

                        > div {
                            width: 60%;
                        }
                    `}
                    src={compassIcon}
                />
            </div>
        </div>
    )
}