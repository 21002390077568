
import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { css } from '@emotion/react'
import Selector from '../_control/OptionsSelector'
import NumberScroller from '../_control/NumberScroller'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import useBridge from '../../hooks/useBridge'
import { getUnixTime } from 'date-fns'
import FormLoader from './FormLoader'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import PitchContext from '../../context/Pitch'

export default function GroomingForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [groomingType, setGroomingType] = useState()
    const [groomingMachine, setGroomingMachine] = useState()
    const [groomingDirection, setGroomingDirection] = useState()
    const [groomingDebrisAmount, setGroomingDebrisAmount] = useState(0)
    //other
    const { data: groomingTypeOptions, loading: waitingForGroomingTypeOptions } = useBridge(`/api/current/frontend-bridge/grooming-types`)
    const { data: groomingMachineOptions, loading: waitingForGroomingMachineOptions } = useBridge(`/api/current/frontend-bridge/grooming-machines`)
    const { data: directions, loading: waitingForDirections } = useBridge(`/api/current/frontend-bridge/directions`)
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/grooming-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setGroomingType(data.groomingType)
                setGroomingMachine(data.machine)
                setGroomingDirection(data.direction)
                setGroomingDebrisAmount(data.debrisAmount)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {

        if (!groomingType) return setFormError(formatMessage({ id: 'pleaseSelectGroomingType' }))
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setFormError()
        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            groomingType,
            machine: groomingMachine,
            direction: groomingDirection,
            debrisAmount: groomingDebrisAmount ? parseFloat(groomingDebrisAmount) : 0,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Grooming event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }
            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [groomingType, groomingDirection, groomingMachine, groomingDebrisAmount, pitch, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => {
        return waitingForGroomingTypeOptions || waitingForGroomingMachineOptions || waitingForDirections || isLoading
    }, [waitingForGroomingTypeOptions, waitingForGroomingMachineOptions, waitingForDirections, isLoading])

    return (<>
        <div css={css`display: flex;`}>
            {isBusy ? <FormLoader /> : (
                <div css={css`display: flex; flex-direction: column; align-items: flex-start;`}>
                    <EventDateTimePicker
                        unixDate={selectedDateTime}
                        setUnixDate={setSelectedDateTime}
                    />
                    <div css={css`
                        display: flex; 
                        gap: 0 3em;
                        flex-wrap: wrap;
                    `}>
                        <NumberScroller
                            label={formatMessage({ id: 'timeTaken' })}
                            minVal={0}
                            maxVal={24}
                            state={timeTaken}
                            unit={formatMessage({ id: 'hours' })}
                            step={0.5}
                            setState={setTimeTaken}
                        />
                        <NumberScroller
                            label={formatMessage({ id: 'debrisAmount' })}
                            minVal="0"
                            maxVal="5000"
                            unit="kg"
                            setState={setGroomingDebrisAmount}
                            state={groomingDebrisAmount}
                        />
                    </div>
                    <Selector
                        label={formatMessage({ id: 'type' })}
                        optionsObject={groomingTypeOptions}
                        setState={setGroomingType}
                        state={groomingType}
                    />
                    <Selector
                        label={formatMessage({ id: 'machine' })}
                        optionsObject={groomingMachineOptions}
                        setState={setGroomingMachine}
                        state={groomingMachine}
                    />
                    <Selector
                        label={formatMessage({ id: 'direction' })}
                        optionsObject={directions}
                        setState={setGroomingDirection}
                        state={groomingDirection}
                    />

                </div>
            )}
        </div>
        {formError && <div>{formError}</div>  }
        <button
            className="btn"
            type="button"
            disabled={isBusy}
            onClick={(e) => { validateAndSubmit(e) }}
        >
            <FormattedMessage id='save' />
        </button>
    </>)
}