import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import smileHappyActive from '@assets/icons/smile-active.svg'
import smileNeutralActive from '@assets/icons/neutral-smile-active.svg'
import smileSadActive from '@assets/icons/sad-smile-active.svg'
import commentIcon from '@assets/icons/comment.svg'
import { ReactSVG } from 'react-svg'
import { useIntl } from 'react-intl'
import useBridge from '../../hooks/useBridge'
import Loader from '../_general/Loader'
import dp from 'dompurify'
import useUnit from '../../hooks/useUnit'

const getSmile = (score) => {
    const srcImg = score < 40 ? smileSadActive
        : score < 70 ? smileNeutralActive
            : smileHappyActive
    return <ReactSVG css={{ margin: 6 }} src={srcImg} />
}

function Bullet({ text, ...props }) {
    return <div css={{
        display: "flex",
        width: '100%',
        padding: '2px',
        overflow: 'hidden'
    }} {...props}>
        <div css={{ whiteSpace: "wrap", display: 'flex' }}>
            <b css={{ marginRight: '5px' }}>•</b>
            {text}
        </div>
    </div>
}

export default function EventSummary({ event, ...props }) {

    const { formatMessage } = useIntl()

    //these hooks short-circuit and return nothing when first argument is falsy
    const { data: injuryReasons, loading: loadingInjury } = useBridge(
        event.type === 'InjuryEvent' && `/api/current/frontend-bridge/injury-event-reason`)

    const { data: activityTypes, loading: loadingActivity } = useBridge(
        event.type === 'ActivityEvent' && `/api/current/frontend-bridge/activity-event-types`)

    const { data: fertilisingTypes, loading: loadingFertTypes } = useBridge(
        event.type === 'FertilisingEvent' && `/api/current/frontend-bridge/fertiliser-types`)
    /* const { data: unitsOptions, loading: loadingUnits } = useBridge(
        event.type === 'FertilisingEvent' && `/api/current/frontend-bridge/units`) */

    const { data: seedingSpeciesOptions, loading: loadingSpecies } = useBridge(
        event.type === 'SeedingEvent' && `/api/current/frontend-bridge/seeding-species`)
    const { data: seedingTypeOptions, loading: loadingSeedingTypes } = useBridge(
        event.type === 'SeedingEvent' && `/api/current/frontend-bridge/seeding-types`)

    const { data: mowingTechniques, loading: loadingMowingTech } = useBridge(
        event.type === 'MowingEvent' && `/api/current/frontend-bridge/mowing-techniques`)
    const { data: mowingMachines, loading: loadingMowingMachines } = useBridge(
        event.type === 'MowingEvent' && `/api/current/frontend-bridge/mowing-machine-types`)
    const { data: mowingDirections, loading: loadingMowingDirections } = useBridge(
        event.type === 'MowingEvent' && `/api/current/frontend-bridge/directions`)

    const { data: groomingDirections, loading: loadingGroomingDirections } = useBridge(
        event.type === 'GroomingEvent' && `/api/current/frontend-bridge/directions`)
    const { data: groomingTypes, loading: loadingGroomingTypes } = useBridge(
        event.type === 'GroomingEvent' && `/api/current/frontend-bridge/grooming-types`)
    const { data: groomingMachines, loading: loadingGroomingMachines } = useBridge(
        event.type === 'GroomingEvent' && `/api/current/frontend-bridge/grooming-machines`)

    const { data: aerationTypes, loading: loadingAerationTypes } = useBridge(
        event.type === 'AerationEvent' && `/api/current/frontend-bridge/aeration-types`)
    const { data: aerationHeives, loading: loadingAerationHeives } = useBridge(
        event.type === 'AerationEvent' && `/api/current/frontend-bridge/aeration-heive`)
    const { data: aerationSpacings, loading: loadingAerationSpacings } = useBridge(
        event.type === 'AerationEvent' && `/api/current/frontend-bridge/aeration-spacing`)

    const { data: trainingTypes, loading: loadingTrainingTypes } = useBridge(
        event.type === 'TrainingEvent' && `/api/current/frontend-bridge/training-types`)
    const { data: trainingTeams, loading: loadingTrainingTeams } = useBridge(
        event.type === 'TrainingEvent' && `/api/current/frontend-bridge/training-teams`)

    const { data: matchTeams, loading: loadingMatchTeams } = useBridge(
        event.type === 'MatchEvent' && `/api/current/frontend-bridge/training-teams`)

    const { data: heatingTypes, loading: loadingHeatingTypes } = useBridge(
        event.type === 'HeatingEvent' && `/api/current/frontend-bridge/heating-types`)
    const { data: heatingTemperatures, loading: loadingHeatingTemperatures } = useBridge(
        event.type === 'HeatingEvent' && `/api/current/frontend-bridge/heatingTemperatures`)

    const { data: diseaseTypes, loading: loadingDiseaseTypes } = useBridge(
        event.type === 'DiseaseEvent' && `/api/current/frontend-bridge/disease-types`)

    const { data: treatmentTypes, loading: loadingTreatmentTypes } = useBridge(
        event.type === 'BioTreatmentEvent' && `/api/current/frontend-bridge/biotreatment-event-treatment-types`)
    const { data: treatmentMethods, loading: loadingTreatmentMethods } = useBridge(
        event.type === 'BioTreatmentEvent' && `/api/current/frontend-bridge/biotreatment-event-treatment-methods`)

    const { data: lineMarkingMethods, loading: loadingLineMarkingMethods } = useBridge(
        event.type === 'LineMarkingEvent' && `/api/current/frontend-bridge/line-marking-methods`)

    const { data: topDressingMaterials, loading: loadingTopDressingMaterials } = useBridge(
        event.type === 'TopDressingEvent' && '/api/current/frontend-bridge/top-dressing-materials'
    )

    const { data: coverProductOptions, loading: loadingCoverProducts } = useBridge(
        event.type === 'CoverEvent' && `/api/current/frontend-bridge/cover-event-products`)

    const { data: coverTypeOptions, loading: loadingCoverTypes } = useBridge(
        event.type === 'CoverEvent' && `/api/current/frontend-bridge/cover-event-types`)

    const unitsOptions = {
        litres: 'L',
        kilograms: 'kg',
    }

    const isLoading = useMemo(() => {
        return loadingInjury || loadingActivity || loadingFertTypes || loadingSpecies || loadingSeedingTypes ||
            loadingMowingTech || loadingMowingMachines || loadingMowingDirections || loadingGroomingDirections || loadingGroomingTypes ||
            loadingGroomingMachines || loadingAerationTypes || loadingAerationHeives || loadingAerationSpacings || loadingTrainingTypes ||
            loadingTrainingTeams || loadingMatchTeams || loadingHeatingTypes || loadingHeatingTemperatures || loadingDiseaseTypes ||
            loadingTreatmentTypes || loadingTreatmentMethods || loadingLineMarkingMethods || loadingTopDressingMaterials || loadingCoverProducts || loadingCoverTypes
    }, [
        loadingInjury, loadingActivity, loadingFertTypes, loadingSpecies, loadingSeedingTypes,
        loadingMowingTech, loadingMowingMachines, loadingMowingDirections, loadingGroomingDirections, loadingGroomingTypes,
        loadingGroomingMachines, loadingAerationTypes, loadingAerationHeives, loadingAerationSpacings, loadingTrainingTypes,
        loadingTrainingTeams, loadingMatchTeams, loadingHeatingTypes, loadingHeatingTemperatures, loadingDiseaseTypes,
        loadingTreatmentTypes, loadingTreatmentMethods, loadingLineMarkingMethods, loadingTopDressingMaterials, loadingCoverProducts, loadingCoverTypes
    ])

    const { formatUnit, formatHeatingEventTemperature, formatUnitName } = useUnit()

    if (isLoading) return <Loader />

    switch (event.type) {
        case "ActivityEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet css={{ textTransform: "capitalize" }}
                    text={event.activityType && findInNestedObjects(activityTypes, event.activityType).key}
                />
                <Bullet css={{ textTransform: "capitalize" }}
                    text={event.activityType && findInNestedObjects(activityTypes, event.activityType).value}
                />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "InjuryEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={event.name} />
                <Bullet text={`${formatMessage({ id: 'playerNumber' })}: ${event.playerNumber}`} />
                <Bullet text={injuryReasons[event.reason]} />
                <Bullet text={`${formatMessage({ id: 'severeness' })}: ${event.severeness}`} />
            </div>
        case "FertilisingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'type' })}: ${fertilisingTypes[event.fertilisingType]}`} />
                <Bullet text={formatUnit(event.amount, unitsOptions[event.unit], { metricPrecision: 0, showMetricUnit: true })} />
                {event?.gravity && <Bullet text={`${formatMessage({ id: 'gravity' })}: ${event.gravity}`} />}
                <Bullet text={`${formatMessage({ id: 'nitrogen' })}: ${event.productNitrogen} %`} />
                <Bullet text={`${formatMessage({ id: 'phosphorus' })}: ${event.productPhosphorus} %`} />
                <Bullet text={`${formatMessage({ id: 'potassium' })}: ${event.productPotassium} %`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "SeedingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'type' })}: ${seedingTypeOptions[event.seedingType]}`} />
                <Bullet text={`${formatMessage({ id: 'amount' })}: ${formatUnit(event.amount, 'kg', { metricPrecision: 0, showMetricUnit: true })}`} />
                <Bullet text={`${formatMessage({ id: 'species' })}: ${seedingSpeciesOptions[event.species]}`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "MowingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'mowingTechnique' })}: ${mowingTechniques[event.mowingTechnique]}`} />
                <Bullet text={`${formatMessage({ id: 'heightOfCut' })}: ${formatUnit(event.mowingHeight, 'mm', { metricPrecision: 0, showMetricUnit: true })}`} />
                <Bullet text={`${formatMessage({ id: 'type' })}: ${mowingMachines[event.machineType]}`} />
                <Bullet text={`${formatMessage({ id: 'clippingVolume' })}: ${formatUnit(event.clippingWeights, 'L', { metricPrecision: 0, showMetricUnit: true })}`} />
                <Bullet text={`${formatMessage({ id: 'direction' })}: ${mowingDirections[event.mowingDirection]}`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "GroomingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'type' })}: ${groomingTypes[event.groomingType]}`} />
                <Bullet text={`${formatMessage({ id: 'direction' })}: ${groomingDirections[event.direction]}`} />
                <Bullet text={`${formatMessage({ id: 'machine' })}: ${groomingMachines[event.machine]}`} />
                <Bullet text={`${formatUnit(event.debrisAmount, 'kg', { metricPrecision: 0, showMetricUnit: true })}`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "DivotingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "IrrigationEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'amount' })}: ${event.averageMillimeters ? (
                    formatUnit(event.averageMillimeters, 'mm', { metricPrecision: 0, showMetricUnit: true })
                ) : 'N/A'}`} />
                <Bullet text={`${formatMessage({ id: 'meterValue' })}: ${event.meterValue || formatMessage({ id: 'notApplicableAbbr' })}`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "AerationEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'type' })}: ${aerationTypes[event.aerationType]}`} />
                <Bullet text={`${formatMessage({ id: 'spacing' })}: ${aerationSpacings[event.aerationSpacing]}`} />
                <Bullet text={`${formatMessage({ id: 'heive' })}: ${aerationHeives[event.aerationHeive]}`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "TrainingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={trainingTeams[event.team]} />
                <Bullet text={trainingTypes[event.trainingType]} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "PlayerPerceptionEvent":
            return <div css={{ display: 'flex', flexDirection: 'column' }}>
                <div css={css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1em 0em;
                `}>
                    <div css={styles.happinessTeaserBlock}>
                        <h4><p>{`${formatMessage({ id: 'averageAbbr' })} ${formatMessage({ id: 'firmness' })}`}</p></h4>
                        {getSmile(event.averageFirmnessScore)}
                        <p><b>{event.averageFirmnessScore / 10}</b>/10</p>
                    </div>
                    <div css={styles.happinessTeaserBlock} >
                        <h4><p>{`${formatMessage({ id: 'averageAbbr' })} ${formatMessage({ id: 'stability' })}`}</p></h4>
                        {getSmile(event.averageStabilityScore)}
                        <p><b>{event.averageStabilityScore / 10}</b>/10</p>
                    </div>
                    <div css={styles.happinessTeaserBlock}>
                        <h4><p>{`${formatMessage({ id: 'averageAbbr' })} ${formatMessage({ id: 'ballRollDensity' })}`}</p></h4>
                        {getSmile(event.averageBallRollAndDensityScore)}
                        <p><b>{event.averageBallRollAndDensityScore / 10}</b>/10</p>
                    </div>
                </div>
                <div css={styles.happinessTotal}>
                    <p>{formatMessage({ id: 'total' })}: <b>{event.averageScore / 10}</b>/10</p>
                    {event.remarks &&
                        <ReactSVG src={commentIcon} className="comment" />
                    }
                </div>
            </div>
        case "MatchEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'team' })}: ${matchTeams[event.team]}`} />
            </div>
        case "HeatingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'type' })}: ${heatingTypes[event.heatingType]}`} />
                <Bullet text={`${formatMessage({ id: 'temperature' })}: ${formatHeatingEventTemperature(event.temperature)
                    } ${formatUnitName('°C', { useSecondaryUnit: false })}`} />
                <Bullet text={`${formatMessage({ id: 'meterValue' })}: ${event.currentMeterValue}`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "DiseaseEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'disease' })}: ${diseaseTypes[event.diseaseType]}`} />
                <Bullet text={`${formatMessage({ id: 'severeness' })}: ${event.severeness}`} />
            </div>
        case "BioTreatmentEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'type' })}: ${treatmentTypes[event.treatmentType]}`} />
                {event.treatmentMethod && <Bullet text={`${formatMessage({ id: 'method' })}: ${treatmentMethods[event.treatmentMethod]}`} />}
                {event.fungicideProduct && <Bullet text={`${formatMessage({ id: 'product' })}: ${event.fungicideProduct?.[`name`]}`} />}
                {event.nematodeProduct && <Bullet text={`${formatMessage({ id: 'product' })}: ${event.nematodeProduct?.[`name`]}`} />}
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "TopDressingEvent":
            return <div css={styles.flexWrapper} {...props}>
                {<Bullet text={`${formatMessage({ id: 'amount' })}: ${formatUnit(event.amount, 'kg', { metricPrecision: 0, showMetricUnit: true })}`} />}
                {event.topDressingMaterial && <Bullet text={`${formatMessage({ id: 'material' })}: ${topDressingMaterials[event.topDressingMaterial]}`} />}
                {<Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />}
            </div>
        case "LineMarkingEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'method' })}: ${lineMarkingMethods[event.lineMarkingMethod]}`} />
                <Bullet text={`${formatMessage({ id: 'amount' })}: ${formatUnit(event.amount, 'L', { metricPrecision: 0, showMetricUnit: true })}`} />
                <Bullet text={`${formatMessage({ id: 'timeTaken' })}: ${Math.round((event.timeTaken / 60) * 10) / 10}h`} />
            </div>
        case "CoverEvent":
            return <div css={styles.flexWrapper} {...props}>
                <Bullet text={`${formatMessage({ id: 'product' })}: ${coverProductOptions[event.product]}`} />
                <Bullet text={`${formatMessage({ id: 'type' })}: ${coverTypeOptions[event.coverType]}`} />
            </div>
        case "NoteEvent":
            return <div css={[styles.flexWrapper, css`grid-template-columns: 1fr;`]} {...props}>
                <Bullet text={
                    <div
                        dangerouslySetInnerHTML={{ __html: dp.sanitize(event.note) }}
                        css={css`max-height: 6em; overflow-Y: auto;`}
                    />}
                />
            </div>
    }
}

function findInNestedObjects(mainObject, targetKey) {
    for (const outerKey in mainObject) {
        const nestedObject = mainObject[outerKey];
        if (typeof nestedObject === 'object' && nestedObject !== null) {
            if (targetKey in nestedObject) {
                return {
                    value: nestedObject[targetKey],
                    key: outerKey
                };
            }
        }
    }
}

const styles = {
    flexWrapper: css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }
    `,
    happinessTeaserBlock: css`
        width: 33.33%;
        min-width: 7em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;

        p {
            font-size: 12px;
        }

        svg {
            height: 40px;
            width: 40px;
        }

        > h4 {
            height: 1.5em;
            text-align: center;
            text-transform: capitalize;
        }
    `,
    happinessTotal: css`
        display: flex;

        b {
            font-weight: bold;
            color: #7BFAC9;
        }

        .comment {
            display: inline-block;
        }

        svg {
            height: 14px;
            width: 14px;
            display: inline-block;
            margin-right: 4px;
            margin-left: 8px;
            fill: #7BFAC9;
        }
    `,
}
