import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { getEventTypeIcon } from '../../utils/eventUtils'
import { getEventTypeColor } from '../../utils/eventUtils'
import { useState } from 'react'
import { ReactSVG } from 'react-svg'
import useBridge from '../../hooks/useBridge'

export default function EventTypeSvg({
    eventType,
    style,
    width,
    ...props
}) {

    const [isInfoOpen, setInfoOpen] = useState(false)
    const { data: eventTypes } = useBridge('/api/current/frontend-bridge/event-types')

    const styles = {
        wrapper: css`
            position: relative;
        `,
        infoBox: css`
            position: absolute;
            bottom: -20px;
            transform: translateY(-35px)
        `,
        text: css`
            fill: white;
            transform: translateY(10px)
        `
    }

    const label = useMemo(() => {
        return eventTypes?.find(t => t.entityShortName === eventType).entityShortNameLocalised
    }, [eventTypes, eventType])

    return (
        <svg
            style={{
                color: getEventTypeColor(eventType),
                width: "42px",
                height: "42px",
                display: 'block',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 7,
                fill: '#2E3539',
                position: 'relative',
                overflow: 'overlay',
                ...style,
            }}
            onClick={() => setInfoOpen(!isInfoOpen)}
            onMouseEnter={() => setInfoOpen(true)}
            onMouseLeave={() => setInfoOpen(false)}
        >
            <g id="icon">
                <rect width={width} height={width} rx={2} stroke={'#3B4146'} strokeWidth="1" />
                {/* safari is dumb and stupid. Don't transform svg elements but use a group around it instead */}
                <g transform="translate(4 4)">
                    <ReactSVG
                        src={getEventTypeIcon(eventType)}
                        wrapper='svg'
                        width={width - 8}
                        height={width - 8}
                    />
                </g>
            </g>
            {isInfoOpen &&
                <text css={styles.text}>
                    {label}
                </text>
            }
        </svg>
    )
}