import React, { useContext, useMemo } from 'react'
import LocaleContext from '../../context/Locale'
import { FormattedMessage } from 'react-intl'
import BridgeContext from '../../context/FrontendBridge'
import FancyDropdown from './FancyDropdown'

const localeAbbreviations = {
    'en': <FormattedMessage id='english' />,
    'fr': <FormattedMessage id='french' />,
    'es': <FormattedMessage id='spanish' />,
    'nl': <FormattedMessage id='dutch' />,
}

export default function LanguageSelector({ ...props }) {
    const { locale, setLocale } = useContext(LocaleContext)
    const { clearCache } = useContext(BridgeContext)

    const options = useMemo(() => {
        return Object.keys(localeAbbreviations).map(abbr => ({
            value: abbr,
            label: localeAbbreviations[abbr],
        }))
    }, [localeAbbreviations])

    return <FancyDropdown
        value={options?.find(opt => opt.value === locale)}
        onChange={(val) => {
            clearCache()
            setLocale(val?.value)
        }}
        options={options}
        compact={true}
        subtle={true}
        isSearchable={false}
        {...props}
    />
}