import { css } from "@emotion/react"
import FancyDropdown from "./FancyDropdown"
import { colors } from "../../style/vars"

export default function HourPicker({ disabled, hour, handleSetHour, minute, handleSetMinute, ...props }) {
    return <div css={css`
        display: flex;
        align-items: center;
        width: min-content;
        min-width: 8em;
        border-radius: 5px;
        border: 2px solid #394047;
        background-color: #2F353B;
        margin-bottom: 1em;
    `} {...props}>
        <FancyDropdown
            isSearchable={false}
            options={hoursOptions}
            css={dropdownStyle}
            value={hoursOptions.find(x => x.value === hour)}
            onChange={(option) => handleSetHour(option.value)}
            hideArrow={true}
            disabled={disabled}
            textCenter={true}
        />
        <span css={css`color: ${colors.main1};`}>
            :
        </span>
        <FancyDropdown
            isSearchable={false}
            options={minutesOptions}
            css={dropdownStyle}
            value={minutesOptions.find(x => x.value === minute)}
            onChange={(option) => handleSetMinute(option.value)}
            hideArrow={true}
            disabled={disabled}
            textCenter={true}
        />
    </div>
}

const dropdownStyle = css`
    > div {
        border: none;
    }

    .react-select__menu {
        min-width: 6em;
    }
`

const hoursOptions = [
    { value: 0, label: '00' },
    { value: 1, label: '01' },
    { value: 2, label: '02' },
    { value: 3, label: '03' },
    { value: 4, label: '04' },
    { value: 5, label: '05' },
    { value: 6, label: '06' },
    { value: 7, label: '07' },
    { value: 8, label: '08' },
    { value: 9, label: '09' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
    { value: 22, label: '22' },
    { value: 23, label: '23' },
]

const minutesOptions = [
    { value: 0, label: '00' },
    { value: 5, label: '05' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
    { value: 35, label: '35' },
    { value: 40, label: '40' },
    { value: 45, label: '45' },
    { value: 50, label: '50' },
    { value: 55, label: '55' },
]