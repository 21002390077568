import React from 'react'
import { css } from '@emotion/react'

import { colors } from '../../style/vars'

const style = {
    cont: css`
        background-color: ${colors.solid};
        border-radius: 0.125em;
        padding: 1.75em 1.25em;
        color: ${colors.white};
        box-shadow: 0 0.25em 0.75em 0 rgba(0,0,0,0.3);
        h4 {
            font-size: 1.25em;
            margin-bottom: 1.5em;
        }
    `,
}

export default function AdviceBlock({
    title,
    children,
    ...props
}) {
    return (
        <div css={style.cont} {...props}>
            <h4>{title}</h4>
            {children}
        </div>
    )
}