import React from 'react'
import InjuryForm from './InjuryForm'
import ActivityForm from './ActivityForm'
import IrrigationForm from './IrrigationForm'
import MowingForm from './MowingForm'
import SeedingForm from './SeedingForm'
import DivotingForm from './DivotingForm'
import AerationForm from './AerationForm'
import DiseaseForm from './DiseaseForm'
import PlayerPerceptionForm from './PlayerPerceptionForm'
import HeatingForm from './HeatingForm'
import FertiliserForm from './FertiliserForm'
import GroomingForm from './GroomingForm'
import TrainingForm from './TrainingForm'
import MatchForm from './MatchForm'
import BioTreatmentForm from './BioTreatmentForm'
import TopDressingForm from './TopDressingForm'
import LineMarkingForm from './LineMarkingForm'
import NoteForm from './NoteForm'
import CoverForm from './CoverForm'

export default function EventWrapper({
    eventType,
    event = null,
    onPostRequest,
}) {
    const type = eventType.entityShortName ? eventType.entityShortName : eventType
    switch (type) {
        case "InjuryEvent": return <InjuryForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "AerationEvent": return <AerationForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "ActivityEvent": return <ActivityForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "MowingEvent": return <MowingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "SeedingEvent": return <SeedingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "DivotingEvent": return <DivotingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "IrrigationEvent": return <IrrigationForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "PlayerPerceptionEvent": return <PlayerPerceptionForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "HeatingEvent": return <HeatingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "FertilisingEvent": return <FertiliserForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "GroomingEvent": return <GroomingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "TrainingEvent": return <TrainingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "MatchEvent": return <MatchForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "DiseaseEvent": return <DiseaseForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "BioTreatmentEvent": return <BioTreatmentForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "TopDressingEvent": return <TopDressingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "LineMarkingEvent": return <LineMarkingForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "NoteEvent": return <NoteForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        case "CoverEvent": return <CoverForm eventType={eventType} event={event} onPostRequest={onPostRequest} />
        default: return <p>No form</p>
    }
}
