import { useContext, useMemo } from "react"
import LocaleContext from "../../context/Locale"
import { useIntl } from "react-intl"
import FancyDropdown from "../_control/FancyDropdown"

export default function WeekStartSelector({ ...props }) {
    const { weekStart, setWeekStart } = useContext(LocaleContext)
    const { formatMessage } = useIntl()

    const options = useMemo(() => {
        return [
            {
                value: 1,
                label: formatMessage({ id: 'monday' }),
            },
            {
                value: 0,
                label: formatMessage({ id: 'sunday' }),
            }
        ]
    }, [])

    return <FancyDropdown
        value={options?.find(opt => opt.value === weekStart)}
        onChange={(val) => {
            const weekStartNumber = Number(val?.value)
            if (weekStartNumber < 0 || weekStartNumber > 6) return setWeekStart(1)
            setWeekStart(weekStartNumber)
        }}
        options={options}
        compact={true}
        subtle={true}
        isSearchable={false}
    />
}