import { css } from "@emotion/react"
import { backgrounds, colors, fonts, timings } from "../../style/vars"
import covermasterImage from '@assets/images/integrations/covermaster.bmp'
import { lighten } from "polished"
import { NavLink } from "react-router"
import PageTitle from "../_general/PageTitle"
import { useContext, useMemo } from "react"
import SwitcherSimple from "../_control/SwitcherSimple"
import IntegrationsContext from "../../context/Integrations"

export default function Integrations({ ...props }) {

    return <div css={css`width: 100%; display: grid; grid-template-rows: min-content;`} {...props}>
        <PageTitle >
            <span css={{ color: colors.main1 }}>
                Integrations {/* To do: translate this */}
            </span>
        </PageTitle>

        <div css={style.pageWrapper}>

            <div css={style.title}>
                Activate and manage integrations {/* To do: translate this */}
            </div>

            <IntegrationCard
                to={'/integrations/covermaster'}
                imgSrc={covermasterImage}
                integrationFlagName={'cover-master'}
            />

        </div>
    </div>
}

function IntegrationCard({ to, imgSrc, integrationFlagName, ...props }) {

    const { integrations, toggle, isBusy } = useContext(IntegrationsContext)

    const integrationActive = useMemo(() => {
        return integrations.includes(integrationFlagName)
    }, [integrations, integrationFlagName])

    return <div css={style.cardColumn}>

        <div css={css`
            display: flex; 
            flex-direction: column;
            align-items: center;
        `}>

            <NavLink
                to={integrationActive ? to : undefined}
                css={style.card(integrationActive)}
            >
                <img src={imgSrc} alt="" />
            </NavLink>

            <div css={css`
                width: 100%;
                display: grid;
                place-items: center;
                padding: 0.5em;
            `}>
                {!isBusy ?
                    <SwitcherSimple
                        checked={integrationActive}
                        onClick={() => { toggle(integrationFlagName) }}
                        checkedLabel={'on'} /* to do: translate */
                        unCheckedLabel={'off'} /* to do: translate */
                        buttonCenter={true}
                        css={css`width: 100%;`}
                    />
                    :
                    <SwitcherSimple
                        checked={integrationActive}
                        onClick={() => {}}
                        checkedLabel={'on'} /* to do: translate */
                        unCheckedLabel={'off'} /* to do: translate */
                        buttonCenter={true}
                        css={css`width: 100%; opacity: 0.5; cursor: default; pointer-events: none;`}
                    />
                }
            </div>
        </div>
    </div>
}


const style = {
    pageWrapper: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2em;
        width: min(60em, 100%);
        justify-self: center;
    `,
    title: css`
        font-family: ${fonts.special};
        font-size: 1.5em;
        align-self: center;
        margin-top: 3em;
        color: ${colors.soft};
    `,
    cardColumn: css`
        display: grid;
        margin-top: 2em;
        gap: 2em;
        max-width: 95%;
        width: 20em;
    `,
    card: (isActivated) => css`
        height: 12em;
        width: 100%;
        border-radius: 5px;
        background: ${colors.eventLight};
        background-image: ${backgrounds.cardGradient};
        display: grid;
        opacity: ${isActivated ? 1 : 0.3};
        transition: transform ${timings.responsive}, opacity ${timings.responsive};
        cursor: unset;
        
        img {
            width: 80%;
            height: 45%;
            object-fit: contain;
            background: ${colors.dark};
            overflow: hidden;
            border-radius: 5px;
            place-self: center;
        }

        ${isActivated ? `
        
        cursor: pointer;
        
        &: hover {
            background: ${lighten(0.05, colors.eventLight)};
            background-image: ${backgrounds.cardGradient};
            transform: translate(0, -4px);
        }
        ` : ''}
    `

}