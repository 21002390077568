import { css } from "@emotion/react"
import PageSubtitle from "../../_general/PageSubtitle"
import { useCallback, useContext, useMemo, useState } from "react"
import PitchTelemetry from "../../../context/covermaster/PitchTelemetry"
import ZoneResults from "../../test/ZoneResults"
import Orientation from "../../test/Orientation"
import SwitcherSimple from "../../_control/SwitcherSimple"
import GridModeSwitcher from "../../test/GridModeSwitcher"
import LiveMetricsList from "./LiveMetricsList"
import EventZones from "../../events/EventZones"
import { toZonedTime } from "date-fns-tz"
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { backgrounds, colors } from "../../../style/vars"
import { intervalToDuration, formatDuration, format, getUnixTime } from "date-fns"
import LocaleContext from "../../../context/Locale"
import { ReactSVG } from "react-svg"
import calendarIcon from '@assets/icons/nav/calendar.svg'
import Cta from "../../_control/Cta"
import Modal from "../../_layout/Modal"
import useLdjsonApi from "../../../hooks/useLdjsonApi"
import Loader from "../../_general/Loader"
import EventTypeIcon from "../../events/EventTypeIcon"
import { useNavigate } from "react-router"
import useBridge from "../../../hooks/useBridge"

export default function LiveTelemetrySection({ className, ...props }) {
    const navigate = useNavigate()
    const { put } = useLdjsonApi()

    const { datefnsLocale } = useContext(LocaleContext)
    const {
        titleMessage,
        pitch,
        pitchBg,
        liveZoneResults,
        handleSelectPosition,
        isCoverOn,
        isUnderCover,
        setIsUnderCover,
        selectedMetricTypeUnit,
        currentCoverEvent,
        timeZone,
        refetchEvents,
    } = useContext(PitchTelemetry)

    const [showRemovalModal, setShowRemovalModal] = useState(false)

    const { timeOnPitch, progressPercentage, timeRemaining, coverEndFormatted, currentFormatted } = useMemo(() => {
        if (!currentCoverEvent) return {}
        const coverStart = toZonedTime(currentCoverEvent.timestamp * 1000, timeZone)
        const coverCurrent = toZonedTime(new Date(), timeZone)
        const coverEnd = toZonedTime(currentCoverEvent.timestampEnd * 1000, timeZone)
        const progressPercentage = (coverCurrent - coverStart) / (coverEnd - coverStart) * 100

        const timeOnPitch = formatDuration(
            intervalToDuration({ start: coverStart, end: coverCurrent }),
            {
                format: ['days', 'hours'],
                locale: datefnsLocale,
                delimiter: ', '
            },
        ) || formatDuration(  // fall back to minutes in case of empty string output
            intervalToDuration({ start: coverStart, end: coverCurrent }),
            {
                format: ['minutes'],
                locale: datefnsLocale,
            },
        )

        const timeRemaining = formatDuration(
            intervalToDuration({ start: coverCurrent, end: coverEnd }),
            {
                format: ['days', 'hours'],
                locale: datefnsLocale,
                delimiter: ', '
            },
        ) || formatDuration(
            intervalToDuration({ start: coverCurrent, end: coverEnd }),
            {
                format: ['minutes'],
                locale: datefnsLocale,
            },
        )
        const currentFormatted = format(coverCurrent, 'PP -  HH:mm', { locale: datefnsLocale })
        const coverEndFormatted = format(coverEnd, 'PP - HH:mm', { locale: datefnsLocale })
        return {
            timeOnPitch,
            progressPercentage,
            timeRemaining,
            coverEndFormatted,
            currentFormatted
        }
    }, [currentCoverEvent, timeZone, datefnsLocale, showRemovalModal])

    const [modalLoading, setModalLoading] = useState(false)

    const handleRemoveCover = useCallback(async () => {

        setModalLoading(true)

        try {
            const res = await put(
                `/api/current/cover-events/${currentCoverEvent.id}`,
                {
                    body: {
                        timestampEnd: getUnixTime(new Date()) - 1 // avoid timestamp collisions
                    }
                }
            )
            refetchEvents()
        } catch (e) {
            console.log(e)
        } finally {
            setShowRemovalModal(false)
            setModalLoading(false)
        }
    }, [timeZone, showRemovalModal, put, currentCoverEvent, refetchEvents])

    const { data: coverProductOptions, loading: loadingCoverProducts } = useBridge(
        currentCoverEvent && `/api/current/frontend-bridge/cover-event-products`)

    const { data: coverTypeOptions, loading: loadingCoverTypes } = useBridge(
        currentCoverEvent && `/api/current/frontend-bridge/cover-event-types`)

    const loadingCoverStrings = loadingCoverProducts || loadingCoverTypes

    return <div css={className}>

        <PageSubtitle css={css`grid-column: span 2; margin: 0; font-size: 1.5em;`}>
            {titleMessage}{/* TO DO: translate */}
        </PageSubtitle>

        <div>
            <ZoneResults
                gridId={pitch['@id']}
                backgroundType={pitchBg}
                zoneResults={liveZoneResults}
                decimals={2}
                unitSI={selectedMetricTypeUnit}
                getZoneBackendColor={(zone) => zone.assessment?.frontendCode}
                enableClicks={true}
                onClickZone={(zone) => handleSelectPosition(zone)}
                underLay={currentCoverEvent ?
                    <EventZones
                        event={currentCoverEvent}
                        hideBackground={true}
                        showGrid={false}
                        css={css` opacity: 0.4;`}
                    />
                    :
                    <></>
                }
                css={[css`height: min-content;`]}
            />

            <div css={style.belowGrid}>
                <Orientation
                    heading={pitch?.orientation}
                    css={style.compass}
                />
                <SwitcherSimple
                    checked={isUnderCover}
                    onClick={() => {
                        handleSelectPosition()
                        setIsUnderCover(curr => !curr)
                    }}
                    checkedLabel={'under'} /* TO DO: translate */
                    unCheckedLabel={'over'} /* TO DO: translate */
                    buttonCenter={true}
                    css={style.toggle}
                />
                <GridModeSwitcher />
            </div>

            {isCoverOn &&
                <div css={css`
                    margin-top: 0.5em; 
                    background: ${backgrounds.weatherGradient};
                    box-shadow: 0px 0px 1px rgba(0,0,0,0.4);
                `}>
                    <div css={style.coverEventSection}>
                        <div css={style.eventInfo}>
                            <div css={css`display: flex; align-items: center; gap: 0.5em;`}>
                                <EventTypeIcon eventType={'CoverEvent'} css={css`
                                    > * {
                                        background: rgba(0,0,0,0.30);
                                    }
                                `} />
                                <span css={css`opacity: 0.5;`}>
                                    Cover {/* To DO: translate */}
                                </span>
                            </div>
                            {!loadingCoverStrings && <span css={css`opacity: 0.5;`}>
                                {`${coverTypeOptions?.[currentCoverEvent.coverType]}, 
                                ${coverProductOptions?.[currentCoverEvent.product]}`}
                            </span>}
                        </div>
                        <hr css={css` padding: 0; margin: 0 1em; opacity: 0.3; `} />
                        <div css={[style.belowGrid, css`background: unset;`]}>
                            <div css={style.gaugeWrapper}>
                                <div className="elapsed">
                                    <CircularProgressbarWithChildren
                                        strokeWidth={10}
                                        css={style.gaugeCircle}
                                        styles={style.gauge()}
                                        value={progressPercentage}
                                    >
                                        <div css={style.gaugeLabel}>
                                            {timeOnPitch}
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className="remaining" css={css`  display: none; `}>
                                    <CircularProgressbarWithChildren
                                        strokeWidth={10}
                                        css={style.gaugeCircle}
                                        styles={style.gauge(true, (progressPercentage) / 100)}
                                        value={100 - progressPercentage}
                                    >
                                        <div css={style.gaugeLabel}>
                                            {timeRemaining}
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>
                            </div>

                            <Cta onClick={() => navigate(`/events?pitch=${pitch.id}`)} title={'manage'}> {/* TO DO: translate */}
                                <ReactSVG src={calendarIcon} css={css`
                                    width: 1.5em; height: 1.5em;
                                    * {
                                        color: ${colors.white};
                                    }
                                `} />
                            </Cta>

                            <Cta onClick={() => setShowRemovalModal(true)} title={'remove'}> {/* TO DO: translate */}
                                remove now{/* TO DO: translate */}
                            </Cta>

                            {showRemovalModal &&
                                <Modal onClickClose={() => setShowRemovalModal(false)}
                                    header={'End cover event early?'} /* TO DO: translate */
                                    css={css`width: max-content;`}
                                >
                                    {modalLoading ? <Loader size='2em' /> :
                                        <>
                                            <div css={css`
                                                display: grid;
                                                grid-template-columns: auto 1fr;
                                                gap: 0 1em;
                                            `}>
                                                <div css={css`padding-bottom: 1em; `}>
                                                    Total cover duration: {/* TO DO: translate */}
                                                </div>
                                                <span css={css`color: ${colors.main1};`}>
                                                    {timeOnPitch}
                                                </span>

                                                <div css={css`padding-bottom: 1em;`}>
                                                    Planned removal time: {/* TO DO: translate */}
                                                </div>
                                                <span css={css`color: ${colors.main1};`}>
                                                    {coverEndFormatted}
                                                </span>

                                                <div css={css`padding-bottom: 1em; `}>
                                                    Current time: {/* TO DO: translate */}
                                                </div>
                                                <span css={css`color: ${colors.main1};`}>
                                                    {currentFormatted}
                                                </span>
                                            </div>

                                            <div css={css`display: flex; gap: 1em;`}>
                                                <Cta onClick={handleRemoveCover} css={css`border-color: ${colors.warn};`}>
                                                    remove cover now {/* TO DO: translate */}
                                                </Cta>
                                                <Cta onClick={() => setShowRemovalModal(false)}
                                                    css={css` border-color: ${colors.soft}; `}
                                                >
                                                    cancel {/* TO DO: translate */}
                                                </Cta>
                                            </div>
                                        </>
                                    }
                                </Modal>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
        <LiveMetricsList />
    </div>
}

const style = {
    belowGrid: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(0,0,0,0.1); 
        padding: 0.3em 1em;  
        margin-top: 0.5em;
        gap: 1em;
    `,
    compass: css`
        background: transparent; 
        width: min-content;
        height: min-content; 
    `,
    toggle: css`
        border-radius: 5px;
        width: max-content;
        white-space: nowrap;
        place-self: center;
        height: 100%;
        padding-left: 1em;
        padding-right: 1em;
        height: min-content;
    `,
    coverTitle: css`
        display: grid;
        place-items: center;
        margin-bottom: -0.5em;
        background: rgba(0,0,0,0.1);
    `,
    gaugeWrapper: css`
        &:hover {
            .elapsed {
                display: none;
            }
            .remaining {
                display: grid;
            }
        }
        cursor: default;
    `,
    gauge: (isRemaining, turnFraction) => ({
        path: {
            stroke: isRemaining ? colors.soft : colors.main1,
            strokeLinecap: isRemaining ? '' : 'round',
            transition: 'stroke-dashoffset 0.5s ease 0s',
            transform: isRemaining ? `rotate(${turnFraction}turn)` : 'unset',
            transformOrigin: isRemaining ? 'center center' : 'unset',
        },
        trail: {
            stroke: 'rgba(255,255,255,0.25)',
        },
        text: {
            fill: colors.white,
            fontSize: '1.5em',
        },
    }),
    gaugeCircle: css`
        width: 5em;
        height: 5em;
    `,
    gaugeLabel: css`
        width: 70%;
        height: 70%;
        display: grid;
        place-items: center;
        transform: translate(0, -0.3em);
        text-align: center;
        text-shadow: -1px -1px 1px black;
    `,
    eventInfo: css`
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0 1em;      
        gap: 1em;   
        justify-content: space-between;       
    `,
    coverEventSection: css`
        padding-top: 0.5em;
        display: grid; 
        background: rgba(0,0,0,0.1);
        margin-top: 0.5em;
        gap: 0.5em;
    `,
}