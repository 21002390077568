import React from 'react'

import { css } from '@emotion/react'

export default function EZGrid({
    w = '16rem',
    gap = '1rem',
    ...props
}) {
    return (
        <div
            css={css`
                display: grid;
                grid-gap: ${gap};
                grid-template-columns: repeat(auto-fill, minmax(${w}, 1fr));
            `}
            {...props}
        />
    )
}