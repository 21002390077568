
import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import { css } from '@emotion/react'
import FieldSectionSelector from '../_control/FieldSectionSelector'
import { getUnixTime } from 'date-fns'
import Orientation from '../test/Orientation'
import Pitch from '../../context/Pitch'
import FormLoader from './FormLoader'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { zonesToSectionState } from '../../utils/eventUtils'
import Selector from '../_control/OptionsSelector'
import useBridge from '../../hooks/useBridge'
import EventDateTimeEndPicker from '../_control/EventDateTimeEndPicker'
import useRawApiState from '../../hooks/useRawApiState'
import PitchSensorsModeToggler from '../integrations/covermaster/PitchSensorsModeToggler'
import { colors, fonts } from '../../style/vars'
import { groupBy } from 'lodash'
import SelectedCalendarDate from '../events/SelectedCalendarDate'

const columns = 7
const rows = 5

export default function CoverForm({
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [startDate, setStartDate] = useState()  // start date
    const [endDate, setEndDate] = useState()  // end date

    const [sections, setSections] = useState([])  // cover placement sections
    const [pitch] = useContext(Pitch)

    const { data: coverProductOptions, loading: waitingCoverProducts } = useBridge(`/api/current/frontend-bridge/cover-event-products`)
    const { data: coverTypeOptions, loading: waitingCoverTypes } = useBridge(`/api/current/frontend-bridge/cover-event-types`)
    const [coverType, setCoverType] = useState()
    const [coverProduct, setCoverProduct] = useState()

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/cover-events/${eventId}`)

                // set initial state
                setStartDate(new Date(data.timestamp * 1000))
                setEndDate(new Date(data.timestampEnd * 1000))
                setSections(zonesToSectionState(data.zoneResults))
                setCoverType(data.coverType)
                setCoverProduct(data.product)

                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // the coordinates of all the sensors which placement modes (over/under) are about to be flipped
    const [toggledCoordinates, setToggledCoordinates] = useState([])

    const handleToggleSensorMode = useCallback((x, y) => {
        const foundToggle = toggledCoordinates.find(coor => (coor.x === x) && (coor.y === y))
        if (foundToggle) {
            setToggledCoordinates(prev => prev.filter(crd => !((crd.x === x) && (crd.y === y))))
        } else {
            setToggledCoordinates(prev => [...prev, { x, y }])
        }
    }, [toggledCoordinates])

    const { data: pitchDevicePlacements, isBusy: placementsBusy } = useRawApiState(`/api/current/pitches/${pitch.id}/device-placement-events`)

    /* TO DO: test this mofo */
    const latestDevicePlacementsAtEventStart = useMemo(() => {
        // whatever was the 'last placement event' at that 'from date'
        // max 1 placement per coordinate
        const groupedPlacements = groupBy(pitchDevicePlacements ?? [], (placement) => `${placement.posX}-${placement.posY}`)
        // group placements on coordinate, for each coordinate group: sort by timestamp,
        // filter out all the timestamps greater than the selected start time ,take the highest timestamp
        for (const [positionString, placements] of Object.entries(groupedPlacements)) {
            const filteredPlacements = placements
                .filter(p => p.timestamp * 1000 <= startDate)
                .toSorted((a, b) => a.timestamp - b.timestamp)
            const lastPlacement = filteredPlacements.pop()
            groupedPlacements[positionString] = lastPlacement
        }

        const placementArray = Object.values(groupedPlacements).filter(v => v !== undefined)
        // the same device can have been the last at multiple coordinates...  but can obviously be only in one place at the time.
        // so compare all coordinates, and of each matched device, keep the latest one

        const deviceMap = new Map()
        // Iterate through the array and keep only the latest placement per device
        for (const placement of placementArray) {
            const deviceIRI = placement.device
            if (!deviceMap.has(deviceIRI) || deviceMap.get(deviceIRI).timestamp < placement.timestamp) {
                deviceMap.set(deviceIRI, placement)
            }
        }
        // Convert map values back to an array
        const filteredPlacementArray = Array.from(deviceMap.values())
        return filteredPlacementArray
    }, [pitchDevicePlacements, startDate])

    const virtuallyAlteredPlacements = useMemo(() => {
        const pitchDev = latestDevicePlacementsAtEventStart ?? []
        const alteredPreview = pitchDev
            .filter(pl => pl.deviceMode !== 'unassigned')
            .map(pl => {
                const { posX, posY } = pl
                const placementCopy = {
                    deviceMode: pl.deviceMode,
                    posX: pl.posX,
                    posY: pl.posY,
                    timestamp: getUnixTime(startDate),                      
                    device: pl.device,
                    pitch: pl.pitch,
                }

                if (toggledCoordinates.find(tgc => (tgc.x === posX) && (tgc.y === posY))) {
                    const currentMode = placementCopy.deviceMode
                    placementCopy.deviceMode = (currentMode === 'over-cover' ? 'under-cover' : 'over-cover')
                    placementCopy.timestamp = getUnixTime(startDate)
                    return placementCopy
                } else {
                    return placementCopy
                }
            })

        return alteredPreview
    }, [latestDevicePlacementsAtEventStart, pitch, toggledCoordinates, startDate])

    const isBusy = useMemo(() => {
        return isLoading || waitingCoverProducts || placementsBusy || waitingCoverTypes
    }, [isLoading, waitingCoverProducts, placementsBusy, waitingCoverTypes])


    const validateAndSubmit = useCallback(async () => {
        setFormError('')
        // validate your state
        if (!startDate || !endDate) return setFormError('Please enter valid times')  /*TO DO: TRANSLATE setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' })) */
        if (startDate >= endDate) return setFormError('End time must be later than start time') /* TO DO: translate */
        if (!coverType || !coverProduct) return setFormError('Please select a product and type')
        if (!Array.isArray(sections)) return setFormError('Could not process grid sections')

        setIsLoading(true)

        const payLoad = {
            coverType: coverType,
            product: coverProduct,
            multiDay: true,
            timestamp: getUnixTime(startDate),
            timestampEnd: getUnixTime(endDate),
            devicePlacementEvents: virtuallyAlteredPlacements,
        }

        if (event) {
            const { data: putResponse, error } = await put(`${event['@id']}`, { body: payLoad })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            } else if (putResponse) {
                const { data: coverData } = await get(`/api/current/cover-events/${putResponse.id}`);

                coverData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections[zone.posY - 1][zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, coverData)
            }
        } else {
            const newPayLoad = {
                ...payLoad,
                name: `Cover event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data: postResponse, error } = await post(`/api/current/cover-events`, { body: newPayLoad })

            if (error) {
                if (error.status === 422) {
                    setFormError(error.json["hydra:description"])
                    setIsLoading(false)
                    return
                } else {
                    setIsLoading(false)
                    onPostRequest(false, error)
                }
            }
            else if (postResponse) {
                const { data: coverData } = await get(`/api/current/cover-events/${postResponse.id}`);
                coverData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections[zone.posY - 1][zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, coverData)
            }
        }
    }, [pitch, sections, latestDevicePlacementsAtEventStart, virtuallyAlteredPlacements, startDate, endDate, coverType, coverProduct, post, put, get ])

    return (
        <div style={{ minHeight: '300px' }}>
            {isBusy ? <FormLoader /> : <>

                <div css={style.datesWrapper}>
                    <div css={css`
                        opacity: ${ event ? 0.6 : 1};
                        pointer-events: ${event ? 'none' : 'unset' };
                    `}>
                        <EventDateTimePicker
                            unixDate={startDate}
                            setUnixDate={setStartDate}
                            disabled={!!event}
                        />
                    </div>
                    <div css={style.currentDate}>
                        <SelectedCalendarDate
                            date={startDate}
                            pitch={pitch}
                        />
                    </div>
                </div>

                <EventDateTimeEndPicker
                    unixDate={endDate}
                    setUnixDate={setEndDate}
                    css={css` padding-bottom: 1em; `}
                />

                <Selector
                    label={formatMessage({ id: 'product' })}
                    optionsObject={coverProductOptions}
                    state={coverProduct}
                    setState={setCoverProduct}
                />

                <Selector
                    label={formatMessage({ id: 'type' })}
                    optionsObject={coverTypeOptions}
                    state={coverType}
                    setState={setCoverType}
                />

                <div css={style.formGroup}>
                    <h3>
                        <FormattedMessage id='areasCoveredDivoting' />    {/* TO DO: TRANSLATE */}
                    </h3>
                    <FieldSectionSelector
                        backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                        rows={rows}
                        columns={columns}
                        sections={sections}
                        onChange={setSections}
                    />
                    <Orientation
                        css={style.compass}
                        heading={pitch.orientation}
                    />
                </div>

                {virtuallyAlteredPlacements.length > 0 && <>
                    <div css={css`margin-bottom: 0.5em;`}>
                        {event ? 'Sensor modes' : 'Select sensor modes'}  {/* TO DO: TRANSLATE */}
                    </div>
                    <div css={[style.sensorTogglerWrapper , css`
                        opacity: ${ event ? 0.6 : 1};
                        pointer-events: ${event ? 'none' : 'unset'};
                    `]}>
                        <PitchSensorsModeToggler
                            handleClick={handleToggleSensorMode}
                            disabled={!pitch || !!event}
                            devicePlacements={virtuallyAlteredPlacements}
                            pitchBg={pitch?.pitchBackground || pitch?.club?.sport}
                        />
                    </div>
                </>}

            </>}
            {formError && <div css={css`margin-top: 1em; color: ${colors.warn};`}>{formError}</div>}
            <button
                onClick={() => validateAndSubmit()}
                type='button'
                className="btn"
                disabled={isBusy}
            >
                <FormattedMessage id='save' />
            </button>
        </div >
    )
}

const style = {
    formGroup: css`
        margin-bottom: 1em;
        width: min-content;

        h3 {
            margin-bottom: .5em;
        }
    `,
    compass: css`
        position: relative;
        height: 2em;
        width: 2em;
        bottom: -1em;
        left: calc(50% - 1em);
        background-color: rgba(44,52,58);
        box-shadow: none;
        margin-bottom:40px;
    `,
    datesWrapper: css`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1em;
        height: min-content;
    `,
    currentDate: css`
        transform: translate(0, 0.2em);
        font-size: 1em;
        font-family: ${fonts.main};
        opacity: 0.8;
    `,
    sensorTogglerWrapper: css` 
        background: ${colors.eventLight}; 
        width: 450px;
        height: 300px;

        @media screen and (max-width: 600px) {
            width: 225px;
            height: 150px;
        }
    `
}
