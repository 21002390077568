import React, { useContext, useState, useCallback } from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { NavLink } from 'react-router'
import { colors, fonts } from '../../style/vars'
import useRaw from '../../hooks/useRaw'
import Pitch from '../../context/Pitch'
import Analysis from '../../context/Analysis'
import Test from '../../context/Test'
import PageTitle from '../_general/PageTitle'
import LoaderText from '../_general/LoaderText'
import TestCard from '../test/TestCard'
import TestsAdvice from '../test/TestsAdvice'
import Orientation from '../test/Orientation'
import TestZoneResultsEditor from '../modals/TestZoneResultsEditor'
import Parameter from '../test/Parameter'
import FallbackMessage from '../_general/FallbackMessage'
import CtaLink from '../_control/CtaLink'
import TestAdminModal from '../modals/TestAdminModal'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import { useNavigate } from 'react-router'
import { ReactSVG } from 'react-svg'
import editIcon from '@assets/icons/edit.svg'
import deleteIcon from '@assets/icons/delete.svg'
import useTracker from '../../hooks/useTracker'
import { MTM_EVENT_TYPES } from '../../utils/matomo'
import DeleteWarnModal from '../_control/DeleteWarnModal'
import GridModeContext from '../../context/GridMode'
import GridModeSwitcher from '../test/GridModeSwitcher'
import TestHistory from '../test/TestHistory'
import ClientPagination from '../_control/ClientPagination'
import { useIntl, FormattedMessage } from 'react-intl'
import AnalysisDate from '../analysis/AnalysisDate'

const breadcrumbStyle = css`
    text-decoration: none;
    color: inherit;
`

export default function TestDetails({
    ...props
}) {
    const { del } = useLdjsonApi()
    let navigate = useNavigate()

    const { formatMessage } = useIntl()

    const track = useTracker()

    const [adminModalOpened, setAdminModalOpened] = useState(false)
    const [deleteModalOpened, setDeleteModalOpened] = useState(false)

    // data
    const [pitch] = useContext(Pitch)
    const [analysis] = useContext(Analysis)
    const [test, { waiting: testWaiting, fetch: fetchTest }] = useContext(Test);

    // test history
    const testCategoryId = test && test.testCategory ? test.testCategory.id : null
    const analysisDate = analysis ? analysis.date : null
    const pitchLd = pitch ? pitch['@id'] : undefined
    const [
        { 'hydra:member': testHistory = [] } = {},
        {
            waiting: testHistoryWaiting,
        },
    ] = useRaw(`${pitchLd}/history/${testCategoryId}?to=${analysisDate}`, [pitchLd, testCategoryId, analysisDate])

    const [historySubset, setHistorySubset] = useState([])

    const [deletionError, setDeletionError] = useState('')

    const handleDeleteTest = useCallback(async () => {
        if (test) {
            try {
                const res = await del(`/api/current/tests/${test.id}`)
                if (res.error || res['hydra:Error']) {
                    setDeletionError(
                        formatMessage({ id: 'somethingWentWrongTryAgain' })
                    )
                    throw new Error('Something went wrong')
                } else {
                    track({ 'event': MTM_EVENT_TYPES['delete-test'] })
                }
                setDeleteModalOpened(false)
                navigate(`/pitch/${pitch.id}/analysis/${analysis.id}`)
            } catch (e) {
                console.error(e)
            }
        }
    }, [del, test, history, pitch, analysis, navigate])

    const { put } = useLdjsonApi();
    const handleParamUpdate = useCallback(async (parameter) => {
        const resource = parameter?.['@id'];
        if (!resource) return;
        const response = await put(resource, {
            body: parameter,
            detailedError: true,
        });
        if (response?.data?.violations) throw (response.data.violations?.[0]?.message)
        track({ 'event': MTM_EVENT_TYPES['input-measurement'] })
        fetchTest();
    }, [put, fetchTest]);

    const { selectedMode } = useContext(GridModeContext)

    return (
        <div css={css`display: grid;`} {...props}>
            <div css={css`padding: 1em 2em 5em 2em; flex-grow: 1;`}>
                <PageTitle>
                    {pitch ?
                        <NavLink to={`/club/${pitch.club?.id}`} css={breadcrumbStyle}>
                            {pitch.club?.name || formatMessage({ id: 'noClub' })}
                        </NavLink>
                        :
                        <LoaderText subtle />
                    }
                    {' / '}
                    {pitch ?
                        <NavLink css={css`text-decoration: none; color: inherit;`} to={`/pitch/${pitch.id}`}>
                            {pitch.name}
                        </NavLink>
                        :
                        <LoaderText subtle />
                    }
                    {' / '}
                    {(pitch && analysis)
                        ? (
                            <NavLink to={`/pitch/${pitch.id}/analysis/${analysis.id}`} css={breadcrumbStyle}>
                                <AnalysisDate
                                    date={analysis.date}
                                    timeZone={pitch?.venue?.timeZone}
                                    css={css`
                                        font-family: ${fonts.main};
                                        font-weight: unset;
                                        font-size: 1em;
                                    `}
                                />
                            </NavLink>
                        )
                        : <LoaderText subtle />
                    }
                    <span css={css`color: ${colors.main1};`}>
                        {' / '}
                        {testWaiting
                            ? <LoaderText subtle />
                            : test.testCategory && test.testCategory.name
                        }
                    </span>
                </PageTitle>

                <div css={css`
                    display: grid;
                    grid-gap: 1em;
                    width: 100%;
                    max-width: 70em;

                    grid-template: auto / auto 1fr;
                    grid-template-areas: 
                        "pitchCard zones"
                        "advice advice";

                    @media screen and (max-width: 600px) {
                        grid-template: auto auto auto / 1fr;
                        grid-template-areas:
                            "zones"
                            "pitchCard"
                            "advice";
                    }
                `}>
                    <div css={css`
                        grid-area: pitchCard; 
                        display: flex; 
                        flex-direction: column; 
                        align-items: stretch; 
                        gap: 1em; 
                    `}>
                        <div css={css`
                            display: grid;
                            grid-template-columns: 1fr 1fr; 
                            gap: 1em;
                            place-items: center; 
                            > * {
                                white-space: nowrap;
                            }
                        `}>
                            <CtaLink signal='admin' onClick={() => { setAdminModalOpened(true) }}>
                                <FormattedMessage id='summary' />
                                <ReactSVG
                                    src={editIcon}
                                    css={css`
                                        width: 20px;
                                        margin-left: 6px;
                                    `}
                                />
                            </CtaLink>
                            <CtaLink signal='warn' onClick={() => setDeleteModalOpened(true)}>
                                <FormattedMessage id='deleteTest' />
                                <ReactSVG
                                    src={deleteIcon}
                                    css={css`
                                        width: 20px;
                                        margin-left: 6px;
                                    `}
                                />
                            </CtaLink>
                        </div>

                        <TestCard
                            test={test}
                            pitch={pitch}
                            css={css`flex-grow: 1;`}
                        />
                    </div>

                    <div css={css`grid-area: zones; display: grid; width: 100%; align-items: start; grid-gap: 1em;`} >
                        {(!testWaiting && test.zoneResults && test.zoneResults.length !== 0) &&
                            <div css={css`display: flex; gap: 1em; width: 100%;`}>
                                <div css={css`
                                    width: 100%;
                                    margin: auto;
                                    height: min-content;
                                    border-radius: 0.3125em;
                                    padding: 0.75em;
                                    box-shadow: 0 0.25em 0.75em 0 rgba(0,0,0,0.3);
                                    background-color: ${rgba(colors.stuff, 0.66)};
                                `}>
                                    <TestZoneResultsEditor
                                        test={test}
                                        backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                                    />
                                    <div css={css`
                                        display: flex; 
                                        justify-content: space-between; 
                                        align-items: center;
                                        margin-top: 0.5em;`}
                                    >
                                        <Orientation
                                            heading={pitch ? pitch.orientation : undefined}
                                        />
                                        <div css={css`color: ${colors.light}; text-transform: capitalize;`}>
                                            {formatMessage({ id: selectedMode })}
                                        </div>
                                        <GridModeSwitcher css={css`width: 4em;`} />
                                    </div>
                                </div>
                            </div>
                        }

                        {!!test?.parameterResults?.length &&
                            <div css={css`display: grid; grid-gap: 0.4em; max-width: 36em;`}>
                                {test.parameterResults.map(parameterResult => (
                                    <Parameter
                                        key={parameterResult['@id']}
                                        parameterResult={parameterResult}
                                        onEditSubmit={handleParamUpdate}
                                        showOptimum
                                    />
                                ))
                                }
                            </div>
                        }
                    </div>
                    <div css={css`grid-area: advice;`}>
                        <TestsAdvice test={test} />
                    </div>
                </div>
            </div >


            <div css={css`
                    background-color: ${colors.stuff};
                    padding: 3em 2em 5em 2em;
                    padding-left: 2.5em;
                    color: ${colors.white};
                `}>
                <PageTitle>
                    <FormattedMessage id='previousTests' />
                </PageTitle>
                {testHistoryWaiting
                    ?
                    <FallbackMessage>
                        <FormattedMessage id='loading' />
                    </FallbackMessage>
                    :
                    (testHistory && testHistory.length > 0)
                        ?
                        <ClientPagination
                            completeData={testHistory}
                            setDataSubset={setHistorySubset}
                            entriesPerPage={12}
                        >
                            <TestHistory historyData={historySubset} test={test} />
                        </ClientPagination>
                        :
                        <FallbackMessage>
                            <FormattedMessage
                                id='noTestsInHistory'
                                values={{
                                    testCategory: (test && test.testCategory) ? test.testCategory.name : <LoaderText />
                                }}
                            />
                        </FallbackMessage>
                }
            </div>

            {
                (test && adminModalOpened) &&
                <TestAdminModal
                    css={css`width: 56em;`}
                    test={test}
                    onClickClose={() => { setAdminModalOpened(false) }}
                    onSave={() => {
                        setAdminModalOpened(false)
                        fetchTest()
                    }}
                />
            }

            {
                (test && deleteModalOpened) &&
                <DeleteWarnModal
                    header={formatMessage({ id: 'deleteTest' })}
                    handleClose={() => {
                        setDeleteModalOpened(false)
                        setDeletionError('')
                    }}
                    handleDelete={handleDeleteTest}
                    message={deletionError || formatMessage({ id: 'deleteTestWarnMessage' })}
                />
            }
        </div >
    )
}