import React from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import TestCategoryIcon from './TestCategoryIcon'
import { colors, fonts, timings } from '../../style/vars'
import getTestCategoryGroupColor from '../../utils/getTestCategoryGroupColor'

const style = {
    cont: (color, checked, readOnly) => css`
        border-radius: 0.25em;
        overflow: hidden;
        background-color: ${colors.stuff};
        box-shadow: 0 0.25em 0.75em 0 rgba(0,0,0,0.3);
        padding: 1em;
        transition: all ${timings.fast} ease;
        font-family: ${fonts.special};
        user-select: none;
        text-transform: uppercase;
        touch-action: none;
        height: 6em;
        display: flex;
        line-height: 1.5em;
        justify-content: center;
        align-items: center;
        border: 2px solid #394047;
        width: 15em;

        svg {
            color: white ;
        }

        ${!readOnly ? `
            cursor: pointer;
            &:hover {
                transition: all ${timings.snappy} ease;
                background-color: ${rgba(color, 0.5)};
                border-color: ${rgba(color, 0.5)}
            }
            .ios &:hover {
                background-color: ${colors.stuff};
                border-color: ${colors.stuff};
            }
        ` : ''}

        span {
            display: block;
            font-size: 1.125em;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        ${checked ? `
            border: 2px solid ${color};
            color: ${color};
            opacity: 1;

            svg {
                color: ${color};
                fill: ${color};
            }
        ` : ''}
    `,
}

export default function TestCategoryCheckbox({
    testCategory: {
        name,
        icon,
        group,
    },
    checked = false,
    readOnly = false,
    ...props
}) {
    const color = getTestCategoryGroupColor(group)
    return (
        <div css={css`width: 8em; padding: 8px; display: grid; justify-content: center;`}>
            <div css={style.cont(color, checked, readOnly)} {...props}>
                <TestCategoryIcon
                    iconName={icon}
                    color={checked ? colors.stuff : colors.white}
                    css={css`font-size: 3em; color: white;`}
                />

            </div>
            <span css={css`
            margin-top: .5em;
            width: 100%;
            display: block;
            text-align: center;
            text-transform: uppercase;
            overflow: hidden;
            font-size: min(1em, 12px);
            `}>{name}</span>
        </div>

    )
}