import React, { useCallback, useMemo, useContext } from 'react'
import { css } from '@emotion/react'
import capitalize from 'voca/capitalize'
import { colors, backendColors } from '../../style/vars'
import useRaw from '../../hooks/useRaw'
import PageTitle from '../_general/PageTitle'
import ScoreIndicator from '../_general/ScoreIndicator'
import Table from '../_general/Table'
import AnalysisDate from '../analysis/AnalysisDate'
import RcpMark from '../analysis/RcpMark'
import BrowsePanel from '../_control/BrowsePanel'
import PitchListContext from '../../context/PitchList'
import { useSearchParams } from 'react-router'
import { MTM_LIST_TYPES, MTM_EVENT_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import FancyDropdown from '../_control/FancyDropdown'
import ViewportContext from '../../context/ViewPort'
import { useIntl, FormattedMessage } from 'react-intl'
import TruncatedSummaryText from '../analysis/TruncatedSummaryText'

function getNumberFromPageLink(pageString) {
    if (pageString === undefined) return 1
    return String(pageString)
        ?.match(/page=(?<number>[\d]+)/)
        ?.groups
        ?.number
}

const resultsPerPage = 20

export default function AnalysisOverview({ ...props }) {
    const track = useTracker()
    const { formatMessage } = useIntl()
    const { pitches } = useContext(PitchListContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const { showCompactUI } = useContext(ViewportContext)

    const [pageNum, selectedPitch] = useMemo(() => {
        const page = parseInt(searchParams.get('page')) || 1
        const pitchParam = searchParams.get('pitch')
        const pitchId = (pitchParam === 'null' || !pitchParam) ? null : parseInt(pitchParam)
        return [page, pitchId]
    }, [searchParams])

    const apiEndPoint = useMemo(() => {
        return selectedPitch ?
            `/api/current/pitches/${selectedPitch}/analyses?limit=${resultsPerPage}&page=${pageNum}`
            : `/api/current/surface-analyses?limit=${resultsPerPage}&page=${pageNum}`
    }, [selectedPitch, pageNum])

    const [
        {
            'hydra:member': analyses = [],
            'hydra:view': {
                'hydra:first': firstPage,
                'hydra:last': lastPage } = {}
        } = {},
        { busy: analysesBusy }
    ] = useRaw(apiEndPoint, [apiEndPoint])

    const [pitchInfo = {}] = useRaw(`/api/current/pitches/${selectedPitch}`,
        [selectedPitch]
    )

    const getRowHref = useCallback((analysis) => {
        return `/pitch/${analysis.pitch.id}/analysis/${analysis.id}`
    }, [])

    const pitchFilterOptions = useMemo(() => {
        const options = pitches.map(p => ({
            value: p.id,
            label: p.name
        }))
        options.unshift({
            value: null,
            label: formatMessage({ id: 'allSportsFields' })
        })
        return options
    }, [pitches])

    const handlePitchChange = useCallback((e) => {
        const pitchId = e.value
        setSearchParams({ pitch: pitchId, page: 1 })
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['pitch-select'],
            [MTM_VARIABLES['list-value']]: pitchId
        })
    }, [setSearchParams])

    const handlePageChange = useCallback((num) => {
        setSearchParams(prev => {
            prev.set('page', num)
            return prev
        })
    }, [setSearchParams])

    return (
        <div
            css={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'start',
            }}
            {...props}>
            <div css={style.header}>
                <PageTitle css={css`margin-bottom: 0;`}>
                    <span css={{ color: colors.main1 }}>
                        <FormattedMessage id='surfaceAnalysisOverview' />
                    </span>
                </PageTitle>
                <BrowsePanel
                    pageNum={pageNum}
                    setPageNum={handlePageChange}
                    firstPage={getNumberFromPageLink(firstPage)}
                    lastPage={getNumberFromPageLink(lastPage)}
                    busy={analysesBusy}
                />
                {!showCompactUI && <div css={css`display: flex; align-items: center;`}>
                    <span
                        css={style.dropdownTitle}
                    >
                        <FormattedMessage id='showMenu' />
                    </span>
                    <FancyDropdown
                        value={pitchFilterOptions.find(o => o.value === selectedPitch)}
                        onChange={handlePitchChange}
                        options={pitchFilterOptions}
                        css={style.dropdown}
                    />
                </div>}
            </div>
            <Table
                data={analyses}
                loading={analysesBusy}
                fallback={formatMessage({ id: 'noAnalysesYet' })}
                rowKey={row => (row.id)}
                sortOn='date'
                sortDirection='desc'
                getRowHref={getRowHref}
                columns={[
                    {
                        key: 'date',
                        header: capitalize(formatMessage({ id: 'analysisDate' })),
                        renderData: r => <AnalysisDate
                            date={Number(r.date)}
                            timeZone={selectedPitch ? pitchInfo?.venue?.timeZone : r.pitch.venue?.timeZone}
                            css={css`line-height: 1.2em; white-space: nowrap;`}
                        />,
                        sortable: true,
                    },
                    {
                        key: 'pitch',
                        header: capitalize(formatMessage({ id: 'sportsField' })),
                        renderData: ({ pitch }) => (
                            <div css={css`
                                white-space: ${showCompactUI ? 'normal' : 'nowrap'};
                                min-width: 10em;
                            `}>
                                {showCompactUI ?
                                    `${pitch?.name || pitchInfo?.name || 'Unknown Pitch'}`
                                    : `${pitch?.venue?.name || pitchInfo?.venue?.name || 'Unknown Venue'} - ${pitch?.name || pitchInfo?.name || 'Unknown Pitch'}`}
                            </div>
                        ),
                    },
                    {
                        key: 'summaryText',
                        header: capitalize(formatMessage({ id: 'summary' }).toLowerCase()),
                        renderData: r => <TruncatedSummaryText
                            /* css={css`
                                width: 15em;

                                @media screen and (max-width: 800px) {
                                    width: 5em;
                                }
                            `} */
                            summary={r.summary}
                        />,
                        wide: true
                    },
                    {
                        key: 'rawCertified',
                        header: capitalize(formatMessage({ id: 'rawCertified' })),
                        renderData: analysis => (
                            analysis.rawCertified === true
                                ? <RcpMark />
                                : formatMessage({ id: 'no' })
                        ),
                        sortable: true,
                    },
                    {
                        key: 'testGroups',
                        header: capitalize(formatMessage({ id: 'category' })),
                        renderData: (analysis) => {
                            let string = ''
                            for (const group of analysis.testGroups) {
                                string += `${capitalize(group)}, `
                            }
                            return string.substr(0, string.length - 2) || 'None'
                        },
                    },
                    {
                        key: 'numberOfTests',
                        header: capitalize(formatMessage({ id: 'tests' })),
                        sortable: true,
                        renderData: (analysis) => analysis?.numberOfTests ?? analysis?.tests?.length
                    },
                    {
                        key: 'score',
                        header: capitalize(formatMessage({ id: 'score' })),
                        renderData: analysis => (
                            <ScoreIndicator
                                value={analysis.score}
                                color={backendColors[analysis.assessment]}
                                css={css`font-size: 1em;`}
                            />
                        ),
                        sortable: true,
                        shrink: true,
                    },
                ].filter(col => showCompactUI ?
                    (col.key !== 'rawCertified' && col.key !== 'testGroups' && col.key !== 'summaryText')
                    : true
                )}
                css={{ flexGrow: 1 }}
            />
            {!analysesBusy &&
                <BrowsePanel
                    pageNum={pageNum}
                    setPageNum={handlePageChange}
                    firstPage={getNumberFromPageLink(firstPage)}
                    lastPage={getNumberFromPageLink(lastPage)}
                    busy={analysesBusy}
                    css={css`margin-top: 1em;`}
                    grow={0} />
            }
        </div>
    )
}

const style = {
    dropdown: css`
        width: 16em;
        z-index: 2000;
    `,
    dropdownTitle: css`
        color: ${colors.subtle};
        font-size: 0.875em;
        font-weight: 300;
        margin-right: 0.75em;
    `,
    header: css`
        display: flex; 
        justify-content: stretch; 
        flex-wrap: wrap; gap: 1em;
        margin-bottom: 2em;
    `
}