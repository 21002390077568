import { useContext, useMemo } from "react"
import { useIntl } from "react-intl"
import useBridge from "../../hooks/useBridge"
import Loader from "../_general/Loader"
import TimezoneContext from "../../context/Timezone"
import FancyDropdown from "../_control/FancyDropdown"

export default function ReporterTimezoneSelector({ ...props }) {

    const { formatMessage } = useIntl()

    const { reporterTz, setReporterTz, browserTimezone } = useContext(TimezoneContext)
    const { data: timeZoneOptions, loading } = useBridge('/api/current/frontend-bridge/timezones')

    const options = useMemo(() => {
        if (!timeZoneOptions) return []
        return [
            {
                value: browserTimezone,
                label: formatMessage({ id: 'useBrowsersTimezone' }),

            },
            ...Object.entries(timeZoneOptions).map(([key, val]) => ({
                value: key,
                label: val,
            }))
        ]
    }, [timeZoneOptions, browserTimezone])

    return loading ? <Loader /> :
        <FancyDropdown
            subtle={true}
            compact={true}
            value={options?.find(opt => opt.value === reporterTz)}
            options={options}
            onChange={val => setReporterTz(val.value)}
        />
}