import React, { useState, useCallback, useContext, useEffect } from 'react'
import { getUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import Selector from '../_control/OptionsSelector'
import NumberScroller from '../_control/NumberScroller'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import { useMemo } from 'react'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import PitchContext from '../../context/Pitch'

export default function MowingForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [mowingTechnique, setMowingTechnique] = useState()
    const [machineType, setMachineType] = useState()
    const [mowingDirection, setMowingDirection] = useState()
    const [mowingHeight, setMowingHeight] = useState(22)
    const [clippingWeights, setClippingWeights] = useState(0)
    //other
    const { data: techniqueOptions, loading: waitingForTechniques } = useBridge(`/api/current/frontend-bridge/mowing-techniques`)
    const { data: machineTypes, loading: waitingForTypes } = useBridge(`/api/current/frontend-bridge/mowing-machine-types`)
    const { data: directions, loading: waitingForDirections } = useBridge(`/api/current/frontend-bridge/directions`)
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/mowing-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setMowingTechnique(data.mowingTechnique)
                setMachineType(data.machineType)
                setMowingDirection(data.mowingDirection)
                setMowingHeight(data.mowingHeight)
                setClippingWeights(data.clippingWeights)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        if (!mowingTechnique) return setFormError(formatMessage({ id: 'pleaseSelectMowingTechnique' }))
        if (!machineType) return setFormError(formatMessage({ id: 'pleaseSelectMachineType' }))
        if (!mowingDirection) return setFormError(formatMessage({ id: 'pleaseSelectMowingDirection' }))
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            machineType,
            mowingTechnique,
            mowingDirection,
            mowingHeight: mowingHeight ? parseFloat(mowingHeight) : 0,
            clippingWeights: clippingWeights ? parseFloat(clippingWeights) : 0,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            } else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const eventPayload = {
                ...payload,
                name: `Mowing event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }
            const { data, error } = await post(`/api/current/${eventType.path}`, { body: eventPayload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            } else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [machineType, mowingTechnique, mowingDirection, mowingHeight, clippingWeights, pitch, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => {
        return waitingForTechniques || waitingForTypes || waitingForDirections || isLoading
    }, [waitingForTechniques, waitingForTypes, waitingForDirections, isLoading])

    return (<>
        <div css={css`display: flex; flex-wrap: wrap;`}>
            {isBusy ? <FormLoader /> : <>
                <div css={css`display: flex; flex-direction: column; align-items: flex-start;`} >
                    <EventDateTimePicker
                        unixDate={selectedDateTime}
                        setUnixDate={setSelectedDateTime}
                    />
                    <div css={css`
                        display: flex; 
                        gap: 0 3em;
                        flex-wrap: wrap;
                    `}>
                        <NumberScroller
                            label={formatMessage({ id: 'timeTaken' })}
                            minVal={0}
                            maxVal={24}
                            state={timeTaken}
                            unit={formatMessage({ id: 'hours' })}
                            step={0.5}
                            setState={setTimeTaken}
                        />
                        <NumberScroller
                            label={formatMessage({ id: 'heightOfCut' })}
                            minVal={0}
                            maxVal={40}
                            unit="mm"
                            state={mowingHeight}
                            setState={setMowingHeight}
                        />
                        <NumberScroller
                            label={formatMessage({ id: 'clippingVolume' })}
                            maxVal={2000}
                            unit="L"
                            state={clippingWeights}
                            setState={setClippingWeights}
                        />
                    </div>

                    <Selector
                        label={formatMessage({ id: 'mowingTechnique' })}
                        optionsObject={techniqueOptions}
                        setState={setMowingTechnique}
                        state={mowingTechnique}
                    />
                    <Selector
                        label={formatMessage({ id: 'machineType' })}
                        optionsObject={machineTypes}
                        setState={setMachineType}
                        state={machineType}
                    />
                    <Selector
                        label={formatMessage({ id: 'mowingDirection' })}
                        optionsObject={directions}
                        setState={setMowingDirection}
                        state={mowingDirection}
                    />
                </div>
            </>}
        </div>
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            disabled={isBusy}
            type="button"
            onClick={(e) => validateAndSubmit(e)}>
            <FormattedMessage id='save' />
        </button>
    </>)
}
