import { css } from "@emotion/react"
import { useCallback, useContext, useMemo, useState } from "react"
import HealthEffectsMap from "../physicalAssessment/HealthEffectsMap"
import { BodyPartProvider } from "../../context/BodyPart"
import { colors } from "../../style/vars"
import EffectsList from "../physicalAssessment/EffectsList"
import FancyDropdown from "../_control/FancyDropdown"
import { SurfaceLayerProvider } from "../../context/SurfaceLayer"
import { SurfaceHighlightsProvider } from "../../context/SurfaceHighlights"
import PitchEffects from "../physicalAssessment/PitchEffects"
import { useIntl } from "react-intl"
import SwitcherSimple from "../_control/SwitcherSimple"
import PreferredViewContext from "../../context/PreferredView"
import TestAverageLinearGauge from "../test/TestAverageLinearGauge"

export default function AnalysisDataSummary({ analysis, tests, ...props }) {

    const { formatMessage } = useIntl()

    const { preferredView, setPreferredView, PREFERRED_VIEW_TYPES } = useContext(PreferredViewContext)

    const filterOptions = useMemo(() => [
        { label: formatMessage({ id: 'all' }), value: 'all' },
        { label: formatMessage({ id: 'severeEffect' }), value: 'severe' },
        { label: formatMessage({ id: 'lightEffect' }), value: 'light' },
        { label: formatMessage({ id: 'positive' }), value: 'positive' },
        { label: formatMessage({ id: 'negative' }), value: 'negative' },
    ], [])

    const [filterValue, setFilterValue] = useState(filterOptions[0])

    const [
        allPhysicalAssessments,
        posSev,
        posLight,
        negSev,
        negLight,
        allPitchImpacts,
        posSev2,
        posLight2,
        negSev2,
        negLight2,
    ] = useMemo(() => {
        const [all, posSev, posLight, negSev, negLight] = [[], [], [], [], []] //player
        const [all2, posSev2, posLight2, negSev2, negLight2] = [[], [], [], [], []] // pitch

        analysis?.tests?.forEach(test => {
            if (Array.isArray(test?.physicalImpact)) {
                all.push(...test.physicalImpact)
                for (const impact of test.physicalImpact) {
                    const isPos = !impact.healthEffect?.negativeEffect
                    const isSev = impact.severity === 'severe'
                    if (isPos && isSev) posSev.push(impact)
                    if (isPos && !isSev) posLight.push(impact)
                    if (!isPos && isSev) negSev.push(impact)
                    if (!isPos && !isSev) negLight.push(impact)
                }
            }

            if (Array.isArray(test?.pitchImpact)) {
                all2.push(...test.pitchImpact)
                for (const impact of test.pitchImpact) {
                    const isPos = !impact.pitchEffect?.negativeEffect
                    const isSev = impact.severity === 'severe'
                    if (isPos && isSev) posSev2.push(impact)
                    if (isPos && !isSev) posLight2.push(impact)
                    if (!isPos && isSev) negSev2.push(impact)
                    if (!isPos && !isSev) negLight2.push(impact)
                }
            }
        })

        return [
            filterImpactArray(all, filterValue, 'player'),
            filterImpactArray(posSev, filterValue, 'player'),
            filterImpactArray(posLight, filterValue, 'player'),
            filterImpactArray(negSev, filterValue, 'player'),
            filterImpactArray(negLight, filterValue, 'player'),
            filterImpactArray(all2, filterValue, 'pitch'),
            filterImpactArray(posSev2, filterValue, 'pitch'),
            filterImpactArray(posLight2, filterValue, 'pitch'),
            filterImpactArray(negSev2, filterValue, 'pitch'),
            filterImpactArray(negLight2, filterValue, 'pitch'),
        ]
    }, [analysis, filterValue])

    const [showHealthEffects, showPitchEffects, showToggle] = useMemo(() => {
        let hasHealthEffects = false
        let hasPitchEffects = false

        analysis?.tests?.forEach(test => {
            if (!!test?.testCategory?.hasPhysicalAssessments) {
                if (!hasHealthEffects) hasHealthEffects = true
            }
            if (!!test?.testCategory?.hasPitchAssessments) {
                if (!hasPitchEffects) hasPitchEffects = true
            }
        })

        if (hasHealthEffects && hasPitchEffects) {
            // if test has configuration for both, show a toggle and only one at a time
            switch (preferredView) {
                case PREFERRED_VIEW_TYPES.performance: return [hasHealthEffects, false, true]
                case PREFERRED_VIEW_TYPES.greenkeeping: return [false, hasPitchEffects, true]
                default: return [hasHealthEffects, false, true]
            }
        } else {
            // else show whichever has configuration, if any, and no toggle
            return [hasHealthEffects, hasPitchEffects, false]
        }
    }, [analysis, preferredView, PREFERRED_VIEW_TYPES])

    const handlePreferredViewChange = useCallback(() => {
        setPreferredView(curr => curr === PREFERRED_VIEW_TYPES.performance ?
            PREFERRED_VIEW_TYPES.greenkeeping
            : PREFERRED_VIEW_TYPES.performance
        )
    }, [setPreferredView, PREFERRED_VIEW_TYPES])

    if (!showHealthEffects && !showPitchEffects) return
    return <div css={style.wrapper} id='analysis-data-summary'>
        <div css={style.grid}>
            {showHealthEffects &&
                <BodyPartProvider >
                    <div css={style.image}>
                        {showToggle &&
                            <SwitcherSimple css={css`justify-self: start;`}
                                checked={!showHealthEffects}
                                onClick={handlePreferredViewChange}
                                unCheckedLabel={formatMessage({ id: 'performance' })}
                                checkedLabel={formatMessage({ id: 'grounds' })}
                                buttonCenter={true}
                            />
                        }
                        <HealthEffectsMap physicalAssessments={allPhysicalAssessments} css={css`height: 20em;`} />
                        <FancyDropdown
                            value={filterValue}
                            options={filterOptions}
                            onChange={setFilterValue}
                            openUpwards={true}
                            subtle={true}
                            compact={true}
                        />
                    </div>

                    <div css={style.effectsWrapper}>
                        <EffectsList effects={posSev} />
                        {posSev.length > 0 && posLight.length > 0 &&
                            <div css={css`height: 1em;`} />
                        }
                        <EffectsList effects={posLight} />
                        {posLight.length > 0 && negLight.length > 0 &&
                            <div css={css`height: 1em;`} />
                        }
                        <EffectsList effects={negLight} />
                        {negSev.length > 0 && negLight.length > 0 &&
                            <div css={css`height: 1em;`} />
                        }
                        <EffectsList effects={negSev} />
                    </div>
                </BodyPartProvider>
            }

            {showPitchEffects &&
                <SurfaceLayerProvider >
                    <div css={style.image}>
                        {showToggle &&
                            <SwitcherSimple css={css`justify-self: start; `}
                                checked={!showHealthEffects}
                                onClick={handlePreferredViewChange}
                                unCheckedLabel={formatMessage({ id: 'performance' })}
                                checkedLabel={formatMessage({ id: 'grounds' })}
                                buttonCenter={true}
                            />
                        }
                        <SurfaceHighlightsProvider pitchImpacts={allPitchImpacts}>
                            <PitchEffects css={css`height: 20em;`} />
                        </SurfaceHighlightsProvider>

                        <FancyDropdown
                            value={filterValue}
                            options={filterOptions}
                            onChange={setFilterValue}
                            openUpwards={true}
                            subtle={true}
                            compact={true}
                        />
                    </div>

                    <div css={style.effectsWrapper}>
                        <EffectsList effects={posSev2} type='pitch' />
                        {posSev2.length > 0 && posLight2.length > 0 &&
                            <div css={css`height: 1em;`} />
                        }
                        <EffectsList effects={posLight2} type='pitch' />
                        {posLight2.length > 0 && negLight2.length > 0 &&
                            <div css={css`height: 1em;`} />
                        }
                        <EffectsList effects={negLight2} type='pitch' />
                        {negSev2.length > 0 && negLight2.length > 0 &&
                            <div css={css`height: 1em;`} />
                        }
                        <EffectsList effects={negSev2} type='pitch' />
                    </div>
                </SurfaceLayerProvider>
            }

            <div css={style.testResults} >
                {tests?.map(t =>
                    <TestAverageLinearGauge test={t} />
                )}
            </div>
        </div>
    </div>
}

const style = {
    wrapper: css`
        display: flex; 
        flex-direction: column;
        background: ${colors.eventDark};
        background-image: linear-gradient(170deg, rgba(255,255,255,0) 50%, #02F2FF1C);
        padding: 1em;
        margin-bottom: 1em;
        border-radius: 5px;
        max-width: 3200px;
    `,
    grid: css`
        display: grid;
        grid-template: 1fr / 24em 1fr 1fr;
        gap: 2em;

        @media screen and (max-width: 800px) {
            display: flex; 
            flex-direction: column;
        }
    `,
    testResults: css`
        margin: 0;
        display: grid;
        grid-template-columns: 1fr;
        overflow: hidden;
        place-items: start;
        max-height: 29em;
        overflow-Y: auto;
        user-select: none;

        @media screen and (max-width: 800px) {
            width: 100%;
            padding-left: 0.5em;
        }
    `,
    image: css`
        padding: 1em;
        flex-grow: 1;
        max-width: 24em;
        width: 100%;
        height: 100%;
        min-height: 29em;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-self: start;

        @media screen and (max-width: 800px) {
            place-self: center;
        }
    `,
    effectsWrapper: css`
        padding-left: 1em;
        padding-right: 1em;
        overflow: auto;
        max-height: 29em;

        border-left: 1px solid rgba(255,255,255,0.1);
        border-right: 1px solid rgba(255,255,255,0.1);

        @media screen and (max-width: 800px) {
            border: none;
        }
    `,
}

function filterImpactArray(arr, filter, type) {
    return arr.filter(impact => {

        const isPos = type === 'pitch' ?
            !impact.pitchEffect?.negativeEffect
            : !impact.healthEffect?.negativeEffect
        const isSev = impact.severity === 'severe'

        switch (filter.value) {
            case 'all': return true
            case 'positive': return isPos
            case 'negative': return !isPos
            case 'light': return !isSev
            case 'severe': return isSev
            default: return true
        }
    })
}