import React, { useCallback, useMemo, useRef, useState } from 'react'
import { css } from '@emotion/react'
import EventType from './EventType'
import { colors, timings } from '../../style/vars'
import Loader from '../_general/Loader'
import { getEventTypeColor } from '../../utils/eventUtils'
import FancyDropdown from '../_control/FancyDropdown'
import { getAllEventGroups, getGroupsForEvent } from '../../utils/eventUtils'
import CtaLink from '../_control/CtaLink'
import EventFilterContext from '../../context/EventFilter'
import { useContext } from 'react'
import { useEffect } from 'react'
import { MTM_EVENT_TYPES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { ReactSVG } from 'react-svg'
import calendarIcon from '../../assets/icons/nav/calendar.svg'
import chevronIcon from '../../assets/icons/chevron.svg'
import { useIntl, FormattedMessage } from 'react-intl'
import useBridge from '../../hooks/useBridge'

const dropdownStyle = css`
    min-width: 17.5em;
`

function EventTypeList({
    ...props
}) {
    const {
        data: eventTypes,
        loading: isBusy,
    } = useBridge('/api/current/frontend-bridge/event-types')

    const track = useTracker()
    const { formatMessage } = useIntl()

    const sortedTypes = useMemo(() => {
        if (!eventTypes) return []
        const playerEvents = eventTypes
            .filter(t => getEventTypeColor(t.entityShortName) === colors.player)
            .toSorted((a, b) => a.entityShortNameLocalised.localeCompare(b.entityShortNameLocalised))
        const pitchEvents = eventTypes
            .filter(t => getEventTypeColor(t.entityShortName) === colors.pitch)
            .toSorted((a, b) => a.entityShortNameLocalised.localeCompare(b.entityShortNameLocalised))
        return [...playerEvents, ...pitchEvents]
    }, [eventTypes])

    const eventGroupOptions = useMemo(() => {
        return [
            {
                value: 'all',
                label: formatMessage({ id: 'allEvents' }),
            },
            ...getAllEventGroups().map(group => ({
                label: formatMessage({ id: group }),
                value: group,
            })),
        ]
    }, [formatMessage])

    const [group, setGroup] = useState({
        value: 'all',
        label: formatMessage({ id: 'allEvents' }),
    })

    useEffect(() => {
        const savedGroup = localStorage.getItem('eventGroup')
        if (savedGroup && getAllEventGroups().includes(savedGroup)) {
            const matched = eventGroupOptions.find(x => x.value === savedGroup)
            if (matched) {
                setGroup(matched)
            }
        }
    }, [eventGroupOptions])

    useEffect(() => {
        localStorage.setItem('eventGroup', group.value)
    }, [group])

    const handleSetGroup = useCallback((val) => {
        setGroup(eventGroupOptions.find(x => x.value === val.value))
    }, [eventGroupOptions])

    const [filter, setFilter] = useContext(EventFilterContext)

    const handleClearFilter = useCallback((e) => {
        setFilter([])
        track({
            'event': MTM_EVENT_TYPES["unfilter-calendar"],
        })
    }, [])

    const [expanded, setExpanded] = useState(false)

    const listRef = useRef()

    return (
        <>
            {expanded && <div css={css`
                position: fixed;
                width: 100vw;
                height: 100vh;
                background: rgba(0,0,0,0.3);
                z-index: 4;
                display: none;
                @media screen and (max-width: 600px) {
                    display: flex;
                }
            `}
                onClick={() => setExpanded(false)}
            />}
            {!expanded && <div css={css`
                position: fixed;
                width: 46px;
                height: 46px;
                color: ${colors.eventDark};
                cursor: pointer;
                color: white;
                background: black;
                opacity: 0.85;
                right: 0;
                bottom: 12px;
                z-index: 5;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 2px solid ${colors.eventDark};
                border-right: none;
                &:hover {
                    background: ${colors.eventDark};
                    color: ${colors.eventLight};
                }
                display: none;
                @media screen and (max-width: 600px) {
                    display: grid;
                }
            `}
                onClick={() => setExpanded(true)}
            >
                <ReactSVG
                    css={css`color: white; width: 60%; height: 60%; place-self: center;`}
                    src={calendarIcon}
                />
            </div>}

            <div css={css`
                height: 100%;
                transition: transform ${timings.smooth};
                z-index: 5;
                
                @media screen and (max-width: 600px) {
                    position: fixed;
                    background: black;
                    right: 0;
                    border-left: 2px solid ${colors.eventDark};
                    border-bottom: 2px solid ${colors.eventDark};
                    border-bottom-left-radius: 5px;
                    transform: ${expanded ? 'translate(0,0)' : 'translate(100%, 0)'};
                    height: calc(90svh - 8em);
                }
            `}{...props}>
                {isBusy
                    ?
                    <Loader size='2em' style={{ margin: '2em' }} />
                    :
                    <>
                        <div css={css`
                            position: absolute;
                            bottom: 1em;
                            left: -2px;
                            width: 4em;
                            height: 8em;
                            transform: translateX(-100%);
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px;
                            background: black;
                            place-items: center;
                            display: none;

                            @media screen and (max-width: 600px) {
                                display: ${expanded ? 'grid' : 'none'};
                            }
                        `} >
                            <div css={css`
                                width: 4em; 
                                height: 4em; 
                                display: grid; 
                                place-items: center;
                            `}
                                onClick={() => listRef?.current?.scroll({ top: 0, behavior: 'smooth' })}
                            >
                                <ReactSVG
                                    css={css`fill: ${colors.soft}; width: 70%; height: 70%;
                                        &:active {
                                            fill: white;
                                        }
                                    `}
                                    src={chevronIcon}

                                />
                            </div>
                            <div css={css`
                                width: 4em; 
                                height: 4em; 
                                display: grid; 
                                place-items: center;
                                transform: rotate(180deg);
                            `}
                                onClick={() => {
                                    listRef?.current?.scroll({ top: listRef.current.scrollHeight, behavior: 'smooth' })
                                }}
                            >
                                <ReactSVG
                                    css={css`fill: ${colors.soft}; width: 70%; height: 70%;
                                        &:active {
                                            fill: white;
                                        }
                                    `}
                                    src={chevronIcon}
                                />
                            </div>
                        </div>

                        <FancyDropdown
                            value={group}
                            options={eventGroupOptions}
                            disabled={isBusy}
                            css={dropdownStyle}
                            onChange={(val) => handleSetGroup(val)}
                            isSearchable={false}
                        />
                        <div css={css`
                            max-height: calc(100% - 4.2em); 
                            overflow-X: visible; 
                            overflow-Y: auto; 
                            margin-top: 0.6em;
                            position: relative;
                        `} ref={listRef}>
                            {(filter?.length !== 0) &&
                                <CtaLink

                                    css={css`
                                        border: 2px solid ${colors.eventLight};
                                        position: absolute;
                                        border-radius: 4px;
                                        top: 0;
                                        right: 0;
                                        z-index: 1;
                                        padding: 0.5em;
                                    `}
                                    onClick={handleClearFilter}
                                >
                                    <FormattedMessage id='clearFilter' />
                                </CtaLink>
                            }
                            {sortedTypes
                                ?.filter(eventType => {
                                    return (group.value === 'all') || getGroupsForEvent(eventType?.entityShortName).includes(group.value)
                                })
                                // TO DO: remove when feature is finished #COVEREVENT
                                .filter(eventType =>
                                    process.env.API_URL.includes('dev') || process.env.API_URL.includes('staging') ?
                                        true
                                        : eventType?.entityShortName !== 'CoverEvent'
                                )

                                .map((eventType) => <EventType
                                    setExpanded={setExpanded}
                                    key={eventType.path}
                                    eventType={eventType}
                                    css={css`
                                        opacity: ${!filter?.includes(eventType.entityShortName) && (filter?.length > 0) && 0.4};
                                    `}
                                />
                                )
                            }
                        </div>
                    </>
                }

            </div>
        </>
    )
}

export default EventTypeList
