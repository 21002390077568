import React from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors, timings } from '../../style/vars'

const style = {
    cont: css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 0.125em;
        background-color: ${colors.substance};
        background-image: linear-gradient(350.6deg, ${rgba(colors.main1, 0.16)} 0%, ${rgba(colors.substance, 0.16)} 50%);
        color: ${colors.white};
        box-shadow: 0 0.5em 2em ${rgba(colors.black, 0.25)};
    `,
    bar: css`
        display: flex;
        align-items: stretch;
        background-color: ${colors.stuff};
        border-radius: 0.125em 0.125em 0 0;
        > div {
            position: relative;
            padding: 3em;
            padding-bottom: 2.75em;
            color: ${colors.sublimate};
            text-transform: uppercase;
            cursor: pointer;
            transition: all ${timings.fast} ease;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 1em;
                height: 0.1875em;
                width: calc(100% - 2em);
                transition: all ${timings.fast} ease;
            }

            &:hover {
                transition: all ${timings.snappy} ease;
                background-color: ${colors.substance};
                color: ${colors.main1};
                &::after {
                    transition: all ${timings.snappy} ease;
                    background-color: ${colors.white};
                }
            }

            &.active {
                color: ${colors.white};
                &::after {
                    background-color: ${colors.main1};
                }
            }

            > span {
                font-size: 0.9375em;
            }
        }
    `,
    body: css`
        display: grid;
        padding: 3em;
        flex-grow: 1;
    `,
}

export default function TabBox({ tabs: tabs = [], activeTabId, onClickTab, showHeader = true, ...props }) {
    const activeTab = tabs.find(tab => tab.id === activeTabId)
    return (
        <div css={style.cont} {...props}>
            {showHeader ? (
                <div css={style.bar}>
                    {tabs.map(tab => (
                        <div
                            key={tab.id}
                            className={tab.id === activeTabId ? 'active' : undefined}
                            onClick={tab.clickable ? () => onClickTab(tab.id) : undefined}
                            css={[
                                css`display: flex; align-items:center;`,
                                tab.clickable ? undefined : css`pointer-events: none;`
                            ]}
                        >
                            <span>{tab.name}</span>
                        </div>
                    ))}
                </div>
            ) : null}

            <div css={style.body}>
                {activeTab && activeTab.children}
            </div>
        </div>
    )
}