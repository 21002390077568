import { createContext, useMemo } from "react"
import useRawApiState from "../../hooks/useRawApiState"

const CovermasterContext = createContext()
export default CovermasterContext

const metricTypeWhitelist = [
    'electricalConductivity',
    'soilMoisture',
    //'temperatureCelsius',
    'airTemperatureCelsius',
    'groundTemperatureCelsius',
    'airHumidity',
    'lightLevel',
]

export function CovermasterContextProvider({ ...props }) {

    const { data: telemetryMetricTypes, isBusy: telemetryMetricTypesBusy } = useRawApiState('/api/current/telemetry-metrics?pagination=false')
    const { data: devices, isBusy: devicesBusy } = useRawApiState('/api/current/devices')

    const covermasterMetricTypes = useMemo(() => {
        return telemetryMetricTypes
            ?.filter(type => metricTypeWhitelist.includes(type.systemLabel))
            ?.toSorted((a, b) => a.sortRank - b.sortRank, {})
            ?? []
    }, [telemetryMetricTypes])

    const covermasterDevices = useMemo(() => {
        if (!Array.isArray(devices)) return []
        return devices  /* TO DO: PUT THIS FILTER BACK LATER */
        return devices.filter(dev => (
            dev.deviceType === 'cover-master'
        ))
    }, [devices])

    const isBusy = useMemo(() => {
        return telemetryMetricTypesBusy || devicesBusy
    }, [telemetryMetricTypesBusy, devicesBusy])

    return <CovermasterContext.Provider
        value={{
            metricTypes: covermasterMetricTypes,
            isBusy,
            devices: covermasterDevices,
        }}
        {...props}
    />
}