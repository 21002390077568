import React from 'react'
import { css } from '@emotion/react'
import PitchBg from './_general/pitch/pitchBg'

// this component uses 0-indexed coordinates
// zoneResults from API are 1-indexed! keep this in mind

function Zones({
    backgroundType,
    dimX,
    dimY,
    renderZone,
    ...props
}) {
    // set up arrays to loop over
    const xLoop = new Array(dimX)
    const yLoop = new Array(dimY)

    // render zones
    const zones = []
    // (X,Y) are according to the "real" field placement
    // X = short side of the field
    // Y = the long side
    for (let y = 0; y < yLoop.length; y++) {
        for (let x = 0; x < xLoop.length; x++) {
            zones.push(
                <div
                    css={style.zone(dimX, dimY, x, y)}
                    key={`${x}-${y}`}
                >
                    <div css={style.zoneInner}>
                        {renderZone(x, y)}
                    </div>
                </div>
            )
        }
    }

    return (
        <div {...props}>
            <div css={style.zones}>
                <PitchBg
                    css={style.bg}
                    alt='pitch-bg'
                    type={backgroundType}
                />
                {zones}
            </div>
        </div>
    )
}

const aspectRatio = 1.5
const style = {
    // X and Y are NOT the on-screen positions, see comment below
    zones: css`
        position: relative;
        width: 100%;
        padding-bottom: ${100 / aspectRatio}%;
    `,
    bg: css`
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-scaling: stretch;
        display: block;
        height: auto;
        width: 100%;
    `,
    zone: (dimX, dimY, posX, posY) => css`
        z-index: 1;
        position: absolute;
        width: ${100 / dimY}%;
        height: ${100 / dimX}%;
        left: ${(posY) * (100 / dimY)}%;
        top: ${(dimX - posX - 1) * (100 / dimX)}%;
        overflow: hidden;
        cursor: pointer;
    `,
    zoneInner: css`
        width: 100%;
        height: 100%;
    `,
}

export default Zones
