import React, { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { UNIT_SYSTEMS } from '../../utils/unitConversion'
import UnitSystemContext from '../../context/UnitSystem'
import FancyDropdown from '../_control/FancyDropdown'

export default function UnitSystemSelector({ ...props }) {
    const { unitSystem, setUnitSystem } = useContext(UnitSystemContext)

    const { formatMessage } = useIntl()

    const options = useMemo(() => {
        return Object.entries(UNIT_SYSTEMS)
            .map(([, { name, translationKey }]) => ({
                value: name,
                label: formatMessage({id: translationKey})
            }))        
    }, [UNIT_SYSTEMS])

    return <FancyDropdown
        subtle={true}
        compact={true}
        options={options}
        value={options?.find(opt => opt.value === unitSystem)}
        onChange={(val) => setUnitSystem(val?.value)}
        isSearchable={false}
    />
}