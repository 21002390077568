import { css } from '@emotion/react'
import { lighten } from 'polished'
import React, { useMemo, useCallback, useContext } from 'react'
import { backendColors, colors, dataColors } from '../../style/vars'
import PitchBg from '../_general/pitch/pitchBg'
import ZoneTransition from './ZoneTransition'
import { interpolateRgbBasis } from 'd3'
import GridModeContext from '../../context/GridMode'
import { useIntl } from 'react-intl'
import PitchTelemetry from '../../context/covermaster/PitchTelemetry'
import useUnit from '../../hooks/useUnit'

/*
    X and Y are NOT the on-screen positions
    The results are shown on-screen as follows

      Y---->
      /====================\
      |____________________|
    ^ |____________________|
    | |____________________|
    | |____________________|
    X \====================/
*/

function sortResults({ posX: ax, posY: ay }, { posX: bx, posY: by }) {
    // sort X coordinate high to low (reverse)
    const dx = bx - ax;
    if (dx === 0) return ay - by;
    return dx;
}

const interpolateMagnitudeColor = interpolateRgbBasis(
    [dataColors.cold, dataColors.neutral, dataColors.neutral, dataColors.warm]
)

export default function ZoneResults({
    gridId,
    backgroundType,
    zoneResults = [],
    isPercentageTemplate = false,
    decimals,
    getZoneBackendColor,
    enableClicks = false,
    onClickZone,
    unitSI,
    underLay,
    ...props
}) {
    const { selectedPosition } = useContext(PitchTelemetry) ?? {}



    const { formatUnit } = useUnit()

    const dimX = useMemo(() => (
        zoneResults.reduce((acc, { posX }) => Math.max(acc, posX), 1)
    ), [zoneResults]);
    const dimY = useMemo(() => (
        zoneResults.reduce((acc, { posY }) => Math.max(acc, posY), 1)
    ), [zoneResults]);
    const allowedDecimals = useMemo(() => (
        decimals ?? 0
    ), [decimals])

    //for percentage based templates, we'll calculate a zone size
    const zoneSize = useMemo(() => {
        const minSize = 10  //%
        const maxSize = 18
        const upperCountLimit = 20
        const lowerCountLimit = 5
        const count = zoneResults.length
        if (count >= upperCountLimit) return minSize
        if (count <= lowerCountLimit) return maxSize
        return maxSize - (((maxSize - minSize) / (upperCountLimit - lowerCountLimit)) * (count - lowerCountLimit))
    }, [zoneResults])

    // for magnitude view
    const [measurements, minVal, maxVal] = useMemo(() => {
        const msm = zoneResults?.filter(z => z.measurement !== null).map(z => z.measurement)
        return [
            msm,
            Math.min(...msm),
            Math.max(...msm)
        ]
    }, [zoneResults])

    const normalizeValue = useCallback((val) => {
        if (measurements.length === 0) return 0
        if (minVal === maxVal) return 0
        return (val - minVal) / (maxVal - minVal)
    }, [measurements, minVal, maxVal])

    const getMagnitudeColor = useCallback((measurement) => {
        if (measurement === null) return dataColors.neutral
        const normalized = normalizeValue(measurement)
        return interpolateMagnitudeColor(normalized)
    }, [normalizeValue])

    const { selectedMode, OPTION_NAMES } = useContext(GridModeContext)

    const smallestZoneDimLength = useMemo(() => {
        // remember in ZoneResults axes are scrambled. x = vertical, y = horizontal
        return dimX > dimY ? 'height' : 'width'
    }, [dimX, dimY])

    const sortedZoneResults = useMemo(() => {
        return zoneResults.slice(0).sort(sortResults)
    }, [zoneResults])

    const { formatMessage } = useIntl()

    return (
        <div css={css`display: flex; flex-direction: column; align-items: stretch; justify-content: center;`}
            {...props}
        >
            <div css={{ position: 'relative', display: 'flex' }}>
                <PitchBg type={backgroundType} css={{ flexGrow: 1 }} />
                {underLay &&
                    <div css={css`
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        > * {
                            width: 100%;
                            height: 100%;
                        }
                    `}>
                        {underLay}
                    </div>
                }

                <div css={{
                    display: 'grid',
                    width: '100%',
                    height: '100%',
                    gridTemplateRows: isPercentageTemplate ? 'none' : `repeat(${dimX}, 1fr)`,
                    gridTemplateColumns: isPercentageTemplate ? 'none' : `repeat(${dimY}, 1fr)`,
                    position: 'absolute',
                    placeItems: 'stretch',
                }}>
                    {sortedZoneResults.map((zone) => {

                        const hasMeasurement = zone.measurement != null;
                        const colorCode = getZoneBackendColor(zone);
                        const isSelectedZone = selectedPosition?.posX === zone.posX && selectedPosition?.posY === zone.posY
                        const isNotSelected = !!selectedPosition && !isSelectedZone

                        const color = selectedMode === OPTION_NAMES.heatmap ? getMagnitudeColor(zone.measurement) :
                            selectedMode === OPTION_NAMES.unprocessed ? dataColors.neutral :
                                (backendColors[colorCode] ?? colors.subtle)

                        if (selectedMode === OPTION_NAMES.transitions) {
                            return isPercentageTemplate ? null : <ZoneTransition
                                key={`zoneResult-test-${gridId}-(${zone.posX},${zone.posY})`}
                                zoneResults={zoneResults}
                                zone={zone}
                                allowedDecimals={allowedDecimals}
                                dimX={dimX}
                                dimY={dimY}
                                smallestZoneDimLength={smallestZoneDimLength}
                                unitSI={unitSI}
                            />
                        } else {
                            return <div
                                css={[
                                    isPercentageTemplate ?
                                        css`
                                        height: ${zoneSize}%;
                                        aspect-ratio: 1;
                                        border-radius: 50%;
                                        display: grid;
                                        place-items: center;
                                        position: absolute;
                                        color: ${colors.black};
                                        overflow: visible;
                                        opacity: ${isNotSelected ? .5 : 1};

                                        top: ${100 - zone.posX}%;
                                        left: ${zone.posY}%;
                                        transform: translate(-50%, -50%);

                                        .orb {
                                            position: absolute;
                                            height: 100%;
                                            aspect-ratio: 1;
                                            max-height: 3.5rem;
                                            filter: drop-shadow(0 0 ${!isSelectedZone ? '0.5em' : '1em'} ${!isSelectedZone ? color : 'white'});
                                            fill: ${color};
                                        }
                                        `
                                        :
                                        css`
                                        height: 100%;
                                        width: 100%;
                                        display: ${(zone.weight === undefined || !!zone.weight) ? 'grid' : 'none'};  // hides zone when weight is 0 or null
                                        place-items: center;
                                        position: relative;
                                        color: ${colors.black};
                                        overflow: visible;
                                        opacity: ${isNotSelected ? .5 : 1};

                                        grid-column: ${zone.posY};
                                        // add one because grid-row should range from 1 to dimX
                                        // and posX ranges from 1 to dimX
                                        grid-row: ${dimX - zone.posX + 1};

                                        .orb {
                                            position: absolute;
                                            ${smallestZoneDimLength}: 70%;
                                            max-width: min(90%, 7em);
                                            max-height: min(90%, 7em);
                                            aspect-ratio: 1;
                                            filter: drop-shadow(0 0 ${!isSelectedZone ? '0.5em' : '1em'} ${!isSelectedZone ? color : 'white'});
                                            fill: ${color};
                                        }
                                        `
                                    ,
                                    (enableClicks && css`
                                        cursor: pointer;
                                        &:hover {
                                            .orb {
                                                filter: drop-shadow(0 0 ${!isSelectedZone ? '0.75em' : '1.25em'} ${lighten(.10)(!isSelectedZone ? color : 'white')});
                                            }
                                        }
                                    `)
                                ]}
                                key={`zoneResult-test-${gridId}-(${zone.posX},${zone.posY})`}
                                onClick={enableClicks ? () => { onClickZone(zone) } : undefined}
                                role='button'
                                tabIndex={enableClicks ? 0 : undefined}
                            >
                                <svg className='orb' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid meet'>
                                    <circle cx='50' cy='50' r='50' />
                                </svg>
                                <div css={css`z-index: 1`}>
                                    {hasMeasurement &&
                                        formatUnit(
                                            Number.parseFloat(zone.measurement, 10),
                                            unitSI,
                                            {
                                                metricPrecision: allowedDecimals,
                                                hideAllUnits: true,
                                            },
                                        )
                                    }
                                </div>
                            </div>
                        }
                    })}
                </div>
            </div>
        </div>
    )
}