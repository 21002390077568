import { useContext, useMemo } from "react"
import PitchTelemetry from "../../../context/covermaster/PitchTelemetry"
import { css } from "@emotion/react"
import CovermasterContext from "../../../context/covermaster/Covermaster"
import { colors, timings, backgrounds } from "../../../style/vars"
import LocaleContext from "../../../context/Locale"
import { formatDistanceToNow, fromUnixTime } from "date-fns"
import { rgba } from "polished"
import TelemetryAvgLinearGauge from "./TelemetryAvgLinearGauge"

export default function LiveMetricsList({ ...props }) {
    const { datefnsLocale } = useContext(LocaleContext)
    const { metricTypes } = useContext(CovermasterContext)
    const {
        selectedPosition,
        selectedMetricType,
        setSelectedMetricType,
        liveZoneResults: zoneResults,
        metricAverages: averages,
        timestamps,
    } = useContext(PitchTelemetry)

    const showZoneDetail = useMemo(() => {
        return !!selectedPosition
    }, [selectedPosition])

    const dataToShow = useMemo(() => {
        if (!zoneResults || !averages) return {}
        return showZoneDetail ?
            zoneResults
                .find(zone => zone.posX === selectedPosition.posX && zone.posY === selectedPosition.posY)?.latestTelemetries ?? {}
            :
            averages
    }, [averages, zoneResults, showZoneDetail, selectedPosition])

    const timeMessage = useMemo(() => {
        const noMeasurementsMsg = `No measurements within the last hour`  /* TO DO: translate */
        if (!timestamps) return noMeasurementsMsg

        const allMin = timestamps.allMin ? formatDistanceToNow(
            fromUnixTime(timestamps.allMin),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */
        const allMax = timestamps.allMax ? formatDistanceToNow(
            fromUnixTime(timestamps.allMax),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */
        const selectedMin = timestamps.selectedMin ? formatDistanceToNow(
            fromUnixTime(timestamps.selectedMin),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */
        const selectedMax = timestamps.selectedMax ? formatDistanceToNow(
            fromUnixTime(timestamps.selectedMax),
            { addSuffix: true, locale: datefnsLocale }
        ) : noMeasurementsMsg /* TO DO: translate */

        const selectedEqual = selectedMax === selectedMin
        const allEqual = allMax === allMin

        return selectedPosition ?
            (selectedEqual ? `${selectedMax ?? noMeasurementsMsg}` : `between ${selectedMin} and ${selectedMax}`)
            :
            (allEqual ? `${allMax ?? noMeasurementsMsg}` : `between ${allMin} and ${allMax}`)
        /* TO DO: translate */
    }, [timestamps, datefnsLocale, selectedPosition])

    return <div css={css`
        max-width: 100%; 
        height: 100%; 
        overflow-y: visible;
    `}>

        {Object.entries(dataToShow)
            .map((entry) => {
                entry[1].metricType = metricTypes.find(type => type['@id'] === entry[0])
                return entry
            })
            .toSorted((entry1, entry2) => entry1[1].metricType.sortRank - entry2[1].metricType.sortRank)
            .map(([metricIRI, dataObj]) => {
                const metricType = dataObj.metricType
                const isSelected = selectedMetricType === metricIRI
                const hasData = Number.isFinite(dataObj.measurement)

                return <div
                    key={metricIRI}
                    onClick={() => setSelectedMetricType(metricIRI)}
                    css={css`
                        margin-bottom: 2px;
                        display: flex; 
                        width: 100%; 
                        justify-content: space-between;
                        background: ${isSelected ? 'rgba(0,0,0,0.35)' : 'rgba(0,0,0,0.1)'};
                        background-image: ${isSelected ? backgrounds.weatherGradient : 'rgba(0,0,0,0.1)'};
                        border-radius: 2px;
                        cursor: pointer;
                        transition: background ${timings.responsive};
                        border-bottom: 1px solid ${isSelected ? colors.main1 : 'transparent'};
                        overflow: hidden;
                        font-size: 0.95em;
                        opacity: ${hasData ? 1 : 0.4};
                        
                        &:hover {
                            ${isSelected ? '' : 'background: rgba(200,200,200,0.05);'}
                        }
                    `}>

                    <TelemetryAvgLinearGauge
                        ratings={metricType.ratings}
                        avg={dataObj.measurement}
                        name={metricType?.title}
                        telemetryMetric={metricType}
                        css={css`font-size: 1.19em;`}
                        unitSI={temporaryShittyUnitNotationImplementation[dataObj?.unit]}
                    />

                </div>
            })
        }

        <div css={css`
            color: ${rgba(colors.soft, 0.5)};
            margin-top: 1em;
        `}>
            {timeMessage}
            {/* To do: translate */}
        </div>
    </div>
}

const temporaryShittyUnitNotationImplementation = {
    'us-cm': 'µS/cm',
    'percentage': '%',
    'degrees-celsius': '°C',
}