function round(num, decimals) {
    return Math.round(num * 10 ** decimals) / (10 ** decimals)
}

function truncateDecimals(value, decimals) {
    if (value === undefined || value === null || !Number.isInteger(decimals)) {
        //console.error('did not truncate decimals for ', value, ' ', decimals)
        return value
    }
    return parseFloat(value.toFixed(decimals))
}

/**
 * @typedef {object} ConvertOptions
 * @param {boolean} [useSecondaryUnit] - Whether to use the secondary unit or not.
 * @param {number} [metricPrecision] - The number of decimal places for the metric output.
 * @param {number} [convertedPrecision] - The number of decimal places for the converted output.
 * @param {boolean} [returnUnconvertedWhenUnknownUnit] - If the unit can't be found, whether to return the original value and unit , or to return null / undefined.
 */
/**
 * Function to perform unit conversion
 * @param {number} value - The value to convert
 * @param {string} metricUnit - The original metric unit of the value
 * @param {string} system - Unit system to convert metric value to
 * @param {ConvertOptions} [options] - Whether to use the secondary unit
 * @returns {Object} - Converted values, primary and secondary unit, and whether a conversion happened { primaryValue, primaryUnit, secondaryValue, secondaryUnit, converted }
 */

// function for calculations. Does not round if we stay in metric
export const convertToUnits = (value, metricUnit, system, options) => {

    const errorResponse = {
        primaryValue: null,
        primaryUnit: undefined,
        secondaryValue: null,
        secondaryUnit: undefined,
        converted: false,
    }

    const conversionsMap = conversionsFromMetric[system]

    const {
        metricPrecision,
        convertedPrecision, // this is for overriding the final precision that will end up being used, primary or secondary
        useSecondaryUnit = true,
        returnUnconvertedWhenUnknownUnit = false
    } = options ?? {}

    if (system === UNIT_SYSTEMS.metric.name) return {
        primaryValue: truncateDecimals(value, metricPrecision),
        primaryUnit: { name: metricUnit },
        secondaryValue: null,
        secondaryUnit: undefined,
        converted: false,
    }

    if (!metricUnit || !conversionsMap?.[metricUnit]) {
        if (returnUnconvertedWhenUnknownUnit) {
            return {
                primaryValue: truncateDecimals(value, 2),
                primaryUnit: { name: metricUnit },
                secondaryValue: null,
                seondaryUnit: undefined,
                converted: false,
            }
        } else {
            //console.error(`Unable to find 'metricUnit' or conversions map for system`)
            return errorResponse
        }
    }

    const { primaryUnit, secondaryUnit } = conversionsMap[metricUnit]

    if (!primaryUnit || !('toUnit' in primaryUnit)) {
        //console.error(`Unable to find properties for converting from ${metricUnit}`)
        return errorResponse
    }

    if (value === undefined || value === null) {
        return {
            primaryValue: value,
            primaryUnit,
            secondaryValue: value,
            secondaryUnit,
            converted: true,
        }
    }

    // Convert to primary unit
    const primaryValue = primaryUnit.toUnit(value)

    const primaryConvertedPrecision = conversionsMap?.[metricUnit]?.primaryUnit?.convertedPrecision ?? 2

    // Convert all decimals to secondary unit if there is one
    if (!secondaryUnit || !useSecondaryUnit) return {
        primaryValue: round(primaryValue, (convertedPrecision ?? primaryConvertedPrecision)),
        primaryUnit,
        secondaryValue: null,
        secondaryUnit: undefined,
        converted: true,
    }

    if (!('toUnitFromPrimary' in secondaryUnit)) {
        //console.error(`Unable to find properties for converting from ${metricUnit}`)
        return errorResponse
    }

    const secondaryValue = secondaryUnit.toUnitFromPrimary(primaryValue % 1)

    const secondaryConvertedPrecision = conversionsMap?.[metricUnit]?.secondaryUnit?.convertedPrecision ?? 2

    return {
        primaryValue: Math.floor(primaryValue),
        primaryUnit,
        secondaryValue: round(secondaryValue, (convertedPrecision ?? secondaryConvertedPrecision)),
        secondaryUnit,
        converted: true,
    }
}

/**
 * Function to perform unit conversion
 * @param {number} primaryValue - The value to convert, corresponding to the primary unit
 * @param {number} secondaryValue - The value to convert, corresponding to the secondary unit
 * @param {string} metricUnit - The metric unit to convert to
 * @param {string} system - Unit system to convert to metric from
 * @returns {Object} - Converted value and unit { metricValue, metricUnit }
 */

export const convertToMetric = (primaryValue, secondaryValue = null, metricUnit, system) => {
    const errorResponse = {
        metricValue: null,
        metricUnit: metricUnit,
        converted: false,
    }

    if (system === UNIT_SYSTEMS.metric.name) return {
        metricValue: primaryValue,
        metricUnit: metricUnit,
        converted: false,
    }

    const conversionsMap = conversionsFromMetric[system]

    if (!metricUnit || !conversionsMap?.[metricUnit]) {
        //console.error(`Unable to find 'metricUnit' or conversion factors for system`)
        return errorResponse
    }

    const { primaryUnit, secondaryUnit } = conversionsMap[metricUnit]

    if (!primaryUnit || !('toMetric' in primaryUnit)) {
        //console.error(`Unable to find properties for converting to ${metricUnit}`)
        return errorResponse
    }

    if (primaryValue === undefined || primaryValue === null) {
        return {
            ...errorResponse,
            metricValue: primaryValue,
            metricUnit: metricUnit,
            converted: true,
        }
    }

    // secondary value is provided but no conversion found in map
    if ((secondaryValue !== undefined && secondaryValue !== null) && (!secondaryUnit || !('toPrimaryFromUnit' in secondaryUnit))) {
        return errorResponse
    }

    const valueFromPrimary = primaryUnit.toMetric(primaryValue)
    let valueFromSecondary
    if (secondaryValue === undefined || secondaryValue === null) {
        valueFromSecondary = 0
    } else {
        valueFromSecondary = primaryUnit.toMetric(
            secondaryUnit?.toPrimaryFromUnit(secondaryValue) ?? 0
        )
    }

    return {
        metricValue: valueFromPrimary + valueFromSecondary,
        metricUnit: metricUnit,
        converted: true,
    }
}

/**
 * @typedef {object} Options
 * @param {number} [precision] - The number of decimal places for the formatted output.
 * @param {number} [convertedPrecision] - The number of decimal places for the formatted output when converted.
 * @param {number} [metricPrecision] - The number of decimal places for the formatted output when returned as metric.
 * @param {boolean} [compact] - Whether to omit spaces between number and unit.
 * @param {boolean} [useSecondaryUnit] - Whether to use the secondary unit or not.
 * @param {string} [separator] - How to separate unit one and two (default: space)
 * @param {boolean} [hideAllUnits] - Hides all units
 */
/**
 * Formats a number and converts it from one unit to another.
 * @param {number} value - The value to convert and format.
 * @param {string} metricUnit - The metric unit of the input value.
 * @param {string} system - Unit system to convert metric value to
 * @param {Options} [options] - Additional options
 * @returns {string} - The formatted string representation of the value.
 */

// for displaying things as a string. Will always round, even if we stay in metric
export const formatUnit = (value, metricUnit, system, options) => {

    const conversionsMap = conversionsFromMetric[system]
    const convertedPrimaryPrecision = conversionsMap?.[metricUnit]?.primaryUnit?.convertedPrecision ?? 1
    const convertedSecondaryPrecision = conversionsMap?.[metricUnit]?.secondaryUnit?.convertedPrecision ?? 1

    const {
        metricPrecision,
        convertedPrecision,
        compact = true,
        useSecondaryUnit = true,
        showMetricUnit = false,
        separator = ' ',
        hideAllUnits = false,
    } = options ?? {}

    const metricPrecisionToUse = (metricPrecision ?? conversionsMap?.[metricUnit]?.metricPrecision) ?? 1

    const space = compact ? '' : ' '

    if (!metricUnit) return value.toFixed(metricPrecisionToUse)

    if (value === null || value === undefined) return ''
    // If metric, retain the old behavior of not showing units per default.
    if (system === UNIT_SYSTEMS.metric.name) {
        return showMetricUnit ? `${value.toFixed(metricPrecisionToUse)}${space}${metricUnit}` : value.toFixed(metricPrecisionToUse)
    }

    const {
        primaryValue,
        secondaryValue,
        primaryUnit,
        secondaryUnit
    } = convertToUnits(value, metricUnit, system, { useSecondaryUnit, convertedPrecision })

    if (primaryValue === null || primaryValue === undefined) return ''

    // Build the formatted string(s)
    const valueString1 = secondaryValue === null ?
        primaryValue?.toFixed(convertedPrecision ?? convertedPrimaryPrecision)
        : Math.floor(primaryValue)

    const unit1 = compact ? (primaryUnit.short || primaryUnit.name) : primaryUnit.name
    const primaryFormattedString = valueString1 + (hideAllUnits ? '' : space + unit1)

    if (secondaryValue !== null) {
        const unit2 = compact ? (secondaryUnit.short || secondaryUnit.name) : secondaryUnit.name
        const secondaryFormattedString = separator + secondaryValue.toFixed(convertedPrecision ?? convertedSecondaryPrecision) + (hideAllUnits ? '' : space + unit2)
        return primaryFormattedString + secondaryFormattedString
    } else {
        return primaryFormattedString
    }
}

/**
 * @typedef {object} formatUnitNameOptions
 * @param {string} [separator] - The number of decimal places for the formatted output.
 * @param {boolean} [useSecondaryUnit] - Whether to use the secondary unit or not.
 */
/**
 * Formats a number and converts it from one unit to another.
 * @param {string} metricUnit - The metric unit string to convert from.
 * @param {string} system - Unit system to convert metric value to
 * @param {formatUnitNameOptions} [options] - Additional options
 * @returns {string} - The formatted string representation of the value.
 */

export const formatUnitName = (metricUnit, system, options) => {

    const { useSecondaryUnit = true, separator = ' & ' } = options ?? {}

    if (!metricUnit ||
        system === UNIT_SYSTEMS.metric.name ||
        !isUnitConvertable(metricUnit, system)
    ) return metricUnit

    const {
        primaryUnit,
        secondaryUnit
    } = convertToUnits(0, metricUnit, system, { useSecondaryUnit })

    const primaryStr = primaryUnit.name
    const secondaryStr = secondaryUnit?.name

    return !!secondaryStr ? primaryStr + separator + secondaryStr : primaryStr
}

/** 
 * @param {string} metricUnit - The unit string
 * @param {string} system - Unit system to convert metric value to
 * @returns {boolean} - Whether a conversion is possible (returns false when system is metric)
 * */

export const isUnitConvertable = (metricUnit, system) => {
    return !!conversionsFromMetric[system]?.[metricUnit]?.primaryUnit
}

/**
 * @param {string} temperatureRangeString - string containing temperature numbers and degrees celcius. Example: "0 - 25 °c"
 * @param {string} system - string containing temperature numbers and degrees celcius. Example: "0 - 25 °c"
 * @returns {string} - string with numbers and units converted
*/

export const formatHeatingEventTemperature = (temperatureRangeString, system) => {

    const celciusConvertedUnitName = formatUnitName('°C', system, { useSecondaryUnit: false })
    const lower = temperatureRangeString.toLowerCase()

    return lower
        .replace('°c', celciusConvertedUnitName)
        .replace(/\b\d+\b/g, (match) => {
            return convertToUnits(
                parseInt(match, 10),
                '°C',
                system,
                {
                    convertedPrecision: 0,
                    useSecondaryUnit: false
                }
            ).primaryValue
        })
}

export const UNIT_SYSTEMS = {
    metric: {
        name: 'metric',
        translationKey: 'metric',
    },
    imperialUs: {
        name: 'imperialUs',
        translationKey: 'imperialUs',
    },
}

/**
 * Conversion factors from metric units to other unit systems.
 * This object maps unit systems to their respective conversion details for various metric units.
 * Each unit system contains a dynamic set of units, with each unit defining its primary and optional secondary conversions.
 * @type {Object<string, UnitSystemConversions>}
 */
/**
 * Represents the conversion details for a unit system.
 * @typedef {Object<string, UnitConversionDetails>} UnitSystemConversions
 * A dynamic object where the keys are metric unit names (e.g., 'm', 'cm', 'kg', 'L'),
 * and the values are their respective conversion details.
 */
/**
 * Represents the conversion details for a single unit.
 * @typedef {Object} UnitConversionDetails
 * @property {PrimaryUnitDetails} primaryUnit - The primary unit and its conversion details.
 * @property {SecondaryUnitDetails} [secondaryUnit] - The secondary unit and its conversion details (if applicable).
 */
/**
 * Represents the details of a single unit.
 * @typedef {Object} PrimaryUnitDetails
 * @property {string} name - The name of the unit (e.g., 'ft', 'in', 'gal').
 * @property {string} [short] - The short form of the unit (e.g., "'", '"').
 * @property {function(number): number} toUnit - The conversion function from metric to the primary unit
 * @property {function(number): number} toMetric - The conversion function from the unit to metric
 */
/**
 * Represents the details of a single unit.
 * @typedef {Object} SecondaryUnitDetails
 * @property {string} name - The name of the unit (e.g., 'ft', 'in', 'gal').
 * @property {string} [short] - The short form of the unit (e.g., "'", '"').
 * @property {function(number): number} toUnitFromPrimary - The conversion function from primary unit to the secondary unit
 * @property {function(number): number} toPrimaryFromUnit - The conversion function from secondary unit to the primary unit
 */
// exported for testing purposes only
export const conversionsFromMetric = {
    [UNIT_SYSTEMS.imperialUs.name]: {
        m: {
            primaryUnit: {
                name: 'ft',
                short: "'",
                toUnit: (x) => x * 3.2808398,
                toMetric: (x) => x / 3.2808398,
                convertedPrecision: 1,
            },
            secondaryUnit: {
                name: 'in',
                short: '"',
                toUnitFromPrimary: (x) => x * 12,
                toPrimaryFromUnit: (x) => x / 12,
                convertedPrecision: 0,
            },
            metricPrecision: 1,
        },
        cm: {
            primaryUnit: {
                name: 'in',
                short: '"',
                toUnit: (x) => x * 0.393701,
                toMetric: (x) => x / 0.393701,
                convertedPrecision: 2,
            },
            metricPrecision: 1,
        },
        mm: {
            primaryUnit: {
                name: 'in',
                short: '"',
                toUnit: (x) => x * 0.0393701,
                toMetric: (x) => x / 0.0393701,
                convertedPrecision: 3,
            },
            metricPrecision: 1,
        },
        kg: {
            primaryUnit: {
                name: 'lb',
                toUnit: (x) => x * 2.204623,
                toMetric: (x) => x / 2.204623,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        L: {
            primaryUnit: {
                name: 'gal',
                toUnit: (x) => x * 0.264172,
                toMetric: (x) => x / 0.264172,
                convertedPrecision: 1,
            },
            metricPrecision: 0,
        },
        '%': {
            primaryUnit: {
                name: '%',
                toUnit: (x) => x,
                toMetric: (x) => x,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        '°C': {
            primaryUnit: {
                name: '°F',
                short: '°',
                toUnit: (x) => (x * (9 / 5) + 32),
                toMetric: (x) => ((x - 32) * 5 / 9),
                convertedPrecision: 1,
            },
            metricPrecision: 1,
        },
        '° c': {
            primaryUnit: {
                name: '°F',
                short: '°',
                toUnit: (x) => (x * (9 / 5) + 32),
                toMetric: (x) => ((x - 32) * 5 / 9),
                convertedPrecision: 1,
            },
            metricPrecision: 1,
        },
        '°C GDD': {
            primaryUnit: {
                name: '°F GDD',
                short: '°',
                toUnit: (x) => x * (9 / 5),
                toMetric: (x) => x * (5 / 9),
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'm/s': {
            primaryUnit: {
                name: 'ft/s',
                toUnit: (x) => (x * 3.28084),
                toMetric: (x) => (x / 3.28084),
                convertedPrecision: 1,
            },
            metricPrecision: 1,
        },
        'mm/h': {
            primaryUnit: {
                name: 'in/h',
                toUnit: (x) => (x * 0.0393701),
                toMetric: (x) => (x / 0.0393701),
                convertedPrecision: 1,
            },
            metricPrecision: 0,
        },
        mbar: {
            primaryUnit: {
                name: 'inHg',
                toUnit: (x) => (x * 0.0295333727),
                toMetric: (x) => (x / 0.0295333727),
                convertedPrecision: 1,
            },
            metricPrecision: 0,
        },
        mb: {
            primaryUnit: {
                name: 'inHg',
                toUnit: (x) => (x * 0.0295333727),
                toMetric: (x) => (x / 0.0295333727),
                convertedPrecision: 1,
            },
            metricPrecision: 0,
        },
        'W/m²': {
            primaryUnit: {
                name: 'BTU/ft²·hr',
                toUnit: (x) => (x * 0.317),
                toMetric: (x) => (x / 0.317),
                convertedPrecision: 1,
            },
            metricPrecision: 1,
        },
        // backend values
        'dam': {
            primaryUnit: {
                name: 'ft',
                short: "'",
                toUnit: (x) => x * 32.808398,
                toMetric: (x) => x / 32.808398,
                convertedPrecision: 0,
            },
            secondaryUnit: {
                name: 'in',
                short: '"',
                toUnitFromPrimary: (x) => x * 12,
                toPrimaryFromUnit: (x) => x / 12,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'hm': {
            primaryUnit: {
                name: 'ft',
                short: "'",
                toUnit: (x) => x * 328.08398,
                toMetric: (x) => x / 328.08398,
                convertedPrecision: 0,
            },
            secondaryUnit: {
                name: 'in',
                short: '"',
                toUnitFromPrimary: (x) => x * 12,
                toPrimaryFromUnit: (x) => x / 12,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'km': {
            primaryUnit: {
                name: 'ft',
                short: "'",
                toUnit: (x) => x * 3280.8398,
                toMetric: (x) => x / 3280.8398,
                convertedPrecision: 0,
            },
            secondaryUnit: {
                name: 'in',
                short: '"',
                toUnitFromPrimary: (x) => x * 12,
                toPrimaryFromUnit: (x) => x / 12,
                convertedPrecision: 0,
            },
            metricPrecision: 1,
        },
        'µg': {
            primaryUnit: {
                name: 'gr', //grains
                toUnit: (x) => x / 64935.065,
                toMetric: (x) => x * 64935.065,
                convertedPrecision: 5,
            },
            metricPrecision: 0,
        },
        'mg': {
            primaryUnit: {
                name: 'gr', //grains
                toUnit: (x) => x / 64.935065,
                toMetric: (x) => x * 64.935065,
                convertedPrecision: 3,
            },
            metricPrecision: 0,
        },
        'cg': {
            primaryUnit: {
                name: 'gr', //grains
                toUnit: (x) => x / 6.4935065,
                toMetric: (x) => x * 6.4935065,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'dg': {
            primaryUnit: {
                name: 'gr', //grains
                toUnit: (x) => x / 0.64935065,
                toMetric: (x) => x * 0.64935065,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'g': {
            primaryUnit: {
                name: 'oz', //ounces
                toUnit: (x) => x / 28.3495,
                toMetric: (x) => x * 28.3495,
                convertedPrecision: 2,
            },
            metricPrecision: 0,
        },
        'dag': {
            primaryUnit: {
                name: 'oz', //ounces
                toUnit: (x) => x / 2.83495,
                toMetric: (x) => x * 2.83495,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'hg': {
            primaryUnit: {
                name: 'oz', //ounces
                toUnit: (x) => x / 0.283495,
                toMetric: (x) => x * 0.283495,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        't': { // metric tonne
            primaryUnit: {  // short tons
                name: 'short ton',
                toUnit: (x) => x / 0.9071847,
                toMetric: (x) => x * 0.9071847,
                convertedPrecision: 1,
            },
            metricPrecision: 1,
        },
        'K': {
            primaryUnit: {  // Rankine
                name: '°R',
                toUnit: (x) => x * 1.8,
                toMetric: (x) => x / 1.8,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'dS/m': {
            primaryUnit: {  // millimhos per foot
                name: 'mmho/ft',
                toUnit: (x) => x * 0.3048,
                toMetric: (x) => x / 0.3048,
                convertedPrecision: 3,
            },
            metricPrecision: 2,
        },
        'µS/cm': {
            primaryUnit: {  // micromhos per inch
                name: 'µmho/in',
                toUnit: (x) => x * 0.393701,
                toMetric: (x) => x / 0.393701,
                convertedPrecision: 2,
            },
            metricPrecision: 2,
        },
        'Nm': {
            primaryUnit: {  // pound-foot
                name: 'lbft',
                toUnit: (x) => x * 0.73756,
                toMetric: (x) => x / 0.73756,
                convertedPrecision: 1,
            },
            metricPrecision: 1,
        },
        'Nm/°': {
            primaryUnit: {  // pound-foot per degree 
                name: 'lbft/°',
                toUnit: (x) => x * 0.73756,
                toMetric: (x) => x / 0.73756,
                convertedPrecision: 1,
            },
            metricPrecision: 1,
        },
        'Gm': {
            primaryUnit: {  // ft per second squared
                name: 'ft/s²',
                toUnit: (x) => x * 32.174,
                toMetric: (x) => x / 32.174,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
        'bar': {
            primaryUnit: {
                name: 'psi',
                toUnit: (x) => x * 14.5038,
                toMetric: (x) => x / 14.5038,
                convertedPrecision: 0,
            },
            metricPrecision: 1,
        },
        'Pa': {
            primaryUnit: {
                name: 'psf', // pounds per square feet 
                toUnit: (x) => x * 0.020885,
                toMetric: (x) => x / 0.020885,
                convertedPrecision: 2,
            },
            metricPrecision: 0,
        },
        'kPa': {
            primaryUnit: {
                name: 'psf', // pounds per square feet 
                toUnit: (x) => x * 0.2048,
                toMetric: (x) => x / 0.2048,
                convertedPrecision: 1,
            },
            metricPrecision: 0,
        },
        'PSI': {
            primaryUnit: {
                name: 'PSI', // keep the same
                toUnit: (x) => x,
                toMetric: (x) => x,
                convertedPrecision: 0,
            },
            metricPrecision: 0,
        },
    },
}