import React from 'react'
import { colors } from '../../style/vars'

export default function ContainerDisabler({
    disable,
    backgroundColor,
    ...props
}) {


    if (!disable) {
        return null
    }

    return (
        disable
            ? <div
                css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    minWidth: '100%',
                    minHeight: '100%',
                    background: backgroundColor || colors.dark,
                    opacity: .9,
                    zIndex: 1000
                }}
                {...props} />
            : null
    );
}