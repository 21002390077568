import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { colors, timings } from '../../style/vars'
import { FormattedMessage } from 'react-intl'
import useUnit from '../../hooks/useUnit'

const style = (color, bright, textScalingFactor) => css`
    border: 1px solid ${color};
    min-width: 3em;
    aspect-ratio: 1;
    padding: 0 1px;
    border-radius: 50%;
    background-color: transparent;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color, background, ${timings.smooth};
    position: relative;

    span {
        font-size: ${1.2 * textScalingFactor}em;
        position: absolute;
    }
    
    ${bright ? `
        box-shadow: 0 0 2em ${color};
        background-color: ${color};
        color: ${colors.black};
        font-weight: 400;
    ` : ``}
`

export default function ScoreIndicator({
    value,
    decimals = 1,
    color = colors.main1,
    bright = false,
    metricUnit,
    ...props
}) {
    const { formatUnit, formatUnitName } = useUnit()

    const { convertedUnit, textScalingFactor } = useMemo(() => {
        let convertedUnit = typeof value === 'number' ?
            formatUnit(value, metricUnit, {
                metricPrecision: decimals,
                hideAllUnits: true,
            })
            : undefined

        const defaultLength = 4
        const strLength = convertedUnit?.length ?? defaultLength
        const ratio = defaultLength / strLength
        const textScalingFactor = (ratio > 1 || !convertedUnit) ? 1 : ratio

        return { convertedUnit, textScalingFactor }
    }, [value, metricUnit, formatUnit])

    // if value is null, override color to something gray
    if (value === null) {
        return (
            <div css={style(colors.liquid, bright, 1)} {...props}>
                <span style={{ opacity: 0.66, fontSize: '1em' }}>
                    <FormattedMessage id='notApplicableAbbr' />
                </span>
            </div>
        )
    }
    return (
        <div
            css={style(color, bright, textScalingFactor)}
            title={formatUnitName(metricUnit)}
            {...props}
        >
            <span >
                {convertedUnit}
            </span>
        </div>
    )
}