import { css } from '@emotion/react'
import { endOfDay, getUnixTime, startOfDay } from "date-fns"
import React, { useMemo } from "react"
import { NavLink } from "react-router"
import useRawApiState from "../../hooks/useRawApiState"
import { colors } from "../../style/vars"
import EventTypeIcon from "../events/EventTypeIcon"
import Loader from "../_general/Loader"
import { useIntl } from 'react-intl'
import { fromZonedTime } from 'date-fns-tz'

export default function TodaysEventsSection({ pitch, venue, ...props }) {

    const [fromUnix, toUnix] = useMemo(() => {
        const timeZone = venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        // pitch's "current day"
        return [
            getUnixTime(fromZonedTime(startOfDay(new Date()), timeZone)),
            getUnixTime(fromZonedTime(endOfDay(new Date()), timeZone))
        ]
    }, [venue])

    const { data: events, isBusy: eventsBusy } = useRawApiState(
        `/api/current/pitches/${pitch?.id}/events`,
        { from: fromUnix, to: toUnix, },
        [pitch?.id, fromUnix, toUnix]
    )

    const todaysEvents = useMemo(() => {
        if (!(events?.length > 0)) return
        const typeCounts = {}
        for (const event of events) {
            typeCounts[event.type] === undefined ? typeCounts[event.type] = 1 : typeCounts[event.type]++
        }
        return typeCounts
    }, [events])

    const { formatMessage, } = useIntl()

    return (
        <>
            <span css={css`color: ${colors.light}`}>
                {`${formatMessage({ id: 'todaysEvents' })}: `} {events?.length === 0 && formatMessage({ id: 'none' })}
            </span>

            {(eventsBusy || !pitch) ? <Loader size={'25px'} /> : todaysEvents &&
                <NavLink to={pitch && `/events?pitch=${pitch.id}`}>
                    <div css={css`display: flex; gap: 0.5em; flex-wrap: wrap;`}>
                        {Object.entries(todaysEvents).map(([type, count]) => (
                            <EventTypeIcon
                                key={type}
                                eventType={type}
                                counter={(count >= 2) ? count : null}
                                title={type.replace('Event', '')}
                            />
                        ))}
                    </div>
                </NavLink>
            }
        </>
    )
}
