import { css } from "@emotion/react"
import PageTitle from "../../_general/PageTitle"
import { colors } from "../../../style/vars"
import useRawApiState from "../../../hooks/useRawApiState"
import { useCallback, useContext, useEffect, useMemo } from "react"
import useClubVenuePitches from "../../../hooks/useClubVenuePitches"
import FancyDropdown from "../../_control/FancyDropdown"
import { useSearchParams } from "react-router"
import Loader from "../../_general/Loader"
import PageSubtitle from "../../_general/PageSubtitle"
import { FormattedMessage } from "react-intl"
import PitchTelemetryCard from "./PitchTelemetryCard"
import { PitchTelemetryProvider } from "../../../context/covermaster/PitchTelemetry"
import CovermasterContext from "../../../context/covermaster/Covermaster"
import IntegrationsContext from "../../../context/Integrations"
import { useNavigate } from "react-router"

export default function CovermasterPitches({ ...props }) {

    // hacky attempt at protecting routes without messing with the router
    /* const navigate = useNavigate()
    const { integrations } = useContext(IntegrationsContext)
    useEffect(() => {
        if (!integrations.includes('cover-master')) navigate('/')
    }, [integrations, navigate]) */

    const [searchParams, setSearchParams] = useSearchParams()

    //// CLUBS
    const { data: clubs, isBusy: clubsBusy } = useRawApiState('/api/current/clubs?pagination=false')

    const clubOptions = useMemo(() => {
        return clubs?.map(club => ({
            key: club.id,
            value: club.id,
            label: club.name
        })) ?? []
    }, [clubs])

    const selectedClub = useMemo(() => {
        return Number(searchParams.get('club') ?? null)
    }, [searchParams])

    const handleChangeClub = useCallback((clubId) => {
        setSearchParams(old => {
            clubId ? old.set('club', clubId) : old.delete('club')
            return old
        })
    }, [setSearchParams])

    useEffect(() => {
        if (clubs?.length === 1) {
            handleChangeClub(clubs[0].id)
        }
    }, [clubs, handleChangeClub])

    //// DEVICES
    const { devices, isBusy: covermasterBusy } = useContext(CovermasterContext)

    //// venuepitches
    const { venuePitches, isBusy: venuePitchesBusy } = useClubVenuePitches()

    const relevantVenuePitches = useMemo(() => {
        if (!venuePitches) return []
        const pitchIds = []
        for (const dev of devices) {
            const id = dev.latestDevicePlacementEvent?.pitch?.id ?? null
            if (!pitchIds.includes(id)) pitchIds.push(id)
        }

        // filter out pitches without sensors
        const filteredVenuePitches = []
        for (const venue of venuePitches) {
            const filtered = venue.pitches.filter(p => pitchIds.includes(p.id))

            // add venue with filterd pitches if at least one pitch uses devices
            if (filtered.length) {
                filteredVenuePitches.push({
                    ...venue,
                    pitches: filtered,
                })
            }
        }

        return filteredVenuePitches
    }, [devices, venuePitches])

    const pitchesLoading = useMemo(() => {
        return venuePitchesBusy && selectedClub
    }, [venuePitchesBusy, selectedClub])

    return <div css={style.pageWrapper} {...props}>
        <PageTitle >
            <span css={{ color: colors.main1 }}>
                Covermaster
            </span>
        </PageTitle>

        <div css={style.subTitle}>
            Sensor view {/* TO DO: translate this */}
        </div>

        {clubsBusy ? <Loader size={'2em'} css={css`margin-top: 1em`} /> : <FancyDropdown
            value={clubOptions.find(club => club.value === selectedClub) ?? null}
            options={clubOptions}
            onChange={(club) => { handleChangeClub(club.value) }}
            placeholder={'Select club'}/* TO DO: translate this  */
            css={style.dropdown}
        />}

        {(pitchesLoading || covermasterBusy) ? (clubsBusy ? null : <Loader size={'2em'} css={css`margin-top: 1em`} />) :
            relevantVenuePitches.map(vP => {
                return <div css={style.venueWrapper} key={vP.venue?.id}>

                    <PageSubtitle>
                        {vP.venueName || <FormattedMessage id='unknownVenue' />}
                    </PageSubtitle>

                    <div css={style.pitchesGrid}>
                        {vP.pitches?.map(pitch =>
                            <PitchTelemetryProvider pitch={pitch} timeZone={vP.venue?.timeZone}>
                                <PitchTelemetryCard key={pitch.id} />
                            </PitchTelemetryProvider>
                        )}
                    </div>

                </div>
            })}
    </div>
}

const style = {
    pageWrapper: css`
        width: 100%; 
        display: grid; 
        grid-template-rows: min-content; 
        grid-auto-rows: min-content;
    `,
    subTitle: css`
        color: ${colors.white};
        font-size: 1.7em;
    `,
    venueWrapper: css`
        margin-top: 1.5em;
    `,
    pitchesGrid: css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;

        @media screen and (max-width: 1600px) {
            grid-template-columns: 1fr;
        }
    `,
    dropdown: css`
        margin-top: 1em;
        width: 16em;
        z-index: 2000;
    `,
}