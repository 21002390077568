import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import log from 'loglevel'
import LocalStorage from '../services/LocalStorage'
import Raw from '../services/Raw'


const AuthContext = createContext()
export default AuthContext

export function AuthProvider(props) {
    // state
    const [busy, setBusy] = useState(false)
    const [{
        jwt,
        email,
        rememberedEmail,
        loginType,
        stickySession,
    }, dispatch] = useReducer(authReducer, getInitialAuthState())

    // LOGIN
    const logIn = useCallback((email, password, remember, handleError) => {
        setBusy(true)
        Raw.getJwt(email, password)
            .then((jwt) => {
                dispatch({
                    type: 'login',
                    payload: {
                        email,
                        jwt,
                        remember,
                        loginType: 'email',
                    },
                })
            })
            .catch((e) => {
                handleError(e.status)
            })
            .finally(() => {
                setBusy(false)
            })
    })

    const getSsoRedirect = useCallback(async (email, remember, handleError) => {
        setBusy(true)

        try {
            const response = await Raw.fetchJson('POST', '/api/sso/login', {
                body: {
                    email,
                },
            })

            dispatch({
                type: 'manage-remembered-email',
                payload: {
                    email,
                    remember,
                    loginType: 'sso',
                },
            })

            // redirect to link
            if (response.redirectUrl) window.location.href = response.redirectUrl

        } catch (e) {
            handleError(e.status)
        }
        setBusy(false)
    })

    const logInSso = useCallback((code, handleError) => {
        setBusy(true)

        Raw.getJwtViaSso(code)
            .then((jwt) => {
                dispatch({
                    type: 'login',
                    payload: {
                        email: rememberedEmail ? rememberedEmail : undefined,
                        jwt,
                        remember: !!rememberedEmail,
                        loginType: 'sso',
                    },
                })
            })
            .catch((e) => {
                handleError(e.status)
            })
            .finally(() => {
                setBusy(false)
            })
    }, [email, rememberedEmail])

    // LOGOUT
    const logOut = useCallback(() => {
        dispatch({
            type: 'logout',
        })
    })

    // REFRESH TOKEN
    useEffect(() => {
        // refresh token if we already have one and it's about to expire
        if (jwt) {
            try {
                const { exp } = jwtDecode(jwt)

                // if token is expired, log out
                if (exp * 1000 <= Date.now()) {
                    log.warn('AUTH: current jwt is expired, logging out...')
                    logOut()
                    return
                }

                // we want to refresh a jwt when it is 5 mins before expiring or later
                const shouldRefreshAt = exp * 1000 - (1000 * 60 * 5)

                // if shouldRefreshAt is in the past, try to refresh anyway
                const msUntilRefresh = Math.max(shouldRefreshAt - Date.now(), 0)

                const refreshTimeout = setTimeout(() => {

                    if (stickySession) {
                        log.warn('AUTH: jwt is about to expire, attempting to get a new one... (sticky session)')
                        Raw.getRefreshedJwt(jwt)
                            .then((newJwt) => {
                                log.info('AUTH: refreshed jwt')
                                dispatch({
                                    type: 'refresh-jwt',
                                    payload: {
                                        jwt: newJwt,
                                    },
                                })
                            })
                            .catch(() => {
                                log.warn('AUTH: current jwt is not valid anymore, could not refresh auth')
                                logOut()
                            })
                    } else {
                        log.warn('AUTH: jwt is about to expire, logging out of non-sticky session')
                        logOut()
                    }
                }, msUntilRefresh)

                return () => {
                    clearTimeout(refreshTimeout)
                }
            } catch (e) {
                log.error(e)
                logOut()
            }
        }
    }, [jwt, stickySession])

    return (
        <AuthContext.Provider
            value={{
                hasAuth: !!jwt,
                jwt,
                email,
                rememberedEmail,
                loginType,
                busy,
                logIn,
                getSsoRedirect,
                logInSso,
                logOut,
            }}
            {...props}
        />
    )
}


const authLocalStorageKey = 'auth'

function getInitialAuthState() {
    return LocalStorage.get(authLocalStorageKey) || {}
}

// every time this reducer runs (aka: auth state changes),
// we write to localStorage, this way it'll _always_ be in sync with in-mem state
function authReducer(state, { type, payload }) {
    function calcState() {
        switch (type) {
            case 'login':
                return {
                    jwt: payload.jwt,
                    email: payload.email,
                    rememberedEmail: payload.remember ? payload.email : undefined,
                    loginType: payload.loginType,
                    stickySession: payload.remember ? 'true' : '',
                }
            case 'logout':
                return {
                    rememberedEmail: state.rememberedEmail,
                    loginType: state.loginType,
                    stickySession: '',
                }
            case 'refresh-jwt':
                return {
                    ...state,
                    jwt: payload.jwt,
                }
            case 'manage-remembered-email':
                return {
                    rememberedEmail: payload.remember ? payload.email : undefined,
                    loginType: payload.loginType,
                    stickySession: payload.remember ? 'true' : '',
                }
            default:
                throw new Error('invalid action')
        }
    }
    const newState = calcState()
    LocalStorage.set(authLocalStorageKey, newState)
    return newState
}
