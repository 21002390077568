import React, { useMemo, useEffect } from 'react'
import useWeatherFlowState from '../../hooks/useWeatherFlowState'
import { backgrounds, colors } from '../../style/vars'
import Loader from '../_general/Loader'
import { getConditionIcon, conditionStringsMap } from '../../utils/weatherUtils'
import { format, fromUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import { ReactSVG } from 'react-svg'

import pressureIcon from '@assets/icons/weather/pressure.svg'
import brightnessIcon from '@assets/icons/weather/brightness.svg'
import humidityIcon from '@assets/icons/weather/humidity.svg'
import windIcon from '@assets/icons/weather/wind.svg'
import rainIcon from '@assets/icons/weather/rain.svg'
import sunIcon from '@assets/icons/weather/sun.svg'
import sunsetIcon from '@assets/icons/weather/sunset.svg'

import { MTM_EVENT_TYPES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { FormattedMessage } from 'react-intl'
import useUnit from '../../hooks/useUnit'

export default function LiveWeather({
    stationId,
    ...props
}) {
    const track = useTracker()

    const { data, isBusy, error } = useWeatherFlowState(
        `/better_forecast`,
        {
            station_id: stationId,
            units_wind: 'mps',
        },
        [stationId])

    const conditions = useMemo(() => (data?.current_conditions ?? {}), [data?.current_conditions])
    const forecast = useMemo(() => (data?.forecast?.daily?.[0] ?? {}), [data?.forecast])

    const { units_temp,
        units_wind,
        units_precip,
        units_pressure,
        units_distance,
        ...units } = useMemo(() => (
            data?.units ?? {}
        ), [data])

    useEffect(() => {
        if (!!data) {
            track({
                'event': MTM_EVENT_TYPES['view-tempest-data']
            })
        }
    }, [data])

    const { formatUnit } = useUnit()

    return (
        <div css={style.wrapperBackground}>
            {isBusy ?
                <div css={css`
                    display: grid;
                    place-items: center;
                `}>
                    <div css={css`display: grid; place-items: center;`}>
                        <span css={{ fontSize: '2em' }}>
                            <FormattedMessage id='retrievingCurrentWeather' />
                        </span>
                        <Loader size="6em" />
                    </div>
                </div>
                :
                <div css={style.resultsGrid}>
                    <div>
                        <div css={css`font-size: 2em;`}>
                            {formatUnit(conditions?.air_temperature, '°C', { showMetricUnit: true, compact: false })}
                        </div>
                        <ReactSVG src={getConditionIcon(conditions.conditions)} css={css`width: 4em;`} className='svgException' />
                        <span>
                            <FormattedMessage id={conditionStringsMap[conditions.conditions]} />
                        </span>
                    </div>
                    <div className='gridException' css={css`
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 0.5em;

                        > div {
                            justify-self: flex-end;
                        } 
                        
                        > div > span {
                            margin-left: 0.5em;
                        }
                    `}>
                        <div>
                            <FormattedMessage id='feelsLike' />
                            <span>{formatUnit(conditions?.feels_like, '°C', { showMetricUnit: true, compact: false })}</span>
                        </div>
                        <div>
                            <FormattedMessage id='dewpoint' />
                            <span>{formatUnit(conditions?.dew_point, '°C', { showMetricUnit: true, compact: false })}</span>
                        </div>
                        <div>
                            <FormattedMessage id='wetBulb' />
                            <span>{formatUnit(conditions?.wet_bulb_temperature, '°C', { showMetricUnit: true, compact: false })}</span>
                        </div>
                        <div>
                            <FormattedMessage id='deltaT' />
                            <span>{formatUnit(conditions?.delta_t, '°C', { showMetricUnit: true, compact: false })}</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={rainIcon} css={style.smallIcon} />
                            <FormattedMessage id='rainfall' />
                        </div>
                        <span>
                            {conditions?.precip_accum_local_day &&
                                formatUnit(conditions?.precip_accum_local_day, 'mm', { showMetricUnit: true, compact: false, metricPrecision: 3 })
                            }
                        </span>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={humidityIcon} css={style.smallIcon} />
                            <FormattedMessage id='humidity' />
                        </div>
                        <span> {conditions?.relative_humidity && `${conditions.relative_humidity}%`}</span>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={windIcon} css={style.smallIcon} />
                            <FormattedMessage id='windSpeed' />

                        </div>
                        {conditions?.wind_direction !== undefined &&
                            <span css={css`transform: rotate(${conditions?.wind_direction}deg)`}>&darr;</span>
                        }
                        <span>{conditions?.wind_avg && formatUnit(conditions?.wind_avg, 'm/s', { showMetricUnit: true, compact: false })}</span>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={windIcon} css={style.smallIcon} />
                            <FormattedMessage id='windGust' />
                        </div>
                        <span> {conditions?.wind_gust && formatUnit(conditions?.wind_gust, 'm/s', { showMetricUnit: true, compact: false })} </span>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={brightnessIcon} css={style.smallIcon} />
                            <FormattedMessage id='brightness' />
                        </div>
                        <span> {conditions?.brightness && `${conditions?.brightness} lux`}</span>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={pressureIcon} css={style.smallIcon} />
                            <FormattedMessage id='pressure' />
                        </div>
                        <span> {conditions?.station_pressure && `${conditions?.station_pressure} ${units_pressure}`}</span>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={sunIcon} css={style.smallIcon} />
                            <FormattedMessage id='sunrise' />
                        </div>
                        <span> {forecast?.sunrise ? format(fromUnixTime(forecast?.sunrise), `HH:mm`) : null}</span>
                    </div>
                    <div>
                        <div>
                            <ReactSVG src={sunsetIcon} css={style.smallIcon} />
                            <FormattedMessage id='sunset' />
                        </div>
                        <span> {forecast?.sunset ? format(fromUnixTime(forecast?.sunset), `HH:mm`) : null} </span>
                    </div>
                </div>
            }
        </div>

    )
}

const style = {
    wrapperBackground: css`
        background: ${backgrounds.weatherGradient};
        border-radius: 0.5em;
        border: 2px solid ${colors.solid};
        display: grid;
        width: 100%;
        height: 100%;
    `,
    resultsGrid: css`
        display: grid;
        justify-self: center;
        grid-template-columns: 1fr 1fr;
        padding: 2em;
        width: min(65em, 100%);
        grid-gap: 1em;

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        
        > div {
            background: ${colors.eventDark};
            border-radius: 5px;
            font-size: 1.2em;
            align-items: center;
            padding: 0.2em 2em;
            
            > div:not(.svgException) {
                display: flex;
                align-items: center;
            }
        }

        > div:not(.gridException) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        span {
            font-size: 1.3em;
        }
    `,
    smallIcon: css`
        width: 2em;
        margin-right: 1em;
    `
}