import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { mix, rgba } from 'polished'
import { colors, backendColors } from '../../style/vars'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useIntl } from 'react-intl'
import useUnit from '../../hooks/useUnit'

export default function MeasurementPicker({
    metricUnit,
    value = 0,
    onChange,
    decimals = 0,  // metric unit's decimals
    min = 0,
    max = 100,
    disabled = false,
    ...props
}) {

    const { formatMessage } = useIntl()

    const { convertToUnits, convertToMetric } = useUnit()

    // this memo is only used to set initial slider values
    const { primaryValue, primaryUnit, secondaryValue, secondaryUnit, secondaryUnitMax, converted } = useMemo(() => {

        const convertedValues = convertToUnits(value, metricUnit)
        const secondaryUnitMax = convertedValues.secondaryUnit?.toUnitFromPrimary(1)
        return {
            ...convertedValues,
            secondaryUnitMax,
        }
    }, [convertToUnits, value, metricUnit])

    const { primaryDecimals, secondaryDecimals, primaryStep, secondaryStep, primaryHelperButtonSteps, secondaryHelperButtonSteps } = useMemo(() => {
        const hasSecondaryUnit = converted ? !!secondaryUnit : false

        const getStepFromDecimals = (dec) => 1 / (10 ** dec)

        const getHelperButtonStepsFromDecimals = (dec) => {
            const helperButtonSteps = []
            for (let i = 0; i <= dec; i++) {
                helperButtonSteps.push(1 / (10 ** i))
            }
            return helperButtonSteps
        }

        return {
            primaryDecimals: hasSecondaryUnit ? 0 : (converted ? primaryUnit.convertedPrecision : decimals),
            secondaryDecimals: hasSecondaryUnit ? secondaryUnit.convertedPrecision : undefined,
            primaryStep: hasSecondaryUnit ? 1 : getStepFromDecimals(converted ? primaryUnit.convertedPrecision : decimals),
            secondaryStep: hasSecondaryUnit ? getStepFromDecimals(secondaryUnit.convertedPrecision) : undefined,
            primaryHelperButtonSteps: hasSecondaryUnit ? [1] : getHelperButtonStepsFromDecimals(converted ? primaryUnit.convertedPrecision : decimals),
            secondaryHelperButtonSteps: hasSecondaryUnit ? getHelperButtonStepsFromDecimals(secondaryUnit.convertedPrecision) : undefined,
        }
    }, [decimals, primaryUnit, secondaryUnit, converted])

    // PRIMARY SLIDER
    const [slider1Val, setSlider1Val] = useState(primaryValue)

    const handleSlider1Change = useCallback((value) => {
        if (!disabled) setSlider1Val(value)
    }, [disabled, setSlider1Val])

    const clearSlider1 = useCallback(() => {
        if (!disabled) setSlider1Val(null)
    }, [disabled, setSlider1Val])

    const tweakSlider1 = useCallback((amount) => {
        if (!disabled) {
            let newVal = slider1Val + amount
            newVal = Math.max(newVal, convertToUnits(min, metricUnit, { useSecondaryUnit: false }).primaryValue)
            newVal = Math.min(newVal, convertToUnits(max, metricUnit, { useSecondaryUnit: false }).primaryValue)
            setSlider1Val(newVal)
        }
    }, [min, max, slider1Val, disabled, setSlider1Val, convertToUnits, metricUnit])

    const { convertedMin, convertedMax } = useMemo(() => {
        return converted ? {
            convertedMin: convertToUnits(min, metricUnit, { useSecondaryUnit: false }).primaryValue,
            convertedMax: convertToUnits(max, metricUnit, { useSecondaryUnit: false }).primaryValue,
        } : { convertedMin: min, convertedMax: max }
    }, [min, max, convertToUnits, metricUnit, converted])

    // SECONDARY SLIDER
    const [slider2Val, setSlider2Val] = useState(secondaryValue)

    const handleSlider2Change = useCallback((value) => {
        if (!disabled) setSlider2Val(value)
    }, [disabled, setSlider2Val])

    const clearSlider2 = useCallback(() => {
        if (!disabled) setSlider2Val(null)
    }, [disabled, setSlider2Val])

    const tweakSlider2 = useCallback((amount) => {
        if (!disabled) {
            let newVal = slider2Val + amount
            newVal = Math.max(newVal, 0)
            newVal = Math.min(newVal, secondaryUnitMax)
            setSlider2Val(newVal)
        }
    }, [slider2Val, disabled, setSlider2Val, secondaryUnitMax])

    const debounceTimeout = useRef()

    // use effect version with debounce
    useEffect(() => {
        // Clear the previous timeout if dependencies change
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current)

        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            if (converted) {
                onChange(convertToMetric(slider1Val, slider2Val, metricUnit).metricValue)
            } else {
                onChange(slider1Val)
            }
        }, 70)

        // Cleanup timeout when the component unmounts
        return () => { clearTimeout(debounceTimeout.current) }
    }, [slider1Val, slider2Val, metricUnit, onChange, convertToMetric, converted])

    return (

        <div css={css`display: grid; gap: 2em;`} {...props}>

            {/* SLIDER 1 (primary unit) */}
            <div css={style.cont(disabled)} >
                <div css={style.value}>
                    {typeof slider1Val === 'number'
                        ? slider1Val.toFixed(primaryDecimals) + ` ${primaryUnit?.name ?? ''}`
                        : primaryUnit?.name ?? formatMessage({ id: 'noValue' })
                    }
                </div>

                <div css={style.labeledSliderWrapper} >
                    <div css={style.range}>
                        {!!secondaryUnit ? Math.ceil(convertedMin) : convertedMin.toFixed(primaryDecimals)}
                    </div>
                    <Slider
                        value={slider1Val}
                        step={primaryStep}
                        min={!!secondaryUnit ? Math.ceil(convertedMin) : convertedMin}
                        max={convertedMax}
                        onChange={(val) => handleSlider1Change(val)}
                        css={style.sliderStyle(slider1Val === null)}
                    />
                    <div css={style.range}>
                        {convertedMax.toFixed(primaryDecimals)}
                    </div>
                </div>

                {primaryHelperButtonSteps.length > 0 &&
                    <div css={style.btns}>
                        <button type='button' onClick={clearSlider1} css={style.clearBtn}>
                            {formatMessage({ id: 'clearDataGeneric' })}
                        </button>
                        {primaryHelperButtonSteps.map((helperButtonStep) => {
                            return <div css={css`display: flex; align-items: stretch; flex-grow: 1;`} key={helperButtonStep}>
                                <button
                                    css={style.tweakBtn}
                                    className='negative'
                                    type='button'
                                    disabled={value <= min || disabled}
                                    onClick={() => { tweakSlider1(-(helperButtonStep)) }}
                                >
                                    <span>&minus;&nbsp;</span>
                                    {helperButtonStep}
                                </button>
                                <button
                                    css={style.tweakBtn}
                                    type='button'
                                    disabled={value >= max || disabled}
                                    onClick={() => { tweakSlider1((helperButtonStep)) }}
                                >
                                    <span>+&nbsp;</span>
                                    {helperButtonStep}
                                </button>
                            </div>
                        })}
                    </div>
                }
            </div>

            {/* SLIDER 2 (secondary unit) */}
            {secondaryUnit &&
                <div css={style.cont(disabled)} >
                    <div css={style.value}>
                        {typeof slider2Val === 'number'
                            ? slider2Val.toFixed(secondaryDecimals) + ` ${secondaryUnit?.name ?? ''}`
                            : secondaryUnit?.name ?? formatMessage({ id: 'noValue' })
                        }
                    </div>
                    <div css={style.labeledSliderWrapper}>
                        <div css={style.range}>
                            0
                        </div>
                        <Slider
                            value={slider2Val}
                            step={secondaryStep}
                            min={0}
                            max={secondaryUnitMax}
                            onChange={(val) => handleSlider2Change(val)}
                            css={style.sliderStyle(slider2Val === null)}
                        />
                        <div css={style.range}>
                            {secondaryUnitMax.toFixed(secondaryDecimals)}
                        </div>
                    </div>

                    {secondaryHelperButtonSteps.length > 0 &&
                        <div css={style.btns}>
                            <button type='button' onClick={clearSlider2} css={style.clearBtn}>
                                {formatMessage({ id: 'clearDataGeneric' })}
                            </button>
                            {secondaryHelperButtonSteps.map(helperButtonStep => (
                                <div css={css`display: flex; align-items: stretch; flex-grow: 1;`} key={helperButtonStep}>
                                    <button
                                        css={style.tweakBtn}
                                        className='negative'
                                        type='button'
                                        disabled={value <= min || disabled}
                                        onClick={() => { tweakSlider2(-helperButtonStep) }}
                                    >
                                        <span>&minus;&nbsp;</span>
                                        {helperButtonStep}
                                    </button>
                                    <button
                                        css={style.tweakBtn}
                                        type='button'
                                        disabled={value >= max || disabled}
                                        onClick={() => { tweakSlider2(helperButtonStep) }}
                                    >
                                        <span>+&nbsp;</span>
                                        {helperButtonStep}
                                    </button>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

const style = {
    cont: (disabled) => css`
        display: flex;
        align-items: center;

        @media screen and (max-width: 600px) {
            display: grid;
            grid-template: auto auto / auto 1fr auto;
            grid-template-areas: 
                "value value increments"
                "track track track";
        }

        touch-action: none;
        user-select: none;
        background-color: ${rgba(colors.subtle, 0.1)};
        border-radius: 5px;
        ${disabled ? 'opacity: 0.5;' : ''}
        transition: opacity 200ms ease;
    `,
    value: css`
        grid-area: value;
        height: 100%;
        display: grid;
        place-items: center;
        min-width: 6em;
        border-right: 2px solid ${rgba(colors.subtle, 0.25)};
        padding: 0.25em;
        font-size: 1.75em;
        border-top-left-radius: 5px;
        text-align: center;

        @media screen and (max-width: 600px) {
            border: none;
            background: ${colors.dark};
        }
    `,
    labeledSliderWrapper: css`
        grid-area: track;
        min-height: 1.5em;
        display: grid;
        grid-template: 1fr / auto 1fr auto;
        padding: 2.5em 2em;
        width: 100%;
        gap: 2em;

        > .slider {
            border-radius: 3px;
            background: ${colors.soft};
        }
    `,
    btns: css`
        grid-area: increments;
        display: grid;
        padding: 0.1em;
    `,
    tweakBtn: css`
        touch-action: none;
        margin: 0.125em;
        min-height: 2.5em;
        flex-grow: 1;
        border: none;
        outline: none;
        font-weight: 600;
        color: ${colors.black};
        border-radius: 0.25em;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: all 200ms ease;
        line-height: 0;
        span {
            font-size: 1.50em;
            font-weight: 300;
            width: 1em;
        }

        background-color: ${rgba(mix(0.5, backendColors.good, colors.subtle), 0.75)};
        &:hover {
            transition: all 50ms ease;
            background-color: ${mix(0.8, backendColors.good, colors.subtle)};
        }

        &.negative {
            background-color: ${rgba(mix(0.5, backendColors.bad, colors.subtle), 0.75)};
            &:hover {
                background-color: ${mix(0.8, backendColors.bad, colors.subtle)};
            }
        }
    `,
    sliderStyle: (hide) => css`
        background: ${colors.sublimate};

        .rc-slider-rail, .rc-slider-track {
            display: none;
        }

        .rc-slider-handle {
            ${hide ? 'visibility: hidden;' : ''}
            border-color: ${colors.main1} !important;
            background: ${colors.main1} !important;
            height: 5em;
            border-radius: 0;
            height: 600%;
            width: 2em;
            transform: translate(-50%, -40%) !important;
            opacity: 1 !important;
            border-radius: 3px;
            border: 4px solid ${colors.eventDark} !important;
        }

        .rc-slider-handle-dragging {
            border-color: ${colors.main1} !important;
            background: ${colors.main1} !important;
            box-shadow: unset !important; 
        }
    `,
    clearBtn: css`
        margin: 0.125em;
        margin-bottom: 0.75em;
        font-size: 1em;
        border-radius: 0.125em;
        text-transform: uppercase;
        background-color: ${colors.black};
        color: ${colors.white};
        border: none;
        height: 2em;
        cursor: pointer;
        outline: none;
        opacity: 0.45;
        transition: all 200ms ease;
        &:hover {
            opacity: 0.65;
        }
    `,
    range: css`
        opacity: 0.5;
    `,
}