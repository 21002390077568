import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { colors } from '../../style/vars'
import { scaleLinear } from 'd3'

// uses D3 for the math-y stuff, react for rendering
export default function BarGraph({
    dataSet,
    overlay,
    dataRender,
    graphStyle,
    pixelWidthPerDay = 48,
    ...props
}) {
    const wrap = useRef()
    const dragLoc = useRef()
    const dragDistance = useRef()

    // initially calculate dimensions and on resize update
    const [dim, setDim] = useState({ w: 0, h: 0 })
    useLayoutEffect(() => {
        function recalcDims() {
            if (wrap.current) {
                const { width, height } = wrap.current.getBoundingClientRect()
                setDim({
                    w: width,
                    h: height,
                })
            }
        }
        recalcDims()
        window.addEventListener('resize', recalcDims)
        return () => {
            window.removeEventListener('resize', recalcDims)
        }
    }, [])

    const margin = useMemo(() => ({ t: 24, b: 66, l: 36, r: 0 }), [])
    const sizes = useMemo(() => ({
        contW: dim.w,
        contH: dim.h,
        graphW: dim.w - margin.l - margin.r,
        graphH: dim.h - margin.t - margin.b,
    }), [dim, margin])

    const barWidth = useMemo(() => {
        const barCalc = sizes.graphW / (dataSet.length * 2) - 16
        return Math.max(Math.min(150, barCalc), 25)
    }, [sizes, dataSet])

    const [xScroll, setXScroll] = useState(0)

    const maxXScroll = useMemo(() => {
        if (dataSet && dataSet[0]) {
            return Math.max((((barWidth * dataSet[0].data.length) + 20) * dataSet.length) - sizes.graphW) + 20
        }
        return 0
    }, [dataSet, barWidth, sizes])

    // set up events for scrolling the graph
    useLayoutEffect(() => {
        if (wrap.current) {
            const el = wrap.current

            function borders(delta) {
                setXScroll(x => Math.max(0, Math.min(maxXScroll, (x + delta))))
            }

            // scrolling
            el.onwheel = (e) => {
                const mult = 0.5
                const d = e.deltaY

                borders(d * mult)
            }
            // dragging
            el.onmousedown = (e) => {
                dragLoc.current = e.screenX
                dragDistance.current = 0
            }
            el.onmousemove = (e) => {
                if (dragLoc.current) {
                    const d = dragLoc.current - e.screenX

                    borders(d)
                    dragLoc.current = e.screenX
                }
                dragDistance.current += Math.abs(e.movementX) + Math.abs(e.movementY)
            }
            el.onmouseup = (e) => {
                dragLoc.current = undefined
                dragDistance.current = undefined
            }
            // touch devices
            el.ontouchstart = (e) => {
                const touch = e.touches[0]
                dragLoc.current = touch.screenX
                dragDistance.current = 0
            }
            el.ontouchmove = (e) => {
                if (dragLoc.current) {
                    const touch = e.touches[0]
                    const d = dragLoc.current - touch.screenX
                    borders(d)
                    dragLoc.current = touch.screenX
                }
                dragDistance.current += Math.abs(e.movementX) + Math.abs(e.movementY)
            }
            el.ontouchstop = (e) => {
                dragLoc.current = undefined
                dragDistance.current = undefined
            }
        }
    }, [maxXScroll])


    // ticks
    const xTicks = useMemo(() => {
        const ticks = []

        const tick = sizes.contW / (dataSet.length + 1);
        dataSet.map(({ label }, index) => {
            ticks.push({
                label,
                xOffset: ((index) * tick),
                labelCenter: (((index) * tick) + (tick))
            })
        })

        return ticks
    }, [dataSet, sizes])

    const maxValue = useMemo(() => {
        let max = 0
        for (const { data } of dataSet) {
            for (const { value } of data) {
                max = Math.max(max, value)
            }
        }

        return (max + 1)
    }, [dataSet])

    const yScale = useMemo(() => (
        scaleLinear()
            .domain([0, maxValue])
            .range([0, sizes.graphH])
    ), [sizes, maxValue])

    const yTicks = useMemo(() => {
        const [min, max] = yScale.domain()
        const step = Math.ceil((max - min) / 10)
        let arr = []
        for (let x = 0; x <= 10; x++) {
            arr.push(x * step)
        }

        return arr
            .reverse()
            .filter(value => value <= max)
            .map(value => ({
                label: value,
                yOffset: yScale(value),
            }))
    }, [yScale, maxValue])

    function roundToOne(num) {
        if (num >= 1000) {
            return `${num / 1000}K`
        }

        if (num > 100) {
            return Math.round(num)
        }
        return +(Math.round(num + "e+1") + "e-1");
    }

    return (
        <div {...props}>
            <div
                ref={wrap}
                style={{ width: '100%', height: '100%', position: 'relative', userSelect: 'none', cursor: 'grab' }}
            >
                {overlay &&
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            pointerEvents: 'none',
                        }}
                    >
                        {overlay}
                    </div>
                }
                <svg width={sizes.contW} height={sizes.contH}>
                    {/* graph */}
                    <g transform={`translate(${margin.l} ${margin.t})`}>
                        {/* X axis */}
                        {useMemo(() => (
                            <g transform={`translate(0 ${sizes.graphH})`}>
                                <path
                                    d={`M 0 0 H ${sizes.graphW}`}
                                    stroke='currentColor'
                                    strokeWidth={2}
                                />
                            </g>
                        ), [sizes])}


                        {/* Y axis */}
                        {useMemo(() => (
                            <g transform={`translate(0 ${sizes.graphH})`}>
                                <path
                                    d={`M 0 0 V ${-sizes.graphH}`}
                                    stroke='currentColor'
                                    strokeWidth={2}
                                />
                                {yTicks.map(({ label, yOffset }, index) => (
                                    <g
                                        key={index}
                                        transform={`translate(0 ${-yOffset})`}
                                    >
                                        <line
                                            x2={-6}
                                            stroke='currentColor'
                                            strokeWidth={2}
                                        />
                                        <text
                                            key={index}
                                            style={{
                                                fontSize: '10px',
                                                textAnchor: 'end',
                                                transform: 'translate(-16px, 4px)',
                                                fill: colors.white,
                                            }}
                                        >
                                            {roundToOne(Number(label))}
                                        </text>

                                        <line
                                            x1={0}
                                            x2={sizes.graphW}
                                            stroke='currentColor'
                                            opacity={0.1}
                                            strokeWidth={1}
                                        />

                                    </g>
                                ))}
                            </g>
                        ), [yTicks, sizes])}

                        <clipPath id='clipGraph'>
                            {/* we offset with the top margin to prevent datapoints clipping their dot */}
                            <rect x='0' y={-margin.t} width={sizes.graphW} height={sizes.contH} />
                        </clipPath>
                        <g clipPath='url(#clipGraph)'>
                            <g style={{ transform: `translateX(-${xScroll}px)` }}>
                                {useMemo(() => {
                                    if (typeof dataRender === 'function') {
                                        // place from back to front so we manipulate z index to not cover our text
                                        const copy = structuredClone(dataSet)
                                        copy.reverse()
                                        return copy.map((data, i) => dataRender({
                                            index: i,
                                            xPos: ((barWidth * data.data.length) + 20) * (copy.length - i - 1),
                                            yPos: sizes.graphH,
                                            label: data.label,
                                            groupWidth: barWidth * data.data.length,
                                            bars: data.data.map((bar, index) => ({
                                                label: bar.label,
                                                width: barWidth,
                                                height: yScale(bar.value),
                                                xOffset: barWidth * index
                                            }))
                                        }))
                                    }
                                }, [dataRender, sizes, margin, yScale, barWidth])}
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    )
}