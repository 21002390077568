import { useMemo } from "react"
import { backendColors, testCategoryGroupColors } from "../../style/vars"
import { avgFromTest } from "../../utils/analysisCalculations"
import Loader from "../_general/Loader"
import LinearGauge from "./LinearGauge"

export default function TestAverageLinearGauge({ test, ...props }) {

    const { avg, color, bins, binId, positionPercentage } = useMemo(() => {
        const ratings = test?.testCategory?.ratings
        if (!ratings?.length) return {}

        const testAvgResult = parseFloat(avgFromTest(test))
        const resultBin = ratings.find(r => ((r.min <= testAvgResult) && (r.max >= testAvgResult)))

        let lastFrontendCode

        const sortedRatings = ratings
            .toSorted((a, b) => a.min - b.min) // sort ratings ascending
            .filter(r => r.score >= 3) // remove ratings with extreme scores
            .map((r, i) => {  // mark where boundaries between colors are
                if (i === 0) {
                    lastFrontendCode = r.assessment.frontendCode
                    return r
                } else if (lastFrontendCode === r.assessment.frontendCode) {
                    return r
                } else {
                    lastFrontendCode = r.assessment.frontendCode
                    return { ...r, boundary: true }
                }
            })

        const gaugeMin = sortedRatings[0].min
        const gaugeMax = sortedRatings[sortedRatings.length - 1].max

        let positionPercentage  // fine positioning of result within bin
        let binId  // which bin our result belongs in
        let color

        if (testAvgResult < gaugeMin) {  // out of bounds minimum
            positionPercentage = 0
            binId = sortedRatings[0].id
            color = backendColors[sortedRatings[0].assessment.frontendCode]
        } else if (testAvgResult > gaugeMax) {  // out of bounds maximum
            positionPercentage = 100
            binId = sortedRatings[sortedRatings.length - 1].id
            color = backendColors[sortedRatings[sortedRatings.length - 1].assessment.frontendCode]
        } else if (Number.isFinite(testAvgResult)) {  // calculate position of result
            if (!resultBin) return {}
            binId = resultBin.id
            const { min, max } = resultBin
            const range = max - min
            positionPercentage = ((testAvgResult - min) / range) * 100
            color = backendColors[resultBin.assessment.frontendCode]
        }

        return {
            avg: testAvgResult,
            color,
            bins: sortedRatings,
            binId,
            positionPercentage,
        }
    }, [test])

    return !!test?.testCategory?.ratings?.length ?
        <LinearGauge
            avg={avg}
            color={color}
            bins={bins}
            binId={binId}
            positionPercentage={positionPercentage}
            name={test.testCategory.name}
            iconColor={testCategoryGroupColors[test.testCategory.group]}
            icon={test.testCategory.icon}
            unitSI={test?.testCategory?.unitSI}
            metricDecimals={test?.testCategory?.allowedDecimals}
        />
        :
        <Loader size={'2em'} />
}