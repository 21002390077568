import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import { useLocation } from 'react-router'
import accountIcon from '../../assets/icons/account_circle.svg'
import { ReactSVG } from 'react-svg'

export default function UserBadge({ ...props }) {
    const location = useLocation()
    const isActive = useMemo(() => location.pathname === "/account", [location])

    return (
        <div css={css`
            font-size: 0.8em;
            display: grid;
            place-items: center;
            position: relative;
        `}
            {...props}
        >
            <ReactSVG
                src={accountIcon}
                css={css`
                    width: 1.5em;
                    fill: ${isActive ? colors.black : colors.white}
                `}
            />
        </div >
    )
}