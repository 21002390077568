import { css } from '@emotion/react'
import { format, fromUnixTime } from 'date-fns'
import React, { useMemo, useState, useEffect, useContext } from 'react'
import { ReactSVG } from 'react-svg'
import Loader from '../_general/Loader'
import useWeatherFlowState from '../../hooks/useWeatherFlowState'
import { backgrounds, colors } from '../../style/vars'
import { getConditionIcon, conditionStringsMap } from '../../utils/weatherUtils'
import humidityIcon from '@assets/icons/weather/humidity.svg'
import pressureIcon from '@assets/icons/weather/pressure.svg'
import precipitationIcon from '@assets/icons/weather/rain.svg'
import sunIcon from '@assets/icons/weather/sun.svg'
import sunsetIcon from '@assets/icons/weather/sunset.svg'
import temperatureIcon from '@assets/icons/weather/temperature.svg'
import windIcon from '@assets/icons/weather/wind.svg'
import SwitcherSimple from '../_control/SwitcherSimple'
import { MTM_EVENT_TYPES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { FormattedMessage, useIntl } from 'react-intl'
import LocaleContext from '../../context/Locale'
import useUnit from '../../hooks/useUnit'

const centeredColumn = 'display: flex; flex-direction: column; align-items: center; justify-content: center;'

function Label({ src, text, ...props }) {
    return (
        <div css={[css`${centeredColumn}`, { ...props.css, width: "100%" }]}
            {...props}
        >
            {src && <ReactSVG src={src} css={{
                width: "50%"
            }} />}
            <span
                css={{ textAlign: "center" }}
            >{text}</span>
        </div>)
}

export default function WeatherForecast({
    stationId,
    ...props
}) {

    const track = useTracker()

    const { data, isBusy, error } = useWeatherFlowState(
        `/better_forecast`,
        {
            station_id: stationId,
            units_wind: "mps",
        },
        [stationId])

    const [hourlyView, setHourlyView] = useState(true)

    const { units_temp,
        units_wind,
        units_precip,
        units_pressure,
        units_distance,
        ...units } = useMemo(() => (
            data?.units ?? {}
        ), [data])

    useEffect(() => {
        if (!!data) {
            track({
                'event': MTM_EVENT_TYPES['view-tempest-data']
            })
        }
    }, [data])

    const { formatMessage } = useIntl()
    const { datefnsLocale } = useContext(LocaleContext)
    const { formatUnit, formatUnitName, convertToUnits } = useUnit()

    return (
        <>
            {
                isBusy ?
                    <div css={css`
                        ${centeredColumn}
                        flex-grow: 1;
                        border-radius: 0.5em;
                        border: 2px solid ${colors.solid};
                        background: ${backgrounds.weatherGradient}`}
                    >
                        <span css={{ fontSize: '2em' }} >
                            <FormattedMessage id='retrievingWeatherForecasts' />
                        </span>
                        <Loader size="6em" />
                    </div>
                    : <>
                        <div css={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            alignItems: 'stretch',
                            padding: 10,
                            overflowX: "auto",
                            border: `2px solid ${colors.solid}`,
                            backgroundImage: backgrounds.weatherGradient,
                            borderRadius: ".5em",
                            padding: "4em 1em 1em 1em",
                        }}>
                            <SwitcherSimple
                                id='timeFrameSwitch'
                                checked={hourlyView}
                                onClick={() => setHourlyView((previous) => !previous)}
                                unCheckedLabel={formatMessage({ id: 'week' })}
                                checkedLabel={formatMessage({ id: 'day' })}
                                buttonCenter={true}
                                css={css`
                                    position: absolute;
                                    top: 0.2em;
                                    left: 50%;
                                    transform: translate(-50%, 0);
                                    text-transform: capitalize;
                                `}
                            /* css={css`margin: 0.4em 0; text-transform: capitalize;`} */
                            />
                            {hourlyView ?
                                <div css={{
                                    display: "grid",
                                    gridTemplateColumns: "minmax(6em, 2fr) repeat(24, minmax(3em, 1fr))",
                                    gridAutoRows: "minmax(1.5em, auto)",
                                    gridGap: 5,
                                    gap: 5,
                                    justifyItems: "center",
                                    alignItems: "center",
                                }}>
                                    {/* leftmost column: 'headers' */}
                                    <>
                                        <Label
                                            src={temperatureIcon}
                                            text={`${formatMessage({ id: 'temperature' })} (${formatUnitName('°C')})`}
                                            css={{ gridColumn: 1, gridRow: 3 }}
                                        />
                                        <Label
                                            src={humidityIcon}
                                            text={formatMessage({ id: 'humidity' })}
                                            css={{ gridColumn: 1, gridRow: 4 }}
                                        />
                                        <Label
                                            src={precipitationIcon}
                                            text={`${formatMessage({ id: 'precipitation' })}  (${formatUnitName('mm')})`}
                                            css={{ gridColumn: 1, gridRow: 5 }}
                                        />
                                        <Label
                                            src={windIcon}
                                            text={`${formatMessage({ id: 'wind' })},  `}
                                            css={{ gridColumn: 1, gridRow: 6 }}
                                        />
                                        <Label
                                            text={`${formatMessage({ id: 'windGust' })}  (${formatUnitName('m/s')})`}
                                            css={{ gridColumn: 1, gridRow: 7 }}
                                        />
                                        <Label
                                            src={pressureIcon}
                                            text={`${formatMessage({ id: 'pressure' })} (${formatUnitName('mb')})`}
                                            css={{ gridColumn: 1, gridRow: 8 }}
                                        />

                                    </>
                                    {/* 'body' --> series of rows */}
                                    {
                                        data?.forecast?.hourly?.slice(0, 24)?.map((forecast, index) => {
                                            const columnBase = {
                                                gridColumn: 2 + index,
                                                fontSize: '1em',
                                                textAlign: "center"
                                            }

                                            return (<React.Fragment key={index}>
                                                <div css={{ ...columnBase, gridRow: 1, fontSize: "1.2em" }}>
                                                    {forecast?.local_hour}
                                                </div>

                                                <div css={{
                                                    ...columnBase,
                                                    gridRow: 2,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%"
                                                }}>
                                                    <ReactSVG
                                                        src={getConditionIcon(forecast?.icon)}
                                                        css={{ width: "100%" }}
                                                    />
                                                </div>

                                                <div css={{ ...columnBase, gridRow: 3, fontSize: '1.5em' }}>
                                                    {
                                                        convertToUnits(forecast?.air_temperature, '°C', { metricPrecision: 0 }).primaryValue
                                                    }°
                                                </div>
                                                <div css={{ ...columnBase, gridRow: 4, fontSize: '1.25em' }}>
                                                    {`${forecast?.relative_humidity}%`}
                                                </div>
                                                <div css={[css`${centeredColumn}`, { ...columnBase, gridRow: 5 }]}>
                                                    <span css={{ fontSize: "1.2em" }}>
                                                        {convertToUnits(forecast?.precip, 'mm', { metricPrecision: 3 }).primaryValue}
                                                    </span>
                                                    <span>{forecast?.precip_probability}%</span>
                                                </div>
                                                <div css={[css`${centeredColumn}`, { ...columnBase, gridRow: 6 }]}>
                                                    <span>
                                                        {convertToUnits(forecast?.wind_avg, 'm/s', { metricPrecision: 0 }).primaryValue}
                                                    </span>
                                                    <span>
                                                        <FormattedMessage id={'cardinal' + forecast?.wind_direction_cardinal} />
                                                    </span>
                                                </div>
                                                <div css={{ ...columnBase, gridRow: 7 }}>
                                                    {convertToUnits(forecast?.wind_gust, 'm/s', { metricPrecision: 0 }).primaryValue}
                                                </div>
                                                <div css={{ ...columnBase, gridRow: 8, fontSize: '.9em' }}>
                                                    {convertToUnits(forecast?.sea_level_pressure, 'mb', { metricPrecision: 1 }).primaryValue}
                                                </div>
                                            </React.Fragment>)
                                        })
                                    }
                                </div>
                                :
                                <div css={{
                                    display: "grid",
                                    gridTemplateColumns: "minmax(6em, 2fr) repeat(7, minmax(10em, 3fr))",
                                    gridAutoRows: "minmax(1.5em, auto)",
                                    gridGap: "1em",
                                    gap: "1em",
                                    justifyItems: "center",
                                    alignItems: "stretch",
                                }}>
                                    {/* leftmost column: 'headers' */}
                                    <>
                                        <Label
                                            src={temperatureIcon}
                                            text={formatMessage({ id: 'temperature' })}
                                            css={{ gridColumn: 1, gridRow: 3 }}
                                        />
                                        <Label
                                            src={sunIcon}
                                            text={formatMessage({ id: 'daylight' })}
                                            css={{ gridColumn: 1, gridRow: 4 }}
                                        />
                                        <Label
                                            src={precipitationIcon}
                                            text={formatMessage({ id: 'precipChanceShort' })}
                                            css={{ gridColumn: 1, gridRow: 5 }}
                                        />
                                    </>
                                    {
                                        data?.forecast?.daily?.slice(0, 7)?.map((forecast, index) => {
                                            const columnBase = {
                                                gridColumn: 2 + index,
                                                fontSize: '1.5em'
                                            }
                                            return (<React.Fragment key={index}>
                                                <div css={{ ...columnBase, gridRow: 1, textTransform: 'capitalize' }}>
                                                    {format(fromUnixTime(forecast?.day_start_local), 'EEEE', { locale: datefnsLocale })}
                                                </div>
                                                <div
                                                    css={[css`${centeredColumn}`, { ...columnBase, gridRow: 2, width: "100%" }]}>
                                                    <ReactSVG
                                                        src={getConditionIcon(forecast?.icon)}
                                                        css={{ width: "60%", margin: "auto", marginTop: ".3em" }}
                                                    />
                                                    <span css={{ textAlign: "center", marginTop: ".3em" }}>
                                                        <FormattedMessage id={conditionStringsMap[forecast?.conditions]} />
                                                    </span>
                                                </div>
                                                <div css={[css`${centeredColumn} gap: 0.5em;`, { ...columnBase, gridRow: 3, textTransform: "capitalize" }]}>
                                                    <span>
                                                        {`${formatMessage({ id: 'maxAbbr' })}: ${formatUnit(forecast?.air_temp_high, '°C')}`}
                                                    </span>
                                                    <span>
                                                        {`${formatMessage({ id: 'minAbbr' })}: ${formatUnit(forecast?.air_temp_low, '°C')}`}
                                                    </span>
                                                </div>

                                                <div css={[css` display: grid; grid-template: 1fr 1fr / auto 1fr; `,
                                                { ...columnBase, gridRow: 4, fontSize: "2em" }]}
                                                >
                                                    <ReactSVG src={sunIcon} css={{ width: "1.2em" }} />
                                                    <span>{format(fromUnixTime(forecast?.sunrise), `HH:mm`)}</span>
                                                    <ReactSVG src={sunsetIcon} css={{ width: "1.2em" }} />
                                                    <span>{format(fromUnixTime(forecast?.sunset), `HH:mm`)}</span>
                                                </div>

                                                <div css={[css`${centeredColumn} gap: 0.5em;`, { ...columnBase, gridRow: 5, textTransform: "capitalize" }]}>
                                                    {
                                                        (forecast?.precip_type !== 'rain')
                                                            ? <span>
                                                                {formatMessage({ id: 'type' })}: {formatMessage({ id: forecast?.precip_type })}
                                                            </span>
                                                            : null
                                                    }
                                                    <span>{forecast?.precip_probability}%</span>
                                                </div>

                                            </React.Fragment>)
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </>
            }
        </>
    )
}
