import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import Select, { components } from 'react-select'
import { lighten, transparentize } from 'polished'
import { useIntl } from 'react-intl'

const rawStadiaTheme = {
    primary: transparentize(0.15, colors.main1),
    primary75: transparentize(0.15, colors.main1),
    primary50: transparentize(0.15, colors.main1),
    primary25: colors.eventDark,
    danger: colors.warn,
    dangerLight: lighten(0.3, colors.warn),
    neutral0: colors.eventDark,
    neutral5: colors.eventDark,
    neutral10: lighten(0.3, colors.eventDark),
    neutral20: transparentize(0.15, colors.main1),
    neutral30: lighten(0.6, colors.eventDark),
    neutral40: lighten(0.7, colors.eventDark),
    neutral50: lighten(0.8, colors.eventDark),
    neutral60: 'white',
    neutral70: 'white',
    neutral80: 'white',
    neutral90: 'white',
}

export default function FancyDropdown({
    options,
    disabled = false,
    subtle = false,
    isClearable = false,
    isSearchable = true,
    hideArrow = false,
    openUpwards = false,
    textCenter = false,
    compact = false,
    ...props
}) {

    const { formatMessage } = useIntl()

    return <Select
        options={options}
        noOptionsMessage={() => formatMessage({id: 'none'})}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={openUpwards ? "top" : 'bottom'}
        classNamePrefix="react-select"
        theme={(theme) => {
            return {
                ...theme,
                borderRadius: '5px',
                colors: {
                    ...theme.colors,
                    ...rawStadiaTheme
                },
            }
        }}
        styles={{
            singleValue: (provided) => (textCenter ? {
                ...provided,
                textAlign: 'center',
                width: '100%',
            } : provided),
            placeholder: (provided) => (textCenter ? {
                ...provided,
                textAlign: 'center',
                width: '100%',
            } : provided),
            option: (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: state.isSelected ? colors.main1 :
                        state.isFocused ? colors.eventLight
                            : provided.backgroundColor,
                    color: state.isSelected ? colors.eventDark : provided.color,
                }
            },
            control: (provided, state) => {
                return {
                    ...provided,
                    borderWidth: 1,
                    cursor: 'pointer'
                }
            }
        }}
        css={css`
        
            .react-select__single-value {
                padding: 0.1em;
            }

            .react-select__control {
                ${!compact ? 'padding: 0.5em;' : ''}
                ${subtle ? `border-color: rgba(255,255,255,0.2);` : ''}
                box-shadow: unset;
            }
            
            /* > div {
                ${!compact ? 'padding: 0.5em;' : ''}
                ${subtle ? `border-color: rgba(255,255,255,0.2);` : ''}
            } */
            
            .react-select__indicator-separator {
                display: none;
            }
        
            .react-select__dropdown-indicator {
                ${hideArrow ? 'display: none;' : ''}
            }
        `}
        {...props}
    />

}