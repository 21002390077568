
import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import { css } from '@emotion/react'
import Selector from '../_control/OptionsSelector'
import { getUnixTime } from 'date-fns'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import FieldSectionSelector from '../_control/FieldSectionSelector'
import Orientation from '../test/Orientation'
import Pitch from '../../context/Pitch'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { zonesToSectionState } from '../../utils/eventUtils'

const columns = 7
const rows = 5

export default function MatchForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [matchType, setMatchType] = useState()
    const [sections, setSections] = useState([])
    //other
    const { data: matchTypeOptions, loading: waitingForTypes } = useBridge(`/api/current/frontend-bridge/training-teams`)
    const [pitch] = useContext(Pitch)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/match-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setMatchType(data.team)
                setSections(zonesToSectionState(data.zoneResults))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // when the required field are set the form is valid and will submit.
    const validateAndSubmit = useCallback(async () => {
        if (!matchType) return setFormError(formatMessage({ id: 'pleaseSelectMatch' }))

        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            team: matchType, 
        }

        if (event) {
            const { data: putResponse, error } = await put(`${event['@id']}`, { body: payload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (putResponse) {
                const { data: matchData } = await get(`/api/current/match-events/${putResponse.id}`)

                matchData.zoneResults.forEach(async zone => {
                    // TO DO only execute for sections which have changed --> performance++
                    await put(`${zone['@id']}`, {
                        body: { active: sections[zone.posY - 1][zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, matchData)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Match event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data: postResponse, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (postResponse) {
                const { data: matchData } = await get(`/api/current/match-events/${postResponse.id}`)
                matchData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: {
                            active: sections?.[zone.posY - 1]?.[zone.posX - 1] ? 1 : null
                        },
                    })
                })
                onPostRequest(true, matchData)
            }
        }
    }, [pitch, matchType, sections, selectedDateTime])

    const isBusy = useMemo(() => {
        return waitingForTypes || isLoading
    }, [waitingForTypes, isLoading])

    return (
        <>
            {isBusy
                ? <FormLoader /> :
                (<>
                    <div css={css`display: flex; flex-direction: column; align-items: flex-start;`}>
                        <EventDateTimePicker
                            unixDate={selectedDateTime}
                            setUnixDate={setSelectedDateTime}
                        />
                        <Selector
                            label={formatMessage({ id: 'team' })}
                            optionsObject={matchTypeOptions}
                            setState={setMatchType}
                            state={matchType} />
                        <h3 css={{ marginBottom: 20 }}>
                            <FormattedMessage id='wornAreas' />
                        </h3>
                        <div css={css`max-width: 100%; overflow-x: auto;`}>
                            <FieldSectionSelector
                                backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                                rows={rows}
                                columns={columns}
                                sections={sections}
                                onChange={setSections}
                            />
                            <Orientation
                                css={style.compass}
                                heading={pitch.orientation}
                            />
                        </div>
                    </div>
                </>
                )
            }
            {
                formError && <div>{formError}</div>
            }
            <button
                className="btn"
                disabled={isBusy}
                type="button"
                onClick={(e) => validateAndSubmit(e)}>
                <FormattedMessage id='save' />
            </button>
        </>
    )
}

const style = {
    compass: css`
        position: relative;
        height: 2em;
        width: 2em;
        bottom: -1em;
        left: calc(50% - 1em);
        background-color: rgba(44,52,58);
        box-shadow: none;
        margin-bottom:40px;
    `,
}
