import React from 'react'

import { css } from '@emotion/react'
import {ReactSVG} from 'react-svg'
import shirt from '../../assets/images/shirt.svg'

const style = {
    wrapper: css`
        position: relative;
        display: inline-flex;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
          }
    `,
    background: css`
        width: 10em;
    `,
    number: css`
        background-color: transparent;
        display: inline-flex;
        position: absolute;
        top: 1em;
        left: 0;
        color: #C1C5C9;
        font-weight: bold;
        font-size: 40px;
        width: 100%;
        text-align: center;

        &.multi {
            left: 0;
        }
`,
}

export default function PlayerNumberField({ className, ...props }) {
    return (
        <div css={style.wrapper}>
            <ReactSVG css={style.background} src={shirt} />
            <input
                type="number"
                min="1"
                className={className}
                css={style.number}
                pattern="[0-9]*"
                {...props}
            />
        </div>
    )
}
