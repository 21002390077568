import { colors, backendColors, timings, fonts } from "../../style/vars"
import { css } from "@emotion/react"
import TestCategoryIcon from "../testCategory/TestCategoryIcon"
import ScoreIndicator from "../_general/ScoreIndicator"
import useUnit from "../../hooks/useUnit"

export default function LinearGauge({ avg, color, bins, binId, positionPercentage, name, icon, iconColor, unitSI, metricDecimals, ...props }) {

    const { formatUnit, formatUnitName } = useUnit()

    return <div css={styles.wrapper} {...props}>
        <div css={css`display: flex; align-items: center; gap: 0.5em; color: ${colors.light};`}>
            {icon &&
                <TestCategoryIcon iconName={icon} color={iconColor} />
            }
            {name}
            <span css={css` text-transform: none !important; `}>
                {` (${formatUnitName(unitSI)})`}
            </span>
        </div>
        <div css={styles.bar}>

            {bins?.map((bin, i) => {
                return <div key={bin['@id']} css={css`
                        flex-grow: 1; 
                        flex-basis: 0;
                        background: ${bin.assessment?.frontendCode === 'good'
                        ? backendColors[bin.assessment.frontendCode] : bin.assessment?.frontendCode === 'neutral'
                            ? 'rgba(255,255,255,0.2)' : 'transparent'};
                        position: relative;
                        height: 0.7em;
                        font-size: 0.8em;                        
                    `}>
                    {bin.id === binId &&
                        <div className="orb" css={css`
                            left: ${positionPercentage}%;
                            position: absolute;
                            z-index: 1;
                            font-size: 0.7em;
                            transform: translate(-50%, -35%);

                            > div {
                                background: ${colors.eventLight};
                            }
                        `}>
                            <ScoreIndicator
                                value={avg}
                                color={color}
                                metricUnit={unitSI}
                                decimals={metricDecimals}
                            />
                        </div>
                    }

                    {bin.boundary &&
                        <div className="boundary" css={styles.binBoundary}>
                            {formatUnit(
                                bin.min,
                                unitSI,
                                { metricPrecision: metricDecimals, hideAllUnits: true, }
                            )}
                        </div>
                    }
                </div>
            })}
        </div>
    </div>

}

const styles = {
    wrapper: css`
        width: 100%;
        font-size: 1em;
        padding: 0 1em 1em 1em;
        color: ${colors.white};
        font-size: 1.4em;
        line-height: 1.8;
        font-family: ${fonts.special};
        font-weight: 700;
        text-transform: unset;
    `,
    bar: css`
        margin-top: 0.2em;
        width: 100%;
        display: flex;
        border-top: 2px solid ${colors.light};
        border-bottom: 2px solid ${colors.light};
        border-left: 2px solid ${colors.light} ;
        border-right: 2px solid ${colors.light};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;

        &:hover {

            .boundary{
                opacity: 0.5;
            }
            
            .orb {
                > div {
                    background: ${colors.eventLight}70;
                    border-color: transparent;
                    box-shadow: unset;
                    color: ${colors.white};
                }
            }
        }
    `,
    binBoundary: css`
        position: absolute; 
        height: 100%;
        transform: translate(-50%, 80%);
        opacity: 0;
        transition: opacity ${timings.smooth};
    `,

}