import React, { useContext, useMemo } from 'react'
import { format } from 'date-fns'
import LocaleContext from '../../context/Locale'
import { toZonedTime } from 'date-fns-tz'

export default function SelectedCalendarDate({ date: dateMs, pitch, ...props }) {
    
    const { datefnsLocale } = useContext(LocaleContext)

    const timeZoneWithFallback = useMemo(() => {
        return pitch?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }, [pitch])

    const formattedDate = useMemo(() => {
        if (!dateMs) return ''
        const zonedTime = toZonedTime(dateMs, timeZoneWithFallback)
        return format(zonedTime, 'PP', { locale: datefnsLocale })
    }, [timeZoneWithFallback, dateMs])

    return <span {...props}>
        {formattedDate}
    </span>
}