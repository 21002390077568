import React, { useCallback, useState, Fragment, useContext, useEffect, useMemo } from 'react'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import { getUnixTime } from 'date-fns'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import NumberScroller from '../_control/NumberScroller'
import { css } from '@emotion/react'
import PitchContext from '../../context/Pitch'

export default function ActivityForm({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [activityType, setActivityType] = useState()
    //other
    const { data: activityTypes, loading: eventTypesBusy } = useBridge(`/api/current/frontend-bridge/activity-event-types`)
    const [pitch] = useContext(PitchContext)

    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/activity-events/${eventId}`)
                setTimeTaken(data.timeTaken / 60)
                setActivityType(data.activityType)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // when the required field are set the form is valid and will submit.
    const validateAndSubmit = useCallback(async () => {

        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)

        const eventPayload = {
            timestamp: getUnixTime(selectedDateTime),
            activityType: activityType,
            timeTaken: parseInt(timeTaken * 60),
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: eventPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }

        } else {
            const newEventPayload = {
                ...eventPayload,
                name: `Activity event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newEventPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [pitch, activityType, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => {
        return eventTypesBusy || isLoading
    }, [eventTypesBusy, isLoading])

    return (<>
        {isBusy ? <FormLoader /> : activityTypes && <>
            <EventDateTimePicker
                unixDate={selectedDateTime}
                setUnixDate={setSelectedDateTime}
            />
            <NumberScroller
                label={formatMessage({ id: 'timeTaken' })}
                minVal={0}
                maxVal={24}
                state={timeTaken}
                unit={formatMessage({ id: 'hours' })}
                step={0.5}
                setState={setTimeTaken}
                css={css`width: min-content;`}
            />
            {Object.keys(activityTypes).map((key) => (
                <Fragment key={key}>
                    <h3 style={{ margin: '16px 0' }}>
                        {key}
                    </h3>
                    {Object.keys(activityTypes[key]).map((type) =>
                        <Fragment key={type}>
                            <button
                                onClick={() => { setActivityType(type) }}
                                css={[buttonStyle, css`
                                    border: ${activityType === type ? '2px solid #75FBC9' : '2px solid #3A424A'};
                                    color: ${activityType === type ? '#75FBC9' : '#CFD6D8'};
                                `]}
                            >
                                {activityTypes[key][type]}
                            </ button>
                        </ Fragment>
                    )}
                </ Fragment>
            ))}
        </>}
        <div style={{ marginTop: '40px' }}>
            <button
                onClick={() => validateAndSubmit()}
                type='button'
                className="btn"
                disabled={!activityType || isLoading}
            >
                <FormattedMessage id='save' />
            </button>
        </div>
    </>)
}

const buttonStyle = {
    backgroundColor: '#2D3339',
    padding: '8px 16px',
    borderRadius: '5px',
    marginRight: '16px',
    marginBottom: '10px',
    outline: 'none',
    cursor: 'pointer',
}