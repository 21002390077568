import { css } from "@emotion/react"
import { useCallback, useMemo } from "react"
import { colors } from "../../../style/vars"
import sensorIcon from '@assets/icons/sensor.svg'
import { ReactSVG } from "react-svg"
import { darken } from "polished"
import PitchBg from "../../_general/pitch/pitchBg"

// according to specs this is going to be a static 3x3 grid, forever and ever

// we'll keep the same X and Y axis orientation as for the analyses, to keep the weirdness consistent:
// |x3y1        x3y3
// |
// |x1y1        x1y3
// -------------------

export default function PitchSensorsModeToggler({
    handleClick,
    devicePlacements,
    pitchBg,
    disabled,
    ...props }) {

    const readOnly = useMemo(() => {
        return !handleClick || disabled
    }, [handleClick, disabled])

    const handleCellClick = useCallback((x, y) => {
        if (handleClick && !readOnly) handleClick(x, y)
    }, [readOnly, handleClick])

    return <div css={css`
            width: 100%;
            aspect-ratio: 4.5 / 3;
            display: grid;
            grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
            border: 2px solid transparent;
            background: transparent;
            border-radius: 3px;
            position: relative;
        `}
        {...props}
    >
        {[
            [3, 1], [3, 2], [3, 3],
            [2, 1], [2, 2], [2, 3],
            [1, 1], [1, 2], [1, 3]
        ].map(([x, y]) => {

            const placement = devicePlacements.find(p => p.posX === x && p.posY === y)

            return <Cell
                key={`${x}${y}SensorPickerCell`}
                setCoordinates={() => { handleCellClick(x, y) }}
                disabled={readOnly}
                isUnderCover={placement?.deviceMode === 'under-cover'}
                deviceName={placement?.device?.name}
                hasDevice={!!placement}
            />
        })}

        <div css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
        `}>
            <PitchBg type={pitchBg} />
        </div>
    </div>
}


const selectedColor = darken(0.15)(colors.main1)

function Cell({
    setCoordinates,
    disabled,
    isUnderCover,
    deviceName,
    hasDevice,
}) {
    const style = useMemo(() => {
        return {
            cell: css` 
                background: transparent;
                height: 100%;
                width: 100%;
                overflow: hidden;
                z-index: 1;
                position: relative;
            ${!disabled ?
                    `cursor: pointer;
                    &:hover {
                        opacity: 0.4;
                        background: ${selectedColor};
                    }`
                    :
                    ''}`
            ,
            icon: css`
                width: 100%;
                height: 100%;
                color: ${isUnderCover ? 'black' : 'white'};
                display: grid;
                place-items: center;

                > * {
                    width: 50%;
                    display: grid; 
                    place-items: center;
                }
            `,
            text: css`
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -2px);
                color: ${(isUnderCover ? 'black' : 'white')};
            `,
        }

    }, [disabled, isUnderCover])

    return <div title={deviceName} onClick={setCoordinates} css={style.cell} >
        {hasDevice && <>
            <ReactSVG src={sensorIcon} css={style.icon} />
            <div css={style.text}>
                {isUnderCover ? 'Under' : 'Over'} {/* /* TO DO: TRANSLATE */}
            </div>
        </>}
    </ div>
}