import { useEffect, useState } from "react"
import FancyDropdown from '../_control/FancyDropdown'
import { css } from "@emotion/react"
import { colors } from "../../style/vars"

// this component does not take timezones into account. Create a 'local' datetime for analysis creation,
// then upon submit convert it to represent "on-pitch" time
export default function NewAnalysisDateTimePicker({ formDate, setFormDate, ...props }) {
    const [hour, setHour] = useState((formDate || new Date()).getHours())
    const [minute, setMinute] = useState(getPrevFiveMins((formDate || new Date()).getMinutes()))

    useEffect(() => {
        setFormDate(date => {
            const newDate = !!date ?
                new Date(date.getTime())
                : new Date() // create a new 'now' date if one does not exist yet 
            newDate.setHours(hour, minute) // keep date, hours and minutes in sync 
            return newDate
        })
    }, [hour, minute])

    return (<div css={css`
        display: flex;
        align-items: center;
        width: min-content;
        border-radius: 5px;
        border: 2px solid #394047;
        background-color: #2F353B;
    `} {...props}>
        <FancyDropdown
            options={hoursOptions}
            css={dropdownStyle}
            value={hoursOptions.find(x => x.value === hour)}
            onChange={(option) => setHour(option.value)}
            hideArrow={true}
            isSearchable={false}
        />
        <span css={css`color: ${colors.main1};`}>
            :
        </span>
        <FancyDropdown
            options={minutesOptions}
            css={dropdownStyle}
            value={minutesOptions.find(x => x.value === minute)}
            onChange={(option) => setMinute(option.value)}
            hideArrow={true}
            isSearchable={false}
        />
    </div>)
}

const dropdownStyle = css`
    > div {
        border: none;
        min-width: 4em;
    }

    .react-select__menu {
        min-width: 6em;
    }
`

const hoursOptions = [
    { value: 0, label: '00' },
    { value: 1, label: '01' },
    { value: 2, label: '02' },
    { value: 3, label: '03' },
    { value: 4, label: '04' },
    { value: 5, label: '05' },
    { value: 6, label: '06' },
    { value: 7, label: '07' },
    { value: 8, label: '08' },
    { value: 9, label: '09' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
    { value: 22, label: '22' },
    { value: 23, label: '23' },
]

const minutesOptions = [
    { value: 0, label: '00' },
    { value: 5, label: '05' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
    { value: 35, label: '35' },
    { value: 40, label: '40' },
    { value: 45, label: '45' },
    { value: 50, label: '50' },
    { value: 55, label: '55' },
]

function getPrevFiveMins(mins) {
    if (mins < 5) return 0
    while (mins % 5 !== 0) {
        mins = mins - 1
    }
    return mins
}