
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg'
import { css, jsx } from '@emotion/react'
import { colors } from '../../style/vars'

import useUnit from '../../hooks/useUnit';

function forceWithinRange(v, min, max) {
    let toCheckValue = v
    toCheckValue = Math.max(toCheckValue, min)
    toCheckValue = Math.min(toCheckValue, max)
    return toCheckValue
}

export default function NumberScroller({
    isOptional,
    label,
    img,
    state,
    setState,
    minVal = 0,
    maxVal = Infinity,
    step = 1,
    unit: metricUnit,
    metricDecimals,
    horizontal = null,
    inputWidthEm,
    ...props
}) {
    const { convertToUnits, convertToMetric, systemIsMetric } = useUnit()

    const { primaryPrecision, secondaryPrecision, primaryStep, secondaryStep } = useMemo(() => {
        let primaryPrec
        let secondaryPrec
        let primarySt
        let secondarySt
        // needs a step in metric but also in the converted unit...
        if (systemIsMetric) {
            primaryPrec = step.toString().split('.')?.[1]?.length ?? 0
            primarySt = step
        } else {
            // try to get a step from the units util for converted/metric unit
            // wait, this needs to handle primary and secondary units, so use a secondary if possible.
            // if using a secondary, the primary step should always be 1, and secondary should be calculated
            const {
                primaryValue,
                primaryUnit,
                secondaryValue,
                secondaryUnit,
                converted,
            } = convertToUnits(parseFloat(step), metricUnit, {
                useSecondaryUnit: true,
                returnUnconvertedWhenUnknownUnit: true,
            })
            // detect whether we are dealing with a secondary unit or not
            if (secondaryUnit) {
                primaryPrec = 0
                primarySt = 1
                secondaryPrec = secondaryValue.toString().split('.')?.[1]?.length ?? 0
                secondarySt = parseFloat(1 / (10 ** secondaryPrec))
            } else if (primaryUnit && converted) {
                // if no secondary, we should calculate for the primary step
                primaryPrec = primaryValue.toString().split('.')?.[1]?.length ?? 0
                primarySt = parseFloat(1 / (10 ** primaryPrec))
            } else {
                // if no conversion can be made, default back to the 'metric' precision and step
                primaryPrec = step.toString().split('.')?.[1]?.length ?? 0
                primarySt = step
            }
        }

        return {
            primaryPrecision: primaryPrec,
            secondaryPrecision: secondaryPrec,
            primaryStep: primarySt,
            secondaryStep: secondarySt,
        }
    }, [step, metricDecimals, metricUnit, systemIsMetric])

    // this memo is only used to set initial scroller values
    const { primaryValue, primaryUnit, secondaryValue, secondaryUnit, secondaryUnitMax, converted } = useMemo(() => {

        const convertedValues = convertToUnits(state, metricUnit, { returnUnconvertedWhenUnknownUnit: true })
        const secondaryUnitMax = convertedValues.secondaryUnit?.toUnitFromPrimary(1)

        return { ...convertedValues, secondaryUnitMax }
    }, [convertToUnits, state, metricUnit])

    const [convertedMin, convertedMax] = useMemo(() => {
        return converted ? [
            convertToUnits(minVal, metricUnit, { useSecondaryUnit: false, returnUnconvertedWhenUnknownUnit: true }).primaryValue,
            convertToUnits(maxVal, metricUnit, { useSecondaryUnit: false, returnUnconvertedWhenUnknownUnit: true }).primaryValue,
        ] : [
            minVal,
            maxVal,
        ]
    }, [minVal, maxVal, metricUnit, convertToUnits, converted])

    // PRIMARY SCROLLER
    const [scroller1Val, setScroller1Val] = useState(primaryValue)
    // string for input
    const val1String = useMemo(() => {
        if (scroller1Val === null || scroller1Val === undefined) return ''
        return secondaryUnit ? scroller1Val.toString() : scroller1Val.toFixed(primaryPrecision)
    }, [scroller1Val, secondaryUnit, primaryPrecision])

    const handleSetScroller1 = useCallback((newValString) => {
        if (newValString === '') { // handle empty or invalid newValString
            setScroller1Val(isOptional ? null : 0)
            return
        }

        const targetValue = forceWithinRange(Number(newValString), convertedMin, convertedMax)
        setScroller1Val(targetValue)

    }, [forceWithinRange, isOptional, convertedMin, convertedMax])

    const incrementScroller1 = useCallback((amount) => {
        handleSetScroller1(((scroller1Val ?? 0) + amount))
    }, [scroller1Val, handleSetScroller1])


    // SECONDARY SCROLLER
    const [scroller2Val, setScroller2Val] = useState(secondaryValue)

    const val2String = useMemo(() => {
        if (scroller2Val === null || scroller2Val === undefined) return ''
        return scroller2Val.toFixed(secondaryPrecision)
    }, [scroller2Val, secondaryUnit, secondaryPrecision])

    const handleSetScroller2 = useCallback((newValString) => {
        if (newValString === '') { // handle empty or invalid newValString
            setScroller2Val(isOptional ? null : 0)
            return
        }
        const targetValue = forceWithinRange(Number(newValString), 0, secondaryUnitMax)
        setScroller2Val(targetValue)
        if (scroller1Val === undefined || scroller1Val === null) {
            setScroller1Val(0)
        }
    }, [forceWithinRange, isOptional, secondaryUnitMax, scroller1Val])

    const incrementScroller2 = useCallback((amount) => {
        handleSetScroller2(((scroller2Val ?? 0) + amount))
    }, [scroller2Val, handleSetScroller2])

    const debounceTimeout = useRef()

    // use effect version with debounce
    useEffect(() => {
        // Clear the previous timeout if dependencies change
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current)

        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            if (converted) {
                const newState = convertToMetric(scroller1Val, scroller2Val, metricUnit).metricValue
                setState(newState)
            } else {
                setState(scroller1Val)
            }
        }, 70)

        // Cleanup timeout when the component unmounts
        return () => { clearTimeout(debounceTimeout.current) }
    }, [scroller1Val, scroller2Val, metricUnit, setState, convertToMetric, converted])


    /**
     * truncates input to precision of the step
     * @param {string | number} numString
     * @return {string} numString truncated to the step precision
     */
    const truncateToPrecision = useCallback((numString, precision) => {
        //determine precision of the step
        const [base, decimals, ..._] = numString.toString().split('.')
        return (decimals === undefined || precision === 0) ?
            base
            :
            `${base}.${decimals.slice(0, precision)}`
    }, [])

    const { scrollerWidth1, scrollerWidth2 } = useMemo(() => {
        return {
            scrollerWidth1: (converted && inputWidthEm) ||
                (convertedMax < Infinity ?
                    Math.max(truncateToPrecision(convertedMax, primaryPrecision).length, 4)
                    : 4),
            scrollerWidth2: (converted && inputWidthEm) ||
                (secondaryUnitMax < Infinity ?
                    Math.max(truncateToPrecision(secondaryUnitMax, secondaryPrecision).length, 4)
                    : 4),
        }
    }, [convertedMax, secondaryUnitMax, truncateToPrecision, inputWidthEm, primaryPrecision, secondaryPrecision, converted])

    const faded = useMemo(() => (
        val1String === ''
    ), [val1String])

    return (
        <div {...props} css={css`display: flex; gap: 2em;`}>

            {/* scroller 1 */}
            <div >
                {label && <label>{label}</label>}
                <div css={{
                    display: horizontal ? "inline-flex" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: faded && '0.6',
                    gap: '0.5em'
                }}>
                    {img && <ReactSVG src={img} style={style.icon} />}

                    <div css={[
                        css`
                            display: flex; 
                            flex-direction: ${horizontal ? "row-reverse" : "column"}
                        `,
                        style.scroller
                    ]}>

                        <button
                            css={style.button}
                            onClick={() => incrementScroller1(primaryStep)}>
                            {horizontal ? "►" : "▲"}
                        </button>

                        <input
                            type="number"
                            onChange={e => handleSetScroller1(e.target.value)}
                            value={val1String}
                            placeholder={isOptional ? undefined : '0'}
                            min={convertedMin}
                            max={convertedMax}
                            css={style.input(scrollerWidth1, faded)}
                            step={primaryStep}
                        />

                        <button
                            css={style.button}
                            onClick={() => incrementScroller1(-primaryStep)}>
                            {horizontal ? "◄ " : "▼"}
                        </button>
                    </div>

                    {metricUnit && <span>{primaryUnit.name}</span>}
                </div>
            </div>

            {/* scroller 2 */}
            {secondaryUnit &&
                <div css={css`align-self: end;`}>
                    <div css={{
                        display: horizontal ? "inline-flex" : "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: faded && '0.6',
                        gap: '0.5em'
                    }}>
                        {img && <ReactSVG src={img} style={style.icon} />}

                        <div css={[
                            css`
                            display: flex; 
                            flex-direction: ${horizontal ? "row-reverse" : "column"}
                        `,
                            style.scroller
                        ]}>

                            <button
                                css={style.button}
                                onClick={() => incrementScroller2(secondaryStep)}>
                                {horizontal ? "►" : "▲"}
                            </button>

                            <input
                                type="number"
                                onChange={e => handleSetScroller2(e.target.value)}
                                value={val2String}
                                placeholder={isOptional ? undefined : '0'}
                                min={0}
                                max={secondaryUnitMax}
                                css={style.input(scrollerWidth2, faded)}
                                step={secondaryStep}
                            />

                            <button
                                css={style.button}
                                onClick={() => incrementScroller2(-secondaryStep)}>
                                {horizontal ? "◄ " : "▼"}
                            </button>
                        </div>
                        <span>{secondaryUnit.name}</span>
                    </div>
                </div>
            }

        </div>
    )
}

const style = {
    icon: {
        width: "48px",
        fill: colors.soft,
        opacity: 0.5
    },

    arrowicon: {
        color: colors.soft,
        width: "1em"
    },

    scroller: css`
        justify-content: center;
        align-items: center;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    `,

    input: (scrollerWidth, faded) => ({
        width: `${scrollerWidth * 0.8}em`,
        backgroundColor: "transparent",
        display: "inline-flex",
        top: 1,
        color: faded ? colors.soft : colors.main1,
        background: 'rgba(255,255,255,0.07)',
        fontWeight: "bold",
        fontSize: 30,
        textAlign: "center",
        MozAppearance: "textfield",
        cursor: 'pointer',
    }),
    button: css`
        background: transparent;
        border: none;
        color: white;
        cursor: pointer;
        padding: 8px;

        &:focus {
            outline: none;
            border: none;
        }
    `,

}