import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { curveStepAfter } from 'd3'
import useUnit from './useUnit'

export default function () {

    const { formatMessage } = useIntl()
    const { convertToUnits } = useUnit()

    const convertUnit = useCallback((unitStr) => {
        return convertToUnits(0, unitStr, { returnUnconvertedWhenUnknownUnit: true }).primaryUnit.name
    }, [convertToUnits])

    const fieldProperties = {
        weatherCodeMax: { // not rendering this in the graph
            name: 'weatherCodeMax',
            unit: '',
        },
        temperatureAvg: {
            name: 'temperatureAvg',
            label: `${formatMessage({ id: 'temperature' })} ${formatMessage({ id: 'averageAbbr' })}`,
            unit: convertUnit('°C'),
            metricUnit: '°C',
            decimals: 1,
            getDomain: (min, max) => [
                (min < 0 ? min * 1.2 : min * 0.8),
                (max < 0 ? max * 0.5 : max * 1.2)
            ]
        },
        humidityAvg: {
            name: 'humidityAvg',
            label: `${formatMessage({ id: 'humidity' })} ${formatMessage({ id: 'averageAbbr' })}`,
            unit: convertUnit('%'),
            metricUnit: '%',
            decimals: 0,
            getDomain: () => [0, 100]
        },
        precipitationIntensityAvg: {
            name: 'precipitationIntensityAvg',
            label: `${formatMessage({ id: 'precipitation' })} ${formatMessage({ id: 'averageAbbr' })}`,
            unit: convertUnit('mm/h'),
            metricUnit: 'mm/h',
            decimals: 3,
            getDomain: (min, max) => [0, (max * 1.2) || 1]
        },
        precipitationProbabilityAvg: {
            name: 'precipitationProbabilityAvg',
            label: `${formatMessage({ id: 'precipChanceShort' })} ${formatMessage({ id: 'averageAbbr' })}`,
            unit: convertUnit('%'),
            metricUnit: '%',
            decimals: 0,
            getDomain: () => [0, 100]
        },
        dewPointAvg: {
            name: 'dewPointAvg',
            label: `${formatMessage({ id: 'dewpoint' })} ${formatMessage({ id: 'averageAbbr' })}`,
            unit: convertUnit('°C'),
            metricUnit: '°C',
            decimals: 1,
            getDomain: (min, max) => [
                (min < 0 ? min * 1.2 : min * 0.8),
                (max < 0 ? max * 0.5 : max * 1.2)
            ]
        },
        evapotranspirationSum: {
            name: 'evapotranspirationSum',
            label: `${formatMessage({ id: 'evapotranspiration' })} ${formatMessage({ id: 'total' })}`,
            unit: convertUnit('mm'),
            metricUnit: 'mm',
            decimals: 3,
            getDomain: (min, max) => [0, max * 1.2],
        },
        windSpeedAvg: {
            name: 'windSpeedAvg',
            label: `${formatMessage({ id: 'wind' })} ${formatMessage({ id: 'averageAbbr' })}`,
            unit: convertUnit('m/s'),
            metricUnit: 'm/s',
            decimals: 1,
            getDomain: (min, max) => [0, max * 1.2],
        },
        // derived metrics:
        growingDegrees: {
            isDerived: true,
            name: 'growingDegrees',
            label: `${formatMessage({ id: 'growingDegrees' })}`,
            unit: convertUnit('°C GD'),
            metricUnit: '°C GD',
            decimals: 1,
            getDomain: (min, max) => [
                (min < 0 ? min * 1.2 : min * 0.8),
                (max < 0 ? max * 0.5 : max * 1.2)
            ],
            curve: curveStepAfter,
        },
        growingDegreeDays: {
            isDerived: true,
            name: 'growingDegreeDays',
            label: `${formatMessage({ id: 'growingDegreeDaysAbbr' })}`,
            unit: convertUnit('°C GDD'),
            metricUnit: '°C GDD',
            decimals: 1,
            getDomain: (min, max) => [
                (min < 0 ? min * 1.2 : min * 0.8),
                (max < 0 ? max * 0.5 : max * 1.2)
            ],
            curve: curveStepAfter,
        },
        diseaseRiskIndex: {
            isDerived: true,
            name: 'diseaseRiskIndex',
            label: `${formatMessage({ id: 'diseaseRiskIndex' })}`,
            unit: convertUnit(''),
            metricUnit: '',
            decimals: 1,
            getDomain: () => [0, 10],
        },
        leafWetness: {
            isDerived: true,
            name: 'leafWetness',
            label: formatMessage({ id: 'leafWetness' }),
            unit: convertUnit('h'),
            metricUnit: 'h',
            decimals: 1,
            getDomain: (min, max) => [0, max * 1.2],
            curve: curveStepAfter,
        },
    }

    /* taken from tomorrow.io */
    const weatherCodeMap = {
        /* "0": "Unknown", //tomorrow.io values
        "1000": "Clear, Sunny",
        "1100": "Mostly Clear",
        "1101": "Partly Cloudy",
        "1102": "Mostly Cloudy",
        "1001": "Cloudy",
        "2000": "Fog",
        "2100": "Light Fog",
        "4000": "Drizzle",
        "4001": "Rain",
        "4200": "Light Rain",
        "4201": "Heavy Rain",
        "5000": "Snow",
        "5001": "Flurries",
        "5100": "Light Snow",
        "5101": "Heavy Snow",
        "6000": "Freezing Drizzle",
        "6001": "Freezing Rain",
        "6200": "Light Freezing Rain",
        "6201": "Heavy Freezing Rain",
        "7000": "Ice Pellets",
        "7101": "Heavy Ice Pellets",
        "7102": "Light Ice Pellets",
        "8000": "Thunderstorm", */
        "0": "unknown", // translations.js keys
        "1000": "clearSunny",
        "1100": "mostlyClear",
        "1101": "partlyCloudy",
        "1102": "mostlyCloudy",
        "1001": "cloudy",
        "2000": "fog",
        "2100": "lightFog",
        "4000": "drizzle",
        "4001": "rain",
        "4200": "lightRain",
        "4201": "heavyRain",
        "5000": "snow",
        "5001": "flurries",
        "5100": "lightSnow",
        "5101": "heavySnow",
        "6000": "freezingDrizzle",
        "6001": "freezingRain",
        "6200": "lightFreezingRain",
        "6201": "heavyFreezingRain",
        "7000": "icePellets",
        "7101": "heavyIcePellets",
        "7102": "lightIcePellets",
        "8000": "thunderstorm"
    }

    return {
        fieldProperties,
        weatherCodeMap
    }
}