import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { colors } from '../../style/vars'

export default function WysiwygEditor({
    value = '',
    onBlur,
    ...props
}) {
    const editor = useRef()

    useEffect(() => {
        if (editor.current) {
            editor.current.setData(value)
        }
    }, [editor.current, value])

    return (
        <div css={css`
            color: ${colors.black};
            * {
                line-height: 1.75;
            }
            h1, h2, h3 {
                font-weight: 600;
            }
            h1 { font-size: 2.25em; }
            h2 { font-size: 2em; }
            h3 { font-size: 1.75em; }
            .ck-content {
                height: 280px;
            }
        `} {...props}>
            <CKEditor
                editor={ClassicEditor}
                data={value} // initial data, according to ckeditor devs
                onBlur={(e, ed) => { onBlur(ed.getData()) }}
                onInit={(ed) => { editor.current = ed }}
                config={{
                    licenseKey: 'GPL',  // use the open-source version
                    height: '320px',
                    heading: {
                        options: [
                            {
                                model: 'paragraph', title: 'Paragraph',
                            },
                        ],
                    },
                    toolbar: ['bold', 'italic'],
                }}
            />
        </div>
    )
}
