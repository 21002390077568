// @ts-nocheck
import InjuryEvent from '../assets/icons/event-types/injury.svg'
import MowingEvent from '../assets/icons/event-types/mowing.svg'
import DivotingEvent from '../assets/icons/event-types/divoting.svg'
import FertilisingEvent from '../assets/icons/event-types/fertilizing.svg'
import AerationEvent from '../assets/icons/event-types/aeration.svg'
import SeedingEvent from '../assets/icons/event-types/seeding.svg'
import IrrigationEvent from '../assets/icons/event-types/irrigation.svg'
import HeatingEvent from '../assets/icons/event-types/heating.svg'
import DiseaseEvent from '../assets/icons/event-types/disease.svg'
import MatchEvent from '../assets/icons/event-types/match.svg'
import TrainingEvent from '../assets/icons/event-types/training.svg'
import ActivityEvent from '../assets/icons/event-types/event.svg'
import PlayerPerceptionEvent from '../assets/icons/event-types/player_perception.svg'
import BioTreatmentEvent from '../assets/icons/event-types/bio_treatment.svg'
import GroomingEvent from '../assets/icons/event-types/grooming.svg'
import TopDressingEvent from '../assets/icons/event-types/topdressing.svg'
import LineMarkingEvent from '../assets/icons/event-types/linemarking.svg'
import NoteEvent from '../assets/icons/event-types/note.svg'
import CoverEvent from '../assets/icons/event-types/cover.svg'
import { colors } from '../style/vars'

function eventExists(eventType) {
    return eventHardcode[eventType] !== undefined
}

export function getEventTypeIcon(eventType) {
    return eventExists(eventType) ? eventHardcode[eventType].icon : AerationEvent
}

export function getEventTypeColor(eventType) {
    return eventExists(eventType) ? eventHardcode[eventType].color : 'white'
}

export function getGroupsForEvent(eventType) {
    return eventExists(eventType) ? eventHardcode[eventType].groups : []
}

export function getAllEventGroups() {
    return Object.values(eventGroups)
}

export function getAllEventNames() {
    return Object.keys(eventHardcode)
}

export function zonesToSectionState(zones) {
    let columns = 0
    let rows = 0

    zones.forEach(z => {
        if (columns < z.posX) columns = z.posX
        if (rows < z.posY) rows = z.posY
    })

    const sectionState = []
    for (let i = 1; i <= rows; i++) {
        let column = []
        for (let j = 1; j <= columns; j++) {
            const zone = zones.filter((z) => z.posX === j && z.posY === i)
            column.push(zone[0].active === 1 ? true : false)
        }
        sectionState.push(column)
    }

    return sectionState
}

const { player, pitch } = colors

const eventGroups = {
    performance: 'performance',
    greenkeeping: 'greenkeeping',
}

const eventHardcode = {
    InjuryEvent: {
        color: player,
        groups: [eventGroups.performance],
        icon: InjuryEvent,
    },
    MowingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: MowingEvent,
    },
    DivotingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: DivotingEvent,
    },
    FertilisingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: FertilisingEvent,
    },
    AerationEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: AerationEvent,
    },
    SeedingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: SeedingEvent,
    },
    IrrigationEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: IrrigationEvent,
    },
    HeatingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: HeatingEvent,
    },
    DiseaseEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: DiseaseEvent,
    },
    MatchEvent: {
        color: player,
        groups: [eventGroups.performance, eventGroups.greenkeeping],
        icon: MatchEvent,
    },
    TrainingEvent: {
        color: player,
        groups: [eventGroups.performance, eventGroups.greenkeeping],
        icon: TrainingEvent,
    },
    ActivityEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: ActivityEvent,
    },
    PlayerPerceptionEvent: {
        color: player,
        groups: [eventGroups.performance],
        icon: PlayerPerceptionEvent,
    },
    BioTreatmentEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: BioTreatmentEvent,
    },
    GroomingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: GroomingEvent,
    },
    TopDressingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: TopDressingEvent,
    },
    LineMarkingEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: LineMarkingEvent,
    },
    NoteEvent: {
        color: player,
        groups: [eventGroups.performance, eventGroups.greenkeeping],
        icon: NoteEvent,
    },
    CoverEvent: {
        color: pitch,
        groups: [eventGroups.greenkeeping],
        icon: CoverEvent,
    },
    [undefined]: {
        color: 'white',
        groups: [eventGroups.performance, eventGroups.greenkeeping],
        icon: AerationEvent,
    }
}
