import { css } from '@emotion/react'
import React, { useState, useMemo, useEffect } from 'react'
import { colors, fonts } from '../../style/vars'
import helpImg from '@assets/images/beta_tutorial/help6.png'
import useTracker from '../../hooks/useTracker'
import { MTM_EVENT_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import { FormattedMessage, useIntl } from 'react-intl'

export default function AnalyticsHelp({ setHelp, ...props }) {

    const track = useTracker()
    const { formatMessage } = useIntl()

    const [faq, setFaq] = useState(false)
    const [page, setPage] = useState(1)
    const totalPages = 12

    function changePage(num) {
        let destination
        if ((num < 0) && (page === 1)) {
            setPage(totalPages)
            destination = totalPages
        } else if ((num > 0) && (page === totalPages)) {
            setPage(1)
            destination = 1
        } else {
            setPage(page + num)
            destination = page + num
        }
        track({
            'event': MTM_EVENT_TYPES['browse-pagination'],
            [MTM_VARIABLES['browse-destination']]: destination
        })
    }

    const infoText = useMemo(() => {
        return formatMessage({ id: 'analyticsTutorial' + page }, {
            p: chunks => <p>{chunks}</p>,
            strong: chunks => <strong>{chunks}</strong>,
            br: () => <br />,
            checkmarkSymbol: <>&#10003;</>,
            infinitySymbol: <>&#8734;</>,
            mode1Symbol: <>&#x2630;</>,
            mode2Symbol: <>&#x2611;</>,
        })
    }, [page])

    useEffect(() => {
        track({
            'event': MTM_EVENT_TYPES['view-help'],
        })
    }, [])

    useEffect(() => {
        if (faq) {
            track({
                'event': MTM_EVENT_TYPES['view-faq'],
            })
        }
    }, [faq])

    return (
        <div css={style.modal}>
            <h2 css={style.title}>
                <FormattedMessage id='help' />
            </h2>
            {faq ?
                <div css={style.faq}>
                    <FormattedMessage id='faqContent' values={{
                        p: chunks => <p>{chunks}</p>,
                        strong: chunks => <strong>{chunks}</strong>,
                        br: () => <br />
                    }} />
                </div>
                :
                <div css={style.content}>
                    <div css={css`width: min(800px, 90%);  position: relative; margin-top: 0.5em;`}>
                        <img src={helpImg} alt=""
                            css={style.image}
                        />
                        {page === 1 && <div css={[style.highlight, css`top: 0%; left: 22%; right: 1.5%; bottom: 80%;`]} />}
                        {page === 2 && <div css={[style.highlight, css`top: 28%; left: 58%; right: 37%; bottom: 66%;`]} />}
                        {page === 3 && <div css={[style.highlight, css`top: 28%; left: 52%; right: 43%; bottom: 67%;`]} />}
                        {page === 3 && <div css={[style.highlight, css`top: 40%; left: 83%; right: 14%; bottom: 54%;`]} />}
                        {page === 4 && <div css={[style.highlight, css`top: 15%; left: 7%; right: 79%; bottom: 52%;`]} />}
                        {page === 5 && <div css={[style.highlight, css`top: 50%; left: 7%; right: 79%; bottom: 1%;`]} />}
                        {page === 6 && <div css={[style.highlight, css`top: 86%; left: 7%; right: 79%; bottom: 8%;`]} />}
                        {page === 7 && <div css={[style.highlight, css`top: 40%; left: 48.5%; right: 34%; bottom: 53.5%;`]} />}
                        {page === 8 && <div css={[style.highlight, css`top: 40%; left: 66%; right: 31%; bottom: 54%;`]} />}
                        {page === 9 && <div css={[style.highlight, css`top: 40%; left: 69%; right: 28%; bottom: 54%;`]} />}
                        {page === 10 && <div css={[style.highlight, css`top: 40%; left: 72%; right: 22.5%; bottom: 54%;`]} />}
                        {page === 11 && <div css={[style.highlight, css`top: 40%; left: 77.5%; right: 19.5%; bottom: 54%;`]} />}
                        {page === 12 && <div css={[style.highlight, css`top: 40%; left: 80.5%; right: 16.7%; bottom: 54%;`]} />}
                    </div>
                    <div
                        onClick={() => { changePage(1) }}
                        css={style.tutorialText}
                    >
                        {infoText}
                    </div>
                </div>
            }

            <div css={style.controls}>
                <button
                    css={[style.button, css`margin-right: 2em;`]}
                    onClick={() => { setFaq(current => !current) }}
                >
                    {faq ? <FormattedMessage id='tutorial' /> : <FormattedMessage id='issuesQuestion' />}
                </button>

                {!faq &&
                    <>
                        <button
                            css={style.button}
                            onClick={() => { changePage(-1) }}>
                            {`<`}
                        </button>
                        <p css={css`margin: 0 1em;`} >
                            {page} / {totalPages}
                        </p>
                        <button
                            css={style.button}
                            onClick={() => { changePage(1) }}>
                            {`>`}
                        </button>
                    </>
                }

                <button
                    onClick={() => setHelp(false)}
                    css={[style.button, css`margin-left: 2em; text-transform: capitalize;`]}
                >
                    <FormattedMessage id='close' />
                </button>
            </div>

        </div>
    )
}

const style = {
    modal: css`
        position: fixed;
        z-index: 6;
        height: 80vh;
        width: min(1000px, 90vw);
        top: 50%;
        left: calc(50% + 2em);
        transform: translate(-50%, -50%);

        display: grid;
        grid-template: 7% 1fr auto / 1fr;

        border-radius: 5px;
        box-shadow: 0 0 0.5em 0.2em black;
        background: ${colors.eventDark};

        > * {
            min-width: 0;
            min-height: 0;
        }
        @media screen and (max-width: 600px) {
            left: 50%;
        }
    `,
    title: css`
        font-family: ${fonts.special};
        font-size: 2em;
        display: grid;
        place-items: center;
    `,
    image: css`
        width: 100%;
        height: auto;
        border-radius: 7px;
        box-shadow: 0 0 1px 2px ${colors.eventDark};
    `,
    controls: css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 5px;
        align-items: center;
    `,
    button: css`
        font-family: ${fonts.main};
        font-size: 16px;
        color:rgb(187, 187, 187);
        border-radius: 4px;
        border: none;
        box-shadow: 0 0 2px 2px ${colors.dark};
        background: rgba(35, 38, 40, 1);
        text-align: center;
        width: 70px;
        height: 32px;
        min-width: max-content;
        cursor: pointer;

        &:hover {
            background:  ${colors.eventDark};
        }
    `,
    content: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: visible;
        user-select: none;
    `,
    highlight: css`
        position: absolute;
        border-radius: 5px;
        box-shadow: 0 0 5px 6px ${colors.main1};
    `,
    tutorialText: css`
        margin-top: 0.3em;
        flex-grow: 1;
        width: 90%;
        display: grid;
        place-items: center;
        text-align: center;
        font-size: 1.2em;
        border-radius: 5px;
        background: rgba(0,0,0,.1);
        padding: 0 1em;
        cursor: pointer;
    `,
    faq: css`
    overflow: auto;
    margin-top: 0.5em;

    p, a {
        text-align: left;
        padding: 0px 15px 0px 15px;
        color: ${colors.light};
        font-family: Heebo;
        font-size: 0.9em;
        }

        a {
            text-decoration: underline;
        }

        ol {
            margin-left: 20px;
        }

        p strong {
            font-weight: 500;
            color: ${colors.soft};
        }
    `
}