import React from 'react'
import { css } from '@emotion/react'
import EventTypeIcon from './EventTypeIcon'

const style = {
    base: css`
        flex-shrink: 0;
    `,
}

export default function Event({
    event,
    iconStyle,
    ...props
}) {
    return (
        <div {...props} css={style.base}>
            <EventTypeIcon
                style={iconStyle}
                eventType={event.type}
            />
        </div>
    )
}