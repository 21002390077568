import React, { createContext, useContext } from 'react'
import { Link, useParams } from 'react-router'
import { get } from 'lodash'
import useRaw from '../hooks/useRawV2'
import Analysis from './Analysis'
import FallbackMessage from '../components/_general/FallbackMessage'
import { FormattedMessage } from 'react-intl'
import { colors } from '../style/vars'

const TestContext = createContext()
export default TestContext

export function RouteBasedTestProvider({children}) {
    const {testId} = useParams()
    return(
        <TestProvider testId={testId} children={children}/>
    )
}

export function TestProvider({ testId, ...props }) {
    const [analysis] = useContext(Analysis)
    const testResult = useRaw(`/api/current/tests/${testId}`, [testId])

    // enforce a matching pitch for the current analysis
    if ((analysis && testResult[0]) && get(analysis, '@id') !== get(testResult, [0, 'analysis', '@id'])) {
        return (
            <div>
                <FallbackMessage>
                    <FormattedMessage id='testDoesntBelongWarn'
                        values={{
                            analysisId: get(analysis, 'id')
                        }}
                    />
                    <br />
                    <Link to='/' style={{ color: colors.main1, textDecoration: 'underline' }}>
                        <FormattedMessage id='returnToHomePage' />
                    </Link>
                </FallbackMessage>
            </div>
        )
    }

    return (
        <TestContext.Provider
            value={testResult}
            {...props}
        />
    )
}