import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { NavLink } from 'react-router'
import { colors } from '../../style/vars'
import Auth from '../../context/Auth'
import useField from '../../hooks/useField'
import useChecked from '../../hooks/useChecked'
import AuthBoxLayout from '../../components/_layout/AuthBoxLayout'
import TextField from '../../components/_control/TextField'
import Cta from '../../components/_control/Cta'
import Checkbox from '../../components/_control/Checkbox'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'
import useFormattedError from '../../hooks/useFormattedError'

export default function SsoLogin() {
    const { rememberedEmail, getSsoRedirect, logInSso, loginType, busy } = useContext(Auth)
    const [emailInput, emailError] = useField(
        loginType === 'sso' ? rememberedEmail : undefined,
        val => val.length < 5
    )
    const [checked, onChange] = useChecked((loginType === 'sso') && !!rememberedEmail)
    const [loginError, setLoginError] = useState(false)

    const [searchParams] = useSearchParams()

    const formIsNotValid = emailError

    const handleError = useCallback((code) => {
        setLoginError(code)
    }, [])

    const handleRedirect = useCallback((e) => {
        e.preventDefault()
        setLoginError(false)
        getSsoRedirect(emailInput.value, checked, handleError)
    }, [emailInput, checked, getSsoRedirect])

    useEffect(() => {
        // check if param with code exists, if so, immediately 'log in' get jwt
        const codeParam = searchParams.get('code')

        if (codeParam) {
            // clears the tableau cookie by setting an expired date
            document.cookie = "ttime=0; expires=Sun, 20 Aug 2000 12:00:00 UTC; SameSite=Lax; Secure"
            logInSso(codeParam, handleError)
        }
    }, [searchParams, logInSso])

    const { formatMessage } = useIntl()

    const { getErrorMessageFromCode } = useFormattedError()

    const errorMessage = useMemo(() => {
        if (loginError) return getErrorMessageFromCode(loginError)
    }, [loginError, getErrorMessageFromCode])

    return (
        <form onSubmit={handleRedirect}>
            <AuthBoxLayout>
                {{
                    title: formatMessage({ id: 'singleSignOn' }),
                    subtitle: formatMessage({ id: 'logInUsingSaml' }),
                    main: (
                        <div css={css`display: grid;`}>
                            <div css={css`display: grid; border-radius: 0.1875em; overflow: hidden;`}>
                                <TextField
                                    type='email'
                                    css={css`border-bottom: 1px solid #ddd;`}
                                    placeholder={formatMessage({ id: 'emailAddress' })}
                                    {...emailInput}
                                />
                                {loginError &&
                                    <div css={css`
                                        margin-top: 0.5em;
                                        width: 100%;
                                        display: grid;
                                        place-items: center;
                                    `}>
                                        {loginError &&
                                            loginError === 404 ?
                                            formatMessage({ id: 'ssoNotConfiguredError' })
                                            : errorMessage
                                        }
                                    </div>
                                }
                            </div>
                            <Cta
                                css={css`align-self: center; min-width: 75%; margin-top: 1.75em;`}
                                disabled={busy || formIsNotValid}
                                loading={busy}
                                type='submit'
                            >
                                <FormattedMessage id='signIn' />
                            </Cta>
                        </div>
                    ),
                    below: (
                        <div css={css`display: flex; 
                            align-items: center;
                            justify-content: space-between; 
                            color: ${colors.liquid}; 
                            font-size: 0.875em; 
                            font-weight: 300;
                            padding: 0 0.3em;
                        `}>
                            <label css={css`display: flex; align-items: center; gap: 0.5em; cursor: pointer; user-select: none;`}>
                                <Checkbox checked={checked} onChange={onChange} />
                                <FormattedMessage id='rememberMe' />
                            </label>

                            <NavLink css={css`color: inherit;`} to='/login'>
                                <FormattedMessage id='goBack' />
                            </NavLink>
                        </div>
                    ),
                }}
            </AuthBoxLayout>
        </form>
    )
}