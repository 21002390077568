import useRawV2 from '../../hooks/useRawV2'
import { useState, useMemo, useContext, useEffect } from 'react'
import Loader from '../_general/Loader'
import ProfileContext from '../../context/Profile'
import FancyDropdown from '../_control/FancyDropdown'

export default function AdminAnalyticsClubSelector({ ...props }) {

    const [profile] = useContext(ProfileContext)

    const [unpaginatedClubs] = useRawV2(profile?.admin ? `/api/current/clubs?pagination=false` : undefined)
    const [selectedClub, setSelectedClub] = useState(localStorage.getItem('adminAnalyticsClub') ? Number(localStorage.getItem('adminAnalyticsClub')) : undefined)
    const clubsOptions = useMemo(() => {
        return unpaginatedClubs?.['hydra:member'].map(c => ({
            value: c.id,
            label: c.name,
        })) ?? []
    }, [unpaginatedClubs])

    useEffect(() => {
        if (!selectedClub) {
            localStorage.removeItem('adminAnalyticsClub')
            return
        }
        localStorage.setItem('adminAnalyticsClub', selectedClub)
    }, [selectedClub])

    return unpaginatedClubs ?
        <FancyDropdown 
            compact={true}
            subtle={true}
            isClearable={true}
            options={clubsOptions}
            onChange={val => setSelectedClub(val?.value)}
            value={clubsOptions?.find(opt => opt.value === selectedClub)}
            placeholder={'None'}
        />
        :
        <Loader size={'1em'} />
}