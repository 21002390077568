import React from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors, fonts } from '../../style/vars'
import swooshBg from '../../assets/images/swoosh_bg.svg'
import fullLogo from '../../assets/images/logo_large.svg'
import { useIntl } from 'react-intl'
import LanguageSelector from '../_control/LanguageSelector'

export default function AuthBoxLayout({
    children: {
        title,
        subtitle,
        main,
        below,
    },
}) {
    const { formatMessage } = useIntl()
    const sloganWords = formatMessage({ id: 'saferSurfaces' }).split(' ')
    const lastWord = sloganWords.pop()

    return (
        <div css={style.cont}>
            <LanguageSelector css={style.langSelect} openUpwards={true} />
            <div css={style.bg0} />
            <div css={style.bg2} />
            <div css={style.bg3}>
                <h4 css={style.slogan}>
                    {sloganWords.map(word => <div key={`slogan-${word}`}>
                        <span>{word}</span><br />
                    </div>)}
                    <span css={css`color: ${colors.main1};`}>
                        {lastWord}
                    </span>
                </h4>
            </div>
            <div css={style.logo} />
            <div css={style.wrapper} direction='column' justify='center' align='stretch'>
                <div css={style.box}>
                    <h1 css={style.title}>{title}</h1>
                    <h2 css={style.subtitle}>{subtitle}</h2>
                    {main}
                </div>
                <div css={style.below}>{below}</div>
            </div>
        </div>
    )
}

const style = {
    cont: css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        z-index: 1;
    `,
    bg0: css`
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${colors.dark};
        background-image: radial-gradient(ellipse at bottom left, ${rgba(colors.main1, 0.1)}, ${colors.dark} 50%);
    `,
    bg2: css`
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url('${swooshBg}');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    `,
    bg3: css`
        z-index: -1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3em;
        right: 3em;
    `,
    logo: css`
        z-index: -1;
        position: absolute;
        top: 3em;
        bottom: 3em;
        left: 3em;
        right: 3em;
        background-image: url('${fullLogo}');
        background-repeat: no-repeat;
        background-size: 12em auto;
        background-position: top left;

        @media screen and (max-width: 600px) {
            top: 1.5em;
        }
    `,
    wrapper: css`
        display: grid;
        padding: 10em 0;
        max-width: 20em;
        width: 100%;
    `,
    box: css`
        background-color: ${colors.substance};
        color: ${colors.white};
        padding: 3em 1.25em;
        border-radius: 4px;

        @media screen and (max-width: 600px) {
            margin-top: 3em;
        }
    `,
    title: css`
        margin-bottom: 1.25em;
        text-align: center;
        font-size: 2em;
    `,
    subtitle: css`
        margin-bottom: 2em;
        text-align: center;
        font-size: 0.875em;
        opacity: 0.36;
        font-weight: 300;
    `,
    below: css`
        margin-top: 1em;
    `,
    langSelect: css`
        position: absolute;
        bottom: 0;
        right: 0;
        background: ${colors.substance};
        width: min-content;
        min-width: 8em;
        color: white;
    `,
    slogan: css`
        font-size: 5.5em;
        font-weight: 600;
        font-family: ${fonts.special};
        color: white;
        text-transform: uppercase;
        @media(max-width: 1600px) {
            font-size: 3.6em;
        }
        @media(max-width: 1200px) {
            display: none;
        }
    `
}