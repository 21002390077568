import React from 'react'
import { css } from '@emotion/react'
import { useIntl } from 'react-intl'

export default function LoaderText({
    children = 'Loading',
    subtle = false,
}) {
    const { formatMessage } = useIntl()

    return (
        <span css={subtle ? css`opacity: 0.25;` : undefined}>
            {children ?? formatMessage({id: 'loading'})}
        </span>
    )
}