import React, { createContext, useState } from "react"
import { useSearchParams } from "react-router"
import useRawApiState from "../hooks/useRawApiState"

const VenueContext = createContext()
export default VenueContext

export function QueryBasedVenueProvider({ children }) {
    const [searchParams] = useSearchParams()
    const venueId = searchParams.get('venue')

    return <VenueProvider venueId={venueId} children={children} />
}

export function VenueProvider({ venueId, ...props }) {
    const venueResult = useRawApiState(`/api/current/venues/${venueId}`)

    return <VenueContext.Provider value={venueResult} {...props} />
}