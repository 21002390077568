import React, { useMemo, useContext } from 'react'
import { line, curveMonotoneX } from 'd3'
import GenericTimeSeries from './GenericTimeSeries'
import Loader from '../_general/Loader'
import FallbackMessage from '../_general/FallbackMessage'
import { colors } from '../../style/vars'
import { processChartData } from './util'
import ReporterContext from '../../context/Reporter'
import { FormattedMessage } from 'react-intl'

export default function PitchesTestsTimeline({ ...props }) {

    const {
        testCategory1,
        useResultsAsAxis,
        tests1,
        compareData,
        reporterBusy,
        lineColor1,
        lineColor2,
    } = useContext(ReporterContext)

    const { maxRangeTests, minRangeTests, testScores, unitOne, decimalsOne } = useMemo(() => {
        const hasTest = tests1 && tests1[0]
        return {
            maxRangeTests: hasTest ? tests1[0].testCategory.maxRatingValue : undefined,
            minRangeTests: hasTest ? tests1[0].testCategory.minRatingValue : undefined,
            testScores: processChartData(tests1),    // massaged data, for displaying
            unitOne: hasTest ? tests1[0].testCategory.unitSI : undefined,
            decimalsOne: hasTest ? tests1[0].testCategory.allowedDecimals : undefined,
        }
    }, [tests1])

    // massaged data, for displaying
    const { maxRangeComparedTests, minRangeComparedTests, compareTestScores, unitTwo, decimalsTwo } = useMemo(() => {
        const hasTest = compareData && compareData[0]
        return {
            maxRangeComparedTests: hasTest ? compareData[0].testCategory.maxRatingValue : undefined,
            minRangeComparedTests: hasTest ? compareData[0].testCategory.minRatingValue : undefined,
            compareTestScores: processChartData(compareData),    // massaged data, for displaying
            unitTwo: hasTest ? compareData[0].testCategory.unitSI : undefined,
            decimalsTwo: hasTest ? compareData[0].testCategory.allowedDecimals : undefined,
        }
    }, [compareData])

    return (
        <GenericTimeSeries
            {...props}
            yAxisOneMin={minRangeTests}
            yAxisOneMax={maxRangeTests}
            yAxisTwoMin={minRangeComparedTests}
            yAxisTwoMax={maxRangeComparedTests}
            unitOne={unitOne}
            unitTwo={unitTwo}
            decimalsUnitOne={decimalsOne}
            decimalsUnitTwo={decimalsTwo}
            graphStyle={{ opacity: reporterBusy ? 0.25 : 1 }}
            overlay={
                <>
                    {reporterBusy &&
                        <Loader size='16em' />
                    }
                    {!reporterBusy && (
                        !testCategory1
                            ?
                            <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                                <FormattedMessage id='selectATestCategory' />
                            </FallbackMessage>
                            :
                            testScores.length === 0 && compareTestScores.length === 0 &&
                            <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                                <FormattedMessage id='noDataAvailable' />
                            </FallbackMessage>
                    )}
                </>
            }
            domainRender={(({ xScale, yScale, yScaleCompared }) => (
                <>
                    {/* line & point */}
                    <g>
                        <path
                            d={
                                line()
                                    .curve(curveMonotoneX)
                                    .x((test) => (xScale(test.timestamp)))
                                    .y((test) => (yScale(useResultsAsAxis ? test.avg : test.score)))
                                    (testScores)
                            }
                            fill='none'
                            stroke={lineColor1}
                            strokeWidth={3}
                        />
                        {testScores?.map(({ id, timestamp, score, avg, rawCertified }) => (
                            <circle
                                key={id}
                                fill={lineColor1}
                                stroke={rawCertified ? colors.main1 : lineColor1}
                                strokeWidth={2}
                                r={4}
                                cx={xScale(timestamp)}
                                cy={yScale(useResultsAsAxis ? avg : score)}
                            />
                        ))}
                    </g>

                    {/* compare line & point */}
                    <g>
                        <path
                            d={
                                line()
                                    .curve(curveMonotoneX)
                                    .x((test) => (xScale(test.timestamp)))
                                    .y((test) => (yScaleCompared(useResultsAsAxis ? test.avg : test.score)))
                                    (compareTestScores)
                            }
                            fill='none'
                            stroke={lineColor2
                            }
                            strokeWidth={3}
                        />
                        {compareTestScores?.map(({ id, timestamp, score, avg, rawCertified }) => (
                            <circle
                                key={id}
                                fill={lineColor2
                                }
                                stroke={rawCertified ? colors.main1 : lineColor2
                                }
                                strokeWidth={2}
                                r={4}
                                cx={xScale(timestamp)}
                                cy={yScaleCompared(useResultsAsAxis ? avg : score)}
                            />
                        ))}
                    </g>
                </>
            ))}
        />
    )
}