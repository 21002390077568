import React, { useContext, useMemo } from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors } from '../../style/vars'
import AdviceBlock from '../_general/AdviceBlock'
import dp from 'dompurify'
import { useIntl, } from 'react-intl'
import capitalize from 'voca/capitalize'
import PreferredViewContext from '../../context/PreferredView'

export default function TestsAdvice({ test, ...props }) {
    const { formatMessage } = useIntl()

    const { preferredView, PREFERRED_VIEW_TYPES } = useContext(PreferredViewContext)

    const advicesToShow = useMemo(() => {
        if (!test) return []

        const showPerformance = preferredView === PREFERRED_VIEW_TYPES.performance
        const showGrounds = preferredView === PREFERRED_VIEW_TYPES.greenkeeping

        // if these performance or pitch specific advices don't exist or are both empty,
        // fall back to old advices
        const useFallback = (!test.advicesGrounds || !test.advicesPerformance ||
            ((test.advicesGrounds.length === 0) && (test.advicesPerformance.length === 0)))

        return useFallback ?
            test.advices
            : showPerformance ?
                test.advicesPerformance
                : showGrounds ?
                    test.advicesGrounds
                    : []
    }, [test, preferredView, PREFERRED_VIEW_TYPES])

    return (test ?
        <AdviceBlock title={formatMessage({ id: 'advice' })} {...props}>
            <div css={style.advice} key={test.id}>
                {advicesToShow.map(advice => (
                    <div
                        key={advice.id}
                        className='advice comment'
                        css={css`color: white;`}
                    >
                        <div dangerouslySetInnerHTML={{ __html: dp.sanitize(advice.instruction || '') }} />
                    </div>
                ))}
                {test.comment && <>
                    <hr css={css`width: 100%; opacity: 0.3;`} />
                    <h2 css={css`margin: 1em 0; font-size: 1.2em;`}>
                        {capitalize(formatMessage({ id: 'summary' }).toLowerCase())}
                    </h2>
                    <div className='advice'>
                        <div dangerouslySetInnerHTML={{ __html: dp.sanitize(test.comment || '') }} />
                    </div>
                </>}
            </div>
        </AdviceBlock>
        : null
    )
}

const style = {
    advice: css`
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom: 0.125em solid ${rgba(colors.subtle, 0.25)};
        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
        }
        .advice {
            opacity: 0.6;
            font-size: 0.875em;
            margin-bottom: 0.75em;
            line-height: 1.4;
            font-weight: 300;
            text-align: justify;
        }
        .comment {
            opacity: 1;
        }
        .info {
            font-size: 0.75em;
            margin-top: 0.25em;
            margin-bottom: 1.5em;
        }
    `,
}