import React from 'react'
import { css } from '@emotion/react'

const style = {
    quickpick: css`
        border: 2px solid #394047;
        padding: .5em;
        display: inline-flex;
        flex-direction: row;
        border-radius: .25em;
    `,
    option: css`
        border: 2px solid transparent;
        border-radius: .25em;
        min-width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: .5em;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
        
        &.selected {
            border: 2px solid #07D0A5;
            border-radius: .25em
        }
    `
}

export default function BasicMeasurementPicker({
    selected,
    values,
    onChange,
    ...props
}) {
    return (
        <div css={style.quickpick} {...props}>
            {values.map(value => {
                return (
                    <div
                        key={'option-' + value}
                        css={style.option}
                        className={selected === value ? 'selected' : ''}
                        onClick={() => onChange(value)}
                    >
                        {value}
                    </div>
                )
            })}
        </div>
    )
}