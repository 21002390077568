import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react"
import CovermasterContext from "./Covermaster"
import ClubContext from "../Club"
import useRawApiState from "../../hooks/useRawApiState"
import { getUnixTime } from "date-fns"

const sectionMountTime = getUnixTime(new Date())

const PitchTelemetry = createContext()
export default PitchTelemetry

export function PitchTelemetryProvider({ pitch, timeZone, ...props }) {

    const [club, { busy: clubBusy }] = useContext(ClubContext)
    const { metricTypes, isBusy: covermasterBusy } = useContext(CovermasterContext)

    const resourceToFetch = useMemo(() => {
        return buildTelemetryUrl(pitch, metricTypes)
    }, [pitch, metricTypes])

    const { data: telemetryData, isBusy: dataBusy } = useRawApiState(resourceToFetch)
    const [isUnderCover, setIsUnderCover] = useState(false)
    const [selectedPosition, setSelectedPosition] = useState()  // {posX, posY}
    const [viewMode, setViewMode] = useState('live')            // ['live', 'historical', 'sensors']
    const [selectedMetricType, setSelectedMetricType] = useState(
        covermasterBusy ? undefined : metricTypes[0]?.['@id']
    )    // telemetryMetricType IRI

    const selectedMetricTypeUnit = useMemo(() => {
        if (!selectedMetricType || !Array.isArray(metricTypes)) return
        const unitMap = {
            'us-cm': 'µS/cm',
            'percentage': '%',
            'degrees-celsius': '°C',
        }
        const unitName = metricTypes.find(mt => mt['@id'] === selectedMetricType)?.unit
        return unitMap[unitName] ?? unitName
    }, [selectedMetricType, metricTypes])

    const handleSelectPosition = useCallback((zone) => {
        setSelectedPosition(prev => {
            if (!zone) return
            if (prev?.posX === zone.posX && prev?.posY === zone.posY) {
                return //undefined
            } else {
                return {
                    posX: zone.posX,
                    posY: zone.posY,
                }
            }
        })
    }, [])

    const [zoneResults, metricAverages] = useMemo(() => {

        // filter out over / under
        const data = telemetryData?.filter(t => t.deviceMode === (isUnderCover ? 'under-cover' : 'over-cover')) ?? []

        const gridData = [] // transform data to a list of coordinates containing all most recent telemetries
        for (const telemetry of data) { // populate gridData
            const telemetryPayload = {
                measurement: telemetry.value ? parseFloat(telemetry.value) : null,
                posX: telemetry.posX,
                posY: telemetry.posY,
                timestamp: telemetry.timestamp,
                device: telemetry.device,
                unit: metricTypes.find(metric => metric['@id'] === telemetry.telemetryMetric).unit
            }
            const foundCoordinate = gridData.find(g => g.posX === telemetry.posX && g.posY === telemetry.posY)
            if (!foundCoordinate) { // populate empty coordinates
                gridData.push({
                    posX: telemetry.posX,
                    posY: telemetry.posY,
                    latestTelemetries: {
                        [telemetry.telemetryMetric]: telemetryPayload
                    },
                })
            } else { // coordinate exists
                const foundMetric = foundCoordinate.latestTelemetries[telemetry.telemetryMetric]
                const prevTimestamp = foundMetric?.timestamp
                if (!foundMetric || (telemetry.timestamp >= prevTimestamp)) { // write if first or more recent
                    foundCoordinate.latestTelemetries[telemetry.telemetryMetric] = telemetryPayload  // will this change gridData?
                }
            }
        }

        // fill in the remaining coordinates with nulled measurements
        for (let x = 1; x < 4; x++) {
            for (let y = 1; y < 4; y++) {
                if (gridData.find(gd => gd.posX === x && gd.posY === y)) continue
                gridData.push({
                    posX: x,
                    posY: y,
                    latestTelemetries: {},
                })
            }
        }

        // calculate the average across entire pitch for each metric type:
        const telemetryTypeGridAverages = {}
        // these are the covermaster metrics
        for (const metricType of metricTypes) {
            const values = []
            for (const zone of gridData) {
                const metricOfZone = zone.latestTelemetries[metricType['@id']]
                if (metricOfZone) {
                    values.push(metricOfZone.measurement)
                }
            }
            const avg = values.length ? (values.reduce((prev, curr) => prev + curr) / values.length) : null
            telemetryTypeGridAverages[metricType['@id']] = {
                measurement: avg,
                unit: metricType.unit,
            }
        }

        return [gridData, telemetryTypeGridAverages]
    }, [telemetryData, isUnderCover, metricTypes])

    const liveZoneResults = useMemo(() => {
        const zonesForMetric = selectedMetricType ?
            zoneResults.map(zone => {
                const measurement = zone.latestTelemetries[selectedMetricType]?.measurement
                const noMeasurement = measurement === undefined || measurement === null
                return {
                    ...zone,
                    measurement: measurement ?? null,
                    weight: noMeasurement ? 0 : 1,
                }
            })
            : []

        const selectedMetricRatings = metricTypes.find(t => t['@id'] === selectedMetricType)?.ratings
        zonesForMetric.forEach(zone => {
            if (zone.measurement === null) return
            const binOfResult = selectedMetricRatings.find(r => (r.min <= zone.measurement) && (r.max >= zone.measurement))
            if (binOfResult) zone.assessment = binOfResult.assessment
        })
        return zonesForMetric
    }, [zoneResults, selectedMetricType, metricTypes])

    const timestamps = useMemo(() => {
        const selectedZoneResult = zoneResults.find(zone => zone.posX === selectedPosition?.posX && zone.posY === selectedPosition?.posY)
        const selectedStamps = []
        if (selectedZoneResult) {
            for (const val of Object.values(selectedZoneResult.latestTelemetries)) {
                if (Number.isFinite(val.timestamp)) selectedStamps.push(val.timestamp)
            }
        }
        const selectedMin = selectedStamps.length ? Math.min(...selectedStamps) : undefined
        const selectedMax = selectedStamps.length ? Math.max(...selectedStamps) : undefined

        const allStamps = []
        for (const zone of zoneResults) {
            for (const val of Object.values(zone.latestTelemetries)) {
                if (Number.isFinite(val.timestamp)) allStamps.push(val.timestamp)
            }
        }
        const allMin = allStamps.length ? Math.min(...allStamps) : undefined
        const allMax = allStamps.length ? Math.max(...allStamps) : undefined

        return { allMin, allMax, selectedMin, selectedMax }
    }, [zoneResults, selectedPosition])

    const pitchBg = useMemo(() => {
        return pitch?.pitchBackground ? pitch.pitchBackground : club?.sport
    }, [club, pitch])

    const { data: events, isBusy: eventsBusy, fetch: refetchEvents } = useRawApiState(
        `/api/current/pitches/${pitch?.id}/events?from=${sectionMountTime}`
    )

    const { isCoverOn, currentCoverEvent } = useMemo(() => {
        const coverEvents = events?.filter(e => e.type === 'CoverEvent') ?? []
        // search for the first event where start and end
        const nowUnix = getUnixTime(new Date())
        const currentCoverEvent = coverEvents.find(e => e.timestamp <= nowUnix && e.timestampEnd >= nowUnix)

        return {
            isCoverOn: !!currentCoverEvent,
            currentCoverEvent,
        }
    }, [events])

    const titleMessage = useMemo(() => {
        if (!viewMode) return '/'
        /* TO DO: translate */
        const metric = metricTypes.find(t => t['@id'] === selectedMetricType)?.title

        return viewMode === 'live' ? `${selectedPosition ? 'Selected Zone - ' : ''}${metric ?? 'Please select a parameter'} - ${isUnderCover ? 'Under-Cover' : 'Over-Cover'}`
            : viewMode === 'historical' ? `${metric ?? 'Please select a parameter'} - History`
                : viewMode == 'sensors' ? `Sensor Info`
                    : null
    }, [isCoverOn, viewMode, isUnderCover, selectedMetricType, selectedPosition, metricTypes])

    const loading = useMemo(() => {
        !telemetryData || dataBusy || !pitch || covermasterBusy || clubBusy
    }, [telemetryData, dataBusy, pitch, covermasterBusy, clubBusy])

    const timeZoneWithFallback = useMemo(() => {
        return timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }, [timeZone])

    return <PitchTelemetry.Provider
        value={{
            isUnderCover,
            setIsUnderCover,
            selectedPosition,
            handleSelectPosition,
            selectedMetricType,
            setSelectedMetricType,
            selectedMetricTypeUnit,
            viewMode,
            setViewMode,
            titleMessage,
            liveZoneResults,
            metricAverages,
            timestamps,
            pitch,
            pitchBg,
            isCoverOn,
            currentCoverEvent,
            refetchEvents,
            loading,
            positionNames,
            timeZone: timeZoneWithFallback,
        }}
        {...props}
    />
}

const positionNames = {
    '11': 'Bottom Left', /* TO DO: translate these */
    '12': 'Bottom',
    '13': 'Bottom Right',
    '21': 'Left',
    '22': 'Middle',
    '23': 'Right',
    '31': 'Top Left',
    '32': 'Top',
    '33': 'Top Right',
}

function buildTelemetryUrl(pitch, metricTypes) {
    const fetchTelemetries = !!pitch && !!metricTypes
    if (!fetchTelemetries) return undefined

    let url = '/api/current/telemetries?'

    const query = {
        pitch: pitch.id,
        pagination: false,
        'timestamp[gte]': Math.ceil(new Date().valueOf() / 1000) - 3600,  // last hour and up
        'telemetryMetric[]': metricTypes.map(mt => mt.id),
        //'device.deviceType': 'cover-master', /* TO DO: add this when we can test with real cover-master sensors */
    }

    for (const [key, val] of Object.entries(query)) {
        if (Array.isArray(val)) {
            for (const el of val) {
                url += `${key}=${el}&`
            }
        } else {
            url += `${key}=${val}&`
        }
    }
    return url
}