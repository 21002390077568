import { useEffect, useState, useContext, useMemo } from "react"
import CalendarContext from "../../context/Calendar"
import { FormattedMessage } from "react-intl"
import { toZonedTime, fromZonedTime } from "date-fns-tz"
import PitchContext from "../../context/Pitch"
import HourPicker from "./HourPicker"

// user chooses time from pitch perspective, uses pitch timezone to get correct unix
// this component expects useState variable and setter props, will fill in empty initial value automatically
export default function EventDateTimePicker({ disabled, unixDate, setUnixDate, ...props }) {

    const { selectedDay } = useContext(CalendarContext)
    const [pitch] = useContext(PitchContext)

    const timeZone = useMemo(() => {
        return pitch?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }, [pitch])

    const [hour, setHour] = useState(unixDate ?
        toZonedTime(unixDate, timeZone).getHours() :
        (new Date()).getHours()
    )

    const [minute, setMinute] = useState(getPrevFiveMins(unixDate ?
        toZonedTime(unixDate, timeZone).getMinutes() :
        (new Date()).getMinutes()
    ))

    useEffect(() => {
        if (!setUnixDate) return
        setUnixDate(date => {
            if (date) {
                // update unsaved value or event exists with value
                const zoned = toZonedTime(date, timeZone)
                zoned.setHours(hour, minute)
                const converted = fromZonedTime(zoned, timeZone)
                return converted
            } else {
                // new event without initial value
                const newUnix = new Date(selectedDay)
                newUnix.setHours(hour, minute)
                // conversion to treat this as zoned occurs here:
                const zonedUtcEquivalent = fromZonedTime(newUnix, timeZone)
                return zonedUtcEquivalent
            }
        })
    }, [setUnixDate, hour, minute, selectedDay, timeZone])

    return (
        <>
            <h3 style={{ marginBottom: "8px", textTransform: 'capitalize' }}>
                <FormattedMessage id='time' />
            </h3>
            <HourPicker
                minute={minute}
                hour={hour}
                handleSetHour={setHour}
                handleSetMinute={setMinute}
                disabled={disabled}
            />
        </>
    )
}

function getPrevFiveMins(mins) {
    if (!Number.isFinite(mins) || !(mins >= 0)) return 0
    while (mins % 5 !== 0) {
        mins = mins - 1
    }
    return mins
}