import editIcon from '@assets/icons/edit.svg'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { backendColors, colors, timings, animations } from '../../style/vars'
import CtaLink from '../_control/CtaLink'
import { useIntl, FormattedMessage } from 'react-intl'

const style = {
    cont: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25em 0.75em;
        padding-right: 0.3125em;
        background-color: ${rgba(colors.subtle, 0.17)};
        border-radius: 0.25em;
        color: ${colors.white};
        transition: all ${timings.fast} ease;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > span {
            display: block;
            text-transform: uppercase;
            font-size: 0.875em;
        }
    `,
    measurement: color => css`
        display: flex;
        align-items: center;
        padding: 0.25em 0.5em;
        padding-top: 0.3125em;
        background-color: ${rgba(color, 0.125)};
        border-radius: 0.3125em;
        border: 2px solid ${color};
    `,
    indicator: css`
        font-size: 0.5em;
    `,
    form: css`
        background-color: ${rgba(colors.white, 0.05)};
        display: flex;
        align-items:center;
        overflow: hidden;
        border-radius: 0.25em;
    `,
    input: css`
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
        max-width: 5em;
        margin-left: 10px;
        font-family: Heebo, Arial;
        color: ${colors.white};
        background-color: transparent;
    `,
    confirm: css`
        border-radius: 0;
        height: 2.5em;
        color: ${colors.super};
        transition: all ${timings.fast} ease;
        text-transform: uppercase;
        border: none;
        font-size: 0.825em;
        font-weight: 300;
        background-color: ${rgba(colors.white, 0.05)};
        cursor: pointer;
        &:hover {
            transition: all ${timings.snappy} ease;
            color: ${colors.white};
            background-color: ${rgba(colors.white, 0.15)};
        }
    `,
    back: css`
        border-radius: 0;
        padding: 1em;
        height: 2.5em;
    `
}

export default function Parameter({
    parameterResult,
    showOptimum = false,
    onEditSubmit,
    ...props
}) {
    const formInput = useRef()
    const [form, setForm] = useState(false);
    const [formError, setFormError] = useState(false)

    const handleSubmit = useCallback(async (value) => {
        try {
            const modified = {
                ...parameterResult,
                measurement: value ? Number(value) : null,
            }
            setFormError(false)
            await onEditSubmit?.(modified);
            setForm(false);
        }
        catch (e) {
            setFormError(true)
        }
    }, [onEditSubmit, parameterResult]);

    useEffect(() => {
        if (form) {
            formInput.current.focus()
        }
    }, [form])

    const { measurement, optimumValue, scoreColor } = useMemo(() => {
        const decimals = parameterResult?.testParameter?.allowedDecimals || 0
        const measurement = (!Number.isNaN(parameterResult?.measurement) && parameterResult?.measurement !== null) ? parameterResult?.measurement.toFixed(decimals) : undefined
        const optimum = parameterResult?.testParameter?.optimumValue
        const rating = !!parameterResult?.rating
        const color = rating ? backendColors[parameterResult?.rating.assessment.frontendCode] : colors.subtle
        return {
            measurement: measurement,
            optimumValue: optimum,
            scoreColor: color
        }
    }, [parameterResult]);

    const { formatMessage } = useIntl()

    return (
        <div css={style.cont} {...props} title={parameterResult.testParameter.label}>
            <span css={css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            `}>
                {parameterResult.testParameter.label}
            </span>
            <div css={css`display: flex; align-items: center; min-height: 2.2em;`}>
                {(showOptimum && typeof optimumValue === 'number' && measurement !== undefined && !form) &&
                    <span style={{ opacity: 0.75, fontSize: '0.75em', marginRight: '1.5em' }}>
                        <span style={{ opacity: 0.5 }}>
                            <FormattedMessage id='optimalValue' />:&nbsp;
                        </span>
                        <span>
                            {optimumValue}
                        </span>
                    </span>
                }
                {(measurement && !form) &&
                    <div css={style.measurement(scoreColor)}>
                        <span style={{ fontWeight: 500 }}>
                            {measurement}
                        </span>
                    </div>
                }
                {!form && onEditSubmit &&
                    <CtaLink
                        onClick={() => { setForm(true) }}
                        signal='admin'
                        css={css`
                                padding: 2px 10px;
                                margin-left: 10px;
                                border-radius: 0.25em;
                            `}>
                        <ReactSVG
                            src={editIcon}
                            css={css`width: 2em;`} />
                    </CtaLink>
                }
                {form &&
                    <form
                        title={formError ? formatMessage({ id: 'outsideAllowedRange' }) : ''}
                        css={[
                            style.form,
                            css`
                                border: ${formError ? ('1px solid ' + colors.warn) : 'none'};
                                ${formError && animations.shake}
                            `
                        ]}
                        onSubmit={(e) => {
                            e.preventDefault()
                            const v = e.target[0].value
                            handleSubmit(v ?? null)
                        }}>
                        <CtaLink
                            onClick={() => {
                                setFormError(false)
                                setForm(false)
                            }}
                            signal='warn'
                            css={style.back}>
                            <FormattedMessage id='cancel' />
                        </CtaLink>
                        <input
                            type="number"
                            step="any"
                            ref={formInput}
                            css={style.input}
                        />
                        <button css={style.confirm}>
                            <FormattedMessage id='confirm' />
                        </button>
                    </form>
                }
            </div>
        </div>
    )
}