import { useEffect, useState, useContext, useMemo, useCallback } from "react"
import { toZonedTime, fromZonedTime } from "date-fns-tz"
import PitchContext from "../../context/Pitch"
import { FormattedMessage } from "react-intl"
import FancyDatePicker from "./FancyDatePicker"
import HourPicker from "./HourPicker"
import { css } from "@emotion/react"
import CalendarContext from "../../context/Calendar"

// user chooses time from pitch perspective, uses pitch timezone to get correct unix
// this component expects useState variable and setter props, will fill in empty initial value automatically
export default function EventDateTimeEndPicker({ unixDate, setUnixDate, ...props }) {

    const [pitch] = useContext(PitchContext)
    const { selectedDay } = useContext(CalendarContext)
    const defaultDay = selectedDay ? 
        new Date(selectedDay.setHours((new Date()).getHours(), (new Date()).getMinutes()))
        : undefined

    const timeZone = useMemo(() => {
        return pitch?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }, [pitch])

    // 'local'
    const [selectedDate, setSelectedDate] = useState(unixDate ? toZonedTime(unixDate, timeZone) : defaultDay)

    const [hour, minute] = useMemo(() => {
        return [
            selectedDate.getHours(),
            getPrevFiveMins(selectedDate.getMinutes()),
        ]
    }, [selectedDate])

    const handleSetHour = useCallback((hour) => {
        setSelectedDate(date => new Date((new Date(date).setHours(hour))))
    }, [])

    const handleSetMinute = useCallback((minute) => {
        setSelectedDate(date => new Date((new Date(date).setMinutes(minute))))
    }, [])

    useEffect(() => {
        if (!setUnixDate) return
        setUnixDate(date => {
            if (date) {
                return fromZonedTime(selectedDate, timeZone)
            } else {
                // new event without initial value
                const newUnix = new Date(selectedDate)
                return fromZonedTime(newUnix, timeZone)
            }
        })
    }, [selectedDate, setUnixDate, timeZone])

    return (
        <div {...props}>
            <h3 style={{ marginBottom: "8px", textTransform: 'capitalize' }}>
                End Time  {/* <FormattedMessage id='time' /> */} {/* TO DO: TRANSLATE */}
            </h3>
            <div css={css`
                display: flex;
                flex-wrap: wrap;
                gap: 0em 1em;
                width: 100%;
            `}>
                <HourPicker
                    hour={hour}
                    minute={minute}
                    handleSetHour={handleSetHour}
                    handleSetMinute={handleSetMinute}
                />

                <FancyDatePicker date={selectedDate} onDateChange={setSelectedDate} css={css`
                    width: 14em; 
                    height: 3.1em;
                    overflow: hidden;
                `} />
            </div>
        </div>
    )
}

function getPrevFiveMins(mins) {
    if (!Number.isFinite(mins) || !(mins >= 0)) return 0
    while (mins % 5 !== 0) {
        mins = mins - 1
    }
    return mins
}