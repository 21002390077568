import { useCallback, useEffect, useMemo, useState } from "react"
import FancyDropdown from "../../_control/FancyDropdown"
import { FormattedMessage, useIntl } from "react-intl"
import { css } from "@emotion/react"
import TextField from "../../_control/TextField"
import { colors } from "../../../style/vars"
import Loader from "../../_general/Loader"
import SensorLocationPicker from "./SensorLocationPicker"
import Cta from "../../_control/Cta"
import useLdjsonApi from "../../../hooks/useLdjsonApi"
import useRawApiState from "../../../hooks/useRawApiState"
import { isEqual } from "lodash"
import { getUnixTime } from 'date-fns'

const formOpenTime = getUnixTime(new Date())

export default function SensorForm({ selectedDevice, afterRequest, pitchGroupedDevices, ...props }) {
    const { formatMessage } = useIntl()
    const { post, put } = useLdjsonApi()
    const creatingNew = !selectedDevice

    const { data: futureEventsOfInitialPitch, isBusy: futureEventsOfInitialPitchBusy } = useRawApiState(
        creatingNew ? 
        undefined : 
        `/api/current/pitches/${selectedDevice.latestDevicePlacementEvent?.pitch?.id}/events?from=${formOpenTime}`
    )

    const allowOwnerOrPitchOrPositionSelection = useMemo(() => {
        // sensors cannot be moved from their pitch or pisition if they are currently in an active cover event
        // or any future cover events are planned
        const events = futureEventsOfInitialPitch?.filter(e => e.type === 'CoverEvent') ?? []
        return (!futureEventsOfInitialPitchBusy && events.length === 0)
    }, [futureEventsOfInitialPitch, futureEventsOfInitialPitchBusy])

    const [formState, setFormState] = useState(creatingNew ?
        {
            name: '',
            deviceType: 'cover-master',
            devEUI: '',
            appKey: '',
            active: true,
            deleted: false,
            posX: null,
            posY: null,
            isUnderCover: false,
            userGroup: null,
        }
        :
        {
            name: selectedDevice.name,
            deviceType: selectedDevice.deviceType,
            devEUI: selectedDevice.devEUI,
            appKey: selectedDevice.appKey,
            active: selectedDevice.active,
            deleted: selectedDevice.deleted,
            posX: selectedDevice.latestDevicePlacementEvent?.posX,
            posY: selectedDevice.latestDevicePlacementEvent?.posY,
            isUnderCover: selectedDevice.latestDevicePlacementEvent?.deviceMode ?
                (selectedDevice.latestDevicePlacementEvent?.deviceMode === 'under-cover')
                : 'unassigned'
            ,
            userGroup: null, // set via useEffect after fetching owner events
        }
    )

    const [pitchId, setPitchId] = useState(creatingNew ? null : selectedDevice.latestDevicePlacementEvent?.pitch?.id)

    ///// OWNERSHIP
    const { data: userGroups, isBusy: userGroupsBusy } = useRawApiState('/api/current/user-groups')
    // get the usergroup that currently owns this sensor, if editing
    const { data: deviceData, isBusy: deviceBusy } = useRawApiState(
        selectedDevice ? `/api/current/devices/${selectedDevice.id}` : undefined,
        {},
        [selectedDevice]
    )

    const latestOwnershipUsergroup = useMemo(() => {
        if (!deviceData?.deviceOwnerships.length) return null
        return deviceData.deviceOwnerships?.reduce((a, b) => {
            const aTimestamp = a.timestamp || a
            const bTimestamp = b.timestamp
            return aTimestamp < bTimestamp ? b : a
        }).userGroup?.['@id']
    }, [deviceData])

    const handleSetOwner = useCallback((value, clearPosition = true) => {
        setFormState(prev => clearPosition ? {
            ...prev,
            pitch: null, // clear pitch and position if owner changes
            posX: null,
            posY: null,
            userGroup: value,
        } :
            {
                ...prev,
                userGroup: value,
            }
        )
    }, [])

    useEffect(() => {
        handleSetOwner(latestOwnershipUsergroup, false)
    }, [latestOwnershipUsergroup])

    const ownershipOptions = useMemo(() => {
        const options = userGroups?.map((userGroup) => {
            return ({ value: userGroup['@id'], label: userGroup.name })
        }).toSorted((a, b) => a.label.localeCompare(b.label), {}) ?? []
        return options
    }, [userGroups])

    const userGroupNumber = useMemo(() => {
        return userGroups?.find(x => x['@id'] === formState.userGroup)?.id  // makes sure the rawApiState won't spam requests
    }, [formState, userGroups])

    ///// PITCH
    const { data: pitchData, isBusy: pitchBusy } = useRawApiState(
        pitchId ? `/api/current/pitches/${pitchId}` : undefined,
        {},
        [pitchId]
    )

    const { data: pitches, isBusy: pitchesBusy } = useRawApiState(
        userGroupNumber ? `/api/current/user-groups/${userGroupNumber}/pitches` : undefined,
        {},
        [userGroupNumber]
    )

    const pitchOptions = useMemo(() => {
        const options = pitches?.map(({ id, name }) => ({
            value: id,
            label: name,
        })) ?? []
        options.unshift({
            value: null,
            label: formatMessage({ id: 'none' })
        })
        return options
    }, [pitches])

    const pitchOtherDevices = useMemo(() => {
        const devices = pitchGroupedDevices[pitchId]
        return devices?.filter(device => device.id !== selectedDevice?.id) ?? []
    }, [pitchGroupedDevices, pitchId, selectedDevice])

    // LOADING AND VALIDATION STATE
    const [submitBusy, setSubmitBusy] = useState(false)

    const loadingForm = useMemo(() => {
        return userGroupsBusy || deviceBusy || !userGroups || futureEventsOfInitialPitchBusy
    }, [userGroupsBusy, deviceBusy, pitchData, userGroups, futureEventsOfInitialPitchBusy])

    const isSubmittable = useMemo(() => {
        return formState.name &&
            formState.devEUI &&
            formState.userGroup &&
            !pitchBusy &&
            !submitBusy &&
            !userGroupsBusy &&
            !deviceBusy &&
            !pitchesBusy
    }, [formState, pitchBusy, submitBusy, userGroupsBusy, deviceBusy, pitchesBusy])

    // STATE SETTERS
    const handleSetPitch = useCallback((value) => {
        setFormState(prev => ({
            ...prev,
            posX: null, // clear the position if pitch changes
            posY: null,
        }))
        setPitchId(value)
    }, [])

    const handleSetCoordinates = useCallback((x, y) => {
        setFormState(prev => ({
            ...prev,
            posX: x,
            posY: y,
        }))
    }, [])

    const { data: futureEvents, isBusy: futureEventsBusy } = useRawApiState(
        pitchId ? `/api/current/pitches/${pitchId}/events?from=${formOpenTime}` : undefined,
        {},
        [pitchId],
    )

    const allowCoordinatePlacement = useMemo(() => {
        // new sensors are added as 'over-cover' and cannot be added at all while a cover-event is active, 
        // or any future cover events are planned
        const events = futureEvents?.filter(e => e.type === 'CoverEvent') ?? []
        return (!futureEventsBusy && events.length === 0)
    }, [futureEvents, futureEventsBusy])

    const submit = useCallback(async () => {
        if (!isSubmittable) return

        setSubmitBusy(true)
        try {
            // create sensor
            const sensorPayload = { ...formState }
            delete sensorPayload.posX
            delete sensorPayload.posY
            delete sensorPayload.isUnderCover
            delete sensorPayload.userGroup

            let responseData
            if (creatingNew) {
                const sensorResponse = await post('/api/current/devices', { body: sensorPayload })
                responseData = sensorResponse.data
            } else {
                delete sensorPayload.devEUI
                delete sensorPayload.appKey
                const sensorResponse = await put(`/api/current/devices/${selectedDevice.id}`, { body: sensorPayload })
                responseData = sensorResponse.data
            }

            // if necessary (ownership has changed), change ownership
            if (responseData && latestOwnershipUsergroup !== formState.userGroup) {
                try {
                    const ownershipPayload = {
                        device: responseData['@id'],
                        userGroup: formState.userGroup,
                        active: true,
                        deleted: false,
                    }
                    await post('/api/current/device-ownerships', { body: ownershipPayload })

                } catch (e) {
                    console.error()
                }
            }

            // if necessary, (new sensor, or placement has changed) create placement event
            if (responseData) {
                try {
                    const hasPitch = !!pitchId && pitchData
                    const hasLocation = formState.posY && formState.posX && hasPitch
                    const placementPayload = {
                        device: responseData['@id'],
                        pitch: hasPitch ? pitchData['@id'] : null,
                        posX: hasLocation ? formState.posX : null,
                        posY: hasLocation ? formState.posY : null,
                        timestamp: (Math.floor((new Date()).getTime() / 1000)) + 1,
                        // +1 to avoid exact same timestamp as the first placement the backend automatically creates, 
                        // which then appears on top...
                        active: true,
                        deleted: false,
                        deviceMode: hasLocation ? (formState.isUnderCover ? 'under-cover' : 'over-cover') : 'unassigned',
                    }

                    if (creatingNew || hasPlacementChanged(placementPayload, responseData)) {
                        await post('/api/current/device-placement-events', { body: placementPayload })
                    }
                } catch (e) {
                    console.error(e)
                }
            }

        } catch (e) {
            console.error(e)
        } finally {
            setSubmitBusy(false)
            afterRequest()
        }
    }, [formState, isSubmittable, post, afterRequest, pitchData, selectedDevice, latestOwnershipUsergroup, pitchId])

    return loadingForm ? <div css={css`height: 50vh;`}>
        <Loader size="2em" />
    </div> :
        <div css={css`display: grid; gap: 1.5em; font-size: 0.85em;`}>

            <div css={style.section}>
                <span>
                    Ownership {/* TO DO translate */}
                </span>
                <FancyDropdown
                    options={ownershipOptions}
                    css={style.dropdown(!allowOwnerOrPitchOrPositionSelection)}
                    value={ownershipOptions?.find(({ value }) => (value === formState.userGroup)) ?? { label: 'none', value: null }} /* TO DO translate this */
                    onChange={({ value }) => handleSetOwner(value)}
                    disabled={!allowOwnerOrPitchOrPositionSelection}
                />
            </div>

            <div css={style.section}>
                <span>
                    Pitch {/* TO DO translate */}
                </span>
                <FancyDropdown
                    options={pitchOptions}
                    placeholder={formatMessage({ id: 'none' })}
                    css={style.dropdown(pitchesBusy || !allowOwnerOrPitchOrPositionSelection)}
                    value={pitchesBusy ? undefined : pitchOptions?.find(({ value }) => (value === pitchId))}
                    onChange={({ value }) => handleSetPitch(value)}
                    disabled={pitchesBusy || !allowOwnerOrPitchOrPositionSelection}
                />
            </div>

            <div css={style.section}>
                <span>
                    Choose a name or number {/* TO DO translate */}
                </span>
                <TextField
                    autoFocus={false}
                    css={style.textField(false)}
                    value={formState.name}
                    maxLength={30}
                    disabled={false}
                    onChange={(e) => setFormState(prev => ({ ...prev, name: e.target.value }))}
                />
            </div>

            <div css={style.section}>
                <span>
                    DEV EUI {/* TO DO translate */}
                </span>
                <TextField
                    autoFocus={false}
                    css={style.textField(!creatingNew)}
                    value={formState.devEUI}
                    maxLength={30}
                    disabled={!creatingNew}
                    onChange={(e) => setFormState(prev => ({ ...prev, devEUI: e.target.value }))}
                />
            </div>

            <div css={style.section}>
                <span>
                    APP KEY {/* TO DO translate */}
                </span>
                <TextField
                    autoFocus={false}
                    css={style.textField(!creatingNew)}
                    value={formState.appKey}
                    maxLength={40}
                    disabled={!creatingNew}
                    onChange={(e) => setFormState(prev => ({ ...prev, appKey: e.target.value }))}
                />
            </div>

            {futureEventsBusy ? <Loader size="2em" /> :
                <div css={[style.section, css`opacity: ${pitchId && allowCoordinatePlacement ? 1 : 0.4};`]}>
                    <span>
                        {allowCoordinatePlacement ?
                            'Choose a position'
                            :
                            'Sensor placement on this pitch is currently unavailable due to current or future cover events' /* TO DO: translate */
                        }{/* TO DO translate */}
                    </span>
                    <div css={css` width: min(100%, 18em);`}>
                        <SensorLocationPicker
                            posX={formState.posX}
                            posY={formState.posY}
                            handleSetCoordinates={handleSetCoordinates}
                            disabled={!pitchId || !allowCoordinatePlacement}
                            isUnderCover={formState.isUnderCover}
                            otherDevices={pitchOtherDevices}
                        />
                        <div css={css`
                                width: 100%;
                                display: grid;
                                place-items: center;
                                padding: 0.5em;
                            `}>
                            {formState.isUnderCover ? 'under cover' : 'over cover'} {/* TO DO translate this */}
                        </div>
                    </div>
                </div>
            }

            <div css={css`
                display: flex;
                flex-direction: row-reverse;
            `}>
                <Cta
                    onClick={() => submit()}
                    disabled={loadingForm || !isSubmittable}
                >
                    <FormattedMessage id='save' />
                </Cta>
            </div>

        </div>
}

const style = {
    textField: (disabled) => css`
        background: ${disabled ? colors.eventDark : colors.eventLight};
        color: white;
        font-size: 1em;
        opacity: ${disabled ? 0.8 : 1}
    `,
    dropdown: (disabled) => css`
        opacity: ${disabled ? 0.4 : 1};
        * {
            cursor: ${disabled ? 'default' : 'pointer'} !important;
        }
    `,
    section: css`
        display: grid;
        gap: 0.4em;
    `
}

function hasPlacementChanged(placementPayload, deviceAfterPostPut) {

    //backend always creates one placement event after creating a device, but in theory can be deleted
    const placementsList = deviceAfterPostPut.devicePlacementEvents // can be an empty array

    if (!placementsList.length) return false

    const latestOfList = placementsList.reduce((a, b) => {
        const aTimestamp = a.timestamp || a
        const bTimestamp = b.timestamp
        return aTimestamp < bTimestamp ? b : a
    })

    try {
        const payloadCopy = { ...placementPayload }
        delete payloadCopy.timestamp

        const payloadShapedData = {
            device: latestOfList.device,
            pitch: latestOfList?.pitch?.['@id'] ?? null,
            posX: latestOfList.posX,
            posY: latestOfList.posY,
            active: latestOfList.active,
            deleted: latestOfList.deleted,
            deviceMode: latestOfList.deviceMode,
        }

        return !isEqual(payloadCopy, payloadShapedData)
    } catch (e) {
        console.error(e)
    }

}