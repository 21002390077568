import React, { createContext, useState, useEffect, useMemo } from 'react'
import useRawApiState from '../hooks/useRawApiState'

const PitchListContext = createContext()

function PitchListProvider({ pagination = false, ...props }) {
    const { data, isBusy, ...state } = useRawApiState(`/api/current/pitches/list?pagination=${pagination}`)
    const [sortAlphabetical, setSortAlphabetical] = useState(localStorage.getItem('setting-sortAlphabetical') === 'true')

    useEffect(() => {
        localStorage.setItem('setting-sortAlphabetical', sortAlphabetical)
    }, [sortAlphabetical])

    const sortedPitches = useMemo(() => {
        if (!data) return []
        return sortAlphabetical ?
            [...data].sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'base' }))
            : data
    }, [data, sortAlphabetical])

    return (
        <PitchListContext.Provider
            {...props}
            value={{
                pitches: sortedPitches,
                defaultOrderPitches: data || [],
                sortAlphabetical: sortAlphabetical,
                setSortAlphabetical: setSortAlphabetical,
                isBusy: isBusy,
                ...state
            }}
        />
    )
}

export default PitchListContext
export { PitchListProvider }