import React, { createContext, useState, useEffect } from 'react'

const UnitSystemContext = createContext()
export default UnitSystemContext

export function UnitSystemProvider(props) {

    const [unitSystem, setUnitSystem] = useState(localStorage.getItem('unitSystem') || 'metric')

    useEffect(() => {
        if (!unitSystem) return
        localStorage.setItem('unitSystem', unitSystem)
    }, [unitSystem])

    return (
        <UnitSystemContext.Provider
            value={{
                unitSystem,
                setUnitSystem,
            }}
            {...props}
        />
    )
}