import React from 'react';
import defaultBg from '../../../assets/images/pitches/football.svg';

function importAll(r) {
    let images = {};
    for (const key of r?.keys()) {
        images[key.replace('./', '').replace('.svg', '')] = r(key);
    };
    return images;
};

const backgrounds = importAll(require.context('../../../assets/images/pitches/', false, /\.svg$/));

export const pitchBgSrc = (type) => (backgrounds?.[type]?.default ?? defaultBg);

export default function PitchBg({ type, style, ...props }) {
    return (<img style={{ maxWidth: '100%', ...style }} src={pitchBgSrc(type)} {...props} />);
}
